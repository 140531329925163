// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal-content {
    background-color: #F5F7F6 !important;
    border-radius: 10px !important;
    width: 400px !important;
    height: 450px !important;
}
.modal-header {
    border: none !important;
}

.modal-body {
    margin-top: -10px;
}

.modal-backdrop {
    z-index: 1200 !important;
}

.modal {
    z-index: 1220 !important;
}

.modal-dialog {
    display: flex;
    height: 80%;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/dialog/Modalcustomstyles.css"],"names":[],"mappings":";AACA;IACI,oCAAoC;IACpC,8BAA8B;IAC9B,uBAAuB;IACvB,wBAAwB;AAC5B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":["\n.modal-content {\n    background-color: #F5F7F6 !important;\n    border-radius: 10px !important;\n    width: 400px !important;\n    height: 450px !important;\n}\n.modal-header {\n    border: none !important;\n}\n\n.modal-body {\n    margin-top: -10px;\n}\n\n.modal-backdrop {\n    z-index: 1200 !important;\n}\n\n.modal {\n    z-index: 1220 !important;\n}\n\n.modal-dialog {\n    display: flex;\n    height: 80%;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
