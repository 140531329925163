import https from './init/https';

// PORT-IL-1: Retrieve List of Institutions
export const getInstitutionList = async (searchKey) => {
  return await https.get(`/institutions/search?search_text=${searchKey}`);
};

// PORT-IL-2: Export Instiutions List to Excel
export const exportToExcel = async (searchKey) => {
  return await https.get(`/institutions/excel-export?search_text=${searchKey}`, { responseType: 'arraybuffer' });
};

// PORT-IL-3: Add, update, delete institutions via excel import
export const institutionsFileUpload = async (fileData) => {
  return await https.post(`/institutions/excel-import`, fileData);
};
