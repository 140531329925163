import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
// import styled from "styled-components";
import './courses.css';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';

// const TrWrapper = styled.tr`
//   background: white;
//   cursor: default;

//   .firstElement {
//     display: flex;
//     flex-direction: row;
//   }

//   &.helperContainerClass {
//     width: auto;
//     border: 1px solid #efefef;
//     box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
//     background-color: rgba(255, 255, 255, 0.9);
//     border-radius: 3px;

//     &:active {
//       cursor: grabbing;
//     }

//     & > td {
//       padding: 5px;
//       text-align: left;
//       width: 200px;
//     }
//   }
// `;

const RowHandler = SortableHandle(() => <div className="handle">|||</div>);

const TableRow = ({
  id,
  rowDetails,
  first,
  second,
  third,
  fourth,
  fifth,
  sixth,
  className,
  handleChange,
  handleSave,
  handleDelete,
  notSaved,
  handleAddRow,
  handleCancel,
}) => {
  return (
    // <TrWrapper className={className}>
    <tr>
      <td>
        <div className="firstElement">
          <RowHandler />
        </div>
      </td>

      <td>
        <input
          type="text"
          value={first}
          placeholder="Enter here"
          onChange={(event) => handleChange(event, 'course_name', id)}
          className={notSaved ? 'tdCoursesInputRed' : 'tdCoursesInput'}
        />{' '}
      </td>
      <td>
        <input
          type="text"
          value={second}
          placeholder="Enter here"
          onChange={(event) => handleChange(event, 'hierarchy_1_value', id)}
          className={notSaved ? 'tdCoursesInputRed' : 'tdCoursesInput'}
        />{' '}
      </td>
      <td>
        <input
          type="text"
          value={third}
          placeholder="Enter here"
          onChange={(event) => handleChange(event, 'hierarchy_2_value', id)}
          className={notSaved ? 'tdCoursesInputRed' : 'tdCoursesInput'}
        />
      </td>
      <td>
        <input
          type="text"
          value={fourth}
          placeholder="Enter here"
          onChange={(event) => handleChange(event, 'hierarchy_3_value', id)}
          className={notSaved ? 'tdCoursesInputRed' : 'tdCoursesInput'}
        />{' '}
      </td>
      <td>
        <input
          type="text"
          value={fifth}
          placeholder="Enter here"
          onChange={(event) => handleChange(event, 'hierarchy_4_value', id)}
          className={notSaved ? 'tdCoursesInputRed' : 'tdCoursesInput'}
        />{' '}
      </td>
      <td>
        <input
          type="text"
          value={sixth}
          placeholder="Enter here"
          onChange={(event) => handleChange(event, 'hierarchy_5_value', id)}
          className={notSaved ? 'tdCoursesInputRed' : 'tdCoursesInput'}
        />{' '}
      </td>
      <td>
        {notSaved ? (
          <button onClick={() => handleCancel(id)} className={id % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}>
            <CancelIcon className={notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
          </button>
        ) : (
          <button onClick={() => handleAddRow(id, rowDetails)} className={id % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}>
            <AddCircleIcon className={notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
          </button>
        )}
        <button onClick={() => handleDelete(id, rowDetails)} className={id % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}>
          <DeleteIcon className={notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
        </button>
        <button onClick={() => handleSave(id, rowDetails)} className={id % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}>
          <SaveIcon className={notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
        </button>
      </td>
      {/* // </TrWrapper> */}
    </tr>
  );
};

export default TableRow;
