import activityIcon from '../../assets/icons/activity.png';

export const fetchResults = {
  data: {
    activity: {
      txt: 'ACTIVITY',
      img: activityIcon,
      data: [
        {
          status: 'added 2 subjects',
          img: 'https://marvel-live.freetls.fastly.net/canvas/2020/3/4e75718e8dc845a7ba028ac644544c05?quality=95&fake=.png',
          name: 'Bittu Sarkar',
          updated: '10:30 am 27 july 2020',
        },
        {
          status: 'added 2 subjects',
          img: 'https://marvel-live.freetls.fastly.net/canvas/2020/3/4e75718e8dc845a7ba028ac644544c05?quality=95&fake=.png',
          name: 'Bittu Sarkar',
          updated: '10:30 am 27 july 2020',
        },
        {
          status: 'added 2 subjectssssssssssssssssssssssssssssssssssssssssssssssssssssss',
          img: 'https://marvel-live.freetls.fastly.net/canvas/2020/3/4e75718e8dc845a7ba028ac644544c05?quality=95&fake=.png',
          name: 'Bittu Sarkar',
          updated: '10:30 am 27 july 2020',
        },
        {
          status: 'added 2 subjects',
          img: 'https://marvel-live.freetls.fastly.net/canvas/2020/3/4e75718e8dc845a7ba028ac644544c05?quality=95&fake=.png',
          name: 'Bittu Sarkar',
          updated: '10:30 am 27 july 2020',
        },
        {
          status: 'added 2 subjects',
          img: 'https://marvel-live.freetls.fastly.net/canvas/2020/3/4e75718e8dc845a7ba028ac644544c05?quality=95&fake=.png',
          name: 'Bittu Sarkar',
          updated: '10:30 am 27 july 2020',
        },
        {
          status: 'added 2 subjects',
          img: 'https://marvel-live.freetls.fastly.net/canvas/2020/3/4e75718e8dc845a7ba028ac644544c05?quality=95&fake=.png',
          name: 'Bittu Sarkar',
          updated: '10:30 am 27 july 2020',
        },
        {
          status: 'added 2 subjects',
          img: 'https://marvel-live.freetls.fastly.net/canvas/2020/3/4e75718e8dc845a7ba028ac644544c05?quality=95&fake=.png',
          name: 'Bittu Sarkar',
          updated: '10:30 am 27 july 2020',
        },
        {
          status: 'added 2 subjects',

          img: 'https://marvel-live.freetls.fastly.net/canvas/2020/3/4e75718e8dc845a7ba028ac644544c05?quality=95&fake=.png',
          name: 'Bittu Sarkar',
          updated: '10:30 am 27 july 2020',
        },
      ],
    },
  },
};
