import React, { useState, useEffect } from 'react';
import { Box, Icon } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import Axios from 'axios';
import * as Yup from 'yup';
import { saveAs } from 'file-saver';
import styles from './SubjectOldStyle.module.css';
import { ModalComponent } from '../../../../components/index';
import { Dialogbox } from '../../../../components/index';
import { BASE_URL } from '../../../../config/Api';
import * as ReactBootStrap from 'react-bootstrap';
import ReactLoading from 'react-loading';
import ButtonComponent from '../../../../components/button/Button';
import { getCoursesListOfAnInstitution } from '../../../../api/institutionCourses';
import { downloadSubjectsForCourseExcel, updateSubjectCourse } from '../../../../api/InstitutionSubjects';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';

var board_id = -1;
var course_id = -1;
var subjectId = -1;
var mediumId = -1;

const initialValues = {
  board: '',
  class: '',
  subject: '',
  subject_name: '',
};

const validationBox = Yup.object({
  board: Yup.string(),
  class: Yup.string(),
  subject: Yup.string(),
  subject_name: Yup.string(),
});

var t_head = ['Subject', 'Board', 'Course', 'Stucle Subject (Code)', 'Sub-subjects', 'Medium', 'Taxonomy', 'Description', ''];

const Subject = () => {
  const [editVal, setEditVal] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [nextPage, setNextPage] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [state, setState] = useState({
    t_row: [],
    optBD: [],
    optCourse: [],
    subjectList: [],
    drop_board: [],
    drop_class: [],
    drop_sub: [],
    board: '',
    subject: '',
    class: '',
    subject_name: '',
    cmn_subjectID: '',
    cmn_school_syllabusID: '',
    qb_classID: '',
    qb_subjectID: '',
    boardId: '-1',
    courseId: '-1',
    showHide: false,
  });

  let school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 2);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  const fetchResult = async () => {
    setIsLoading(true);
    try {
      // PORT-IB-5: Retrieve list of boards of an institution

      const getBoard = await getBoardsByNameInInstitution(school_id);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards`,
      //   Auth
      // );
      // This api is not implimented yet
      const getSubjects = await Axios.get(
        `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/${state.courseId}/subjects/detailed-info?medium_id=${mediumId}`,
        Auth,
      );

      setState((prevState) => ({
        ...prevState,
        optBD: getBoard.data.data,
        subjectList: getSubjects.data.data,
      }));
      setIsLoading(false);
    } catch (err) {
      console.log(err.response, 'error');
    }
  };

  useEffect(() => {
    fetchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changedBoard = async (e, selected) => {
    setIsLoading(true);
    if (selected === 'board') {
      board_id = e.target.value;
    } else if (selected === 'course') {
      course_id = e.target.value;
    }
    try {
      // PORT-IC-4: Retrieve list of courses of an institution
      const getCourse = await getCoursesListOfAnInstitution(school_id, board_id, Auth);
      // const getCourse = await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses`,
      //   Auth
      // );
      // This api is not implimented yet
      const getSubjects = await Axios.get(
        `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/detailed-info`,
        Auth,
      );

      setIsLoading(false);
      setState((prevState) => ({
        ...prevState,
        boardId: board_id,
        courseId: course_id,
        optCourse: getCourse.data.data,
        subjectList: getSubjects.data.data,
      }));
    } catch (err) {
      console.log(err, 'error');
    }
  };

  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };
  const handleClickOpen = () => {
    setState({ ...state, showHide: true });
    setEditVal(false);
  };

  // console.log(state.boardId)
  // console.log(state.courseId)

  //use on handling options
  const selectedBoard = () => {
    let o = state.drop_board.findIndex((e) => e.cmn_syllabus_name === state.board);
    return o === -1 ? 0 : state.drop_board[o].qb_syllabus_id;
  };

  const selectedClass = () => {
    let o = state.drop_class.findIndex((e) => e.qb_class_number === state.class);
    return o === -1 ? 0 : state.drop_class[o].qb_class_id;
  };

  const selectedSubject = () => {
    let o = state.drop_sub.findIndex((e) => e.name === state.subject);
    return o === -1 ? 0 : state.drop_sub[o].id;
  };

  const handleSave = (values, submitProps, formik) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    if (editVal) {
      // This api is not implimented yet
      Axios.put(
        `${BASE_URL}/subject/${state.cmn_subjectID}`,
        {
          cmn_school_syllabus_id: state.cmn_school_syllabusID,
          name: values.subject_name,
          qb_class_id: state.qb_classID,
          qb_subject_id: state.qb_subjectID,
        },
        Auth,
      )
        .then((res) => {
          toast.success('data edited Successfully!');
          if (res.data.status === true) {
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          } else {
            toast.error('error while edit');
          }
        })
        .catch((err) => console.log(err.response, 'error'));
    } else {
      // This api is not implimented yet
      Axios.post(
        `${BASE_URL}/subject`,
        {
          cmn_school_syllabus_id: state.cmn_school_syllabusID,
          name: values.subject_name,
          qb_class_id: state.qb_classID,
          qb_subject_id: state.qb_subjectID,
        },
        Auth,
      )
        .then((res) => {
          toast.success('data created successfully!');
          if (res.data.status === true) {
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const onTableClick = async (boardId, courseId, subjectId) => {
    console.log('id', boardId, courseId);
    // This api is not implimented yet
    const getSubSubjects = await Axios.get(
      `${BASE_URL}/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/detailed-info`,
      Auth,
    );

    console.log(getSubSubjects.data);
  };

  const handleEdit = (
    cmn_subject_id,
    cmn_subject_name,
    cmn_syllabus_name,
    qb_class_number,
    qb_subject_name,
    cmn_school_syllabus_id,
    qb_class_id,
    qb_subject_id,
  ) => {
    setEditVal(true);
    setState({
      ...state,
      cmn_subjectID: cmn_subject_id,
      board: cmn_syllabus_name,
      subject: qb_subject_name,
      class: qb_class_number,
      subject_name: cmn_subject_name,
      cmn_school_syllabusID: cmn_school_syllabus_id,
      qb_classID: qb_class_id,
      qb_subjectID: qb_subject_id,
      showHide: true,
    });
    selectedBoard();
    selectedClass();
    selectedSubject();
  };

  const handleDelete = (e) => {
    // This api is not implimented yet
    Axios.delete(`${BASE_URL}/subject/${state.cmn_subjectID}`, Auth)
      .then((res) => {
        toast.error('data deleted Successfully!');
        if (res.data.status === true) {
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch((err) => console.log(err, 'error'));
  };

  const handleBoardChange = async (e) => {
    let board = e.target.value;
    // This api is not implimented yet
    const getClass = await Axios.get(`${BASE_URL}/institution/${school_id}/board/${board}/class`, Auth);
    setState({
      ...state,
      board: board,
      drop_class: getClass.data.data,
      cmn_school_syllabusID: board,
    });
  };

  const downloadFile = async () => {
    try {
      // if(state.boardId  === "-1"){
      //   toast.error('Please select board')
      // } else if(state.courseId === ""){
      //   toast.error('Please select course')
      // } else {
      // PORT-IS-1: Download subjects for a course via excel export
      const response = await downloadSubjectsForCourseExcel(school_id, state.boardId, state.courseId);
      if (response.data) {
        let filename = response.headers['content-disposition'].split('=')[1];
        var blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });
        saveAs(blob, filename);
        toast.success('file downloaded');
      }
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/${state.courseId}/subjects/excel-export`,
      //   {
      //     ...Auth,
      //     responseType: "arraybuffer",
      //   }
      // )
      // .then((response) => {
      //   // console.log(response)
      //   let filename = response.headers["content-disposition"].split("=")[1];
      //   var blob = new Blob([response.data], {
      //     type: "application/octet-stream",
      //   });
      //   saveAs(blob, filename);
      //   toast.success("file downloaded");
      // });
      // }
    } catch (err) {
      console.log(err.response, 'error');
      toast.error('No records');
    }
  };

  const uploadFile = async (e) => {
    // if(state.boardId === "-1"){
    //   toast.error('Please select board')
    // } else if(state.courseId === ""){
    //   toast.error('Please select course')
    // } else {
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);

    formdata.append('filetoupload', file);
    // PORT-IS-2: Add, update, delete subjects to a course
    try {
      await updateSubjectCourse(school_id, state?.boardId, state?.courseId, formdata);
      setLoading(false);
      toast.success('File Uploaded Successfully');
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (err) {
      setLoading(false);
      if (err) {
        setErrorUpload(true);
        toast.error(err.response.data.message);
        setMsg(err.response.data.message);
        setErrMsg(err.response.data.data);
      }
    }

    // Axios({
    //   url: `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/${state.courseId}/subjects/excel-import`,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //     "Content-Type": "multipart/form-data",
    //     Accept: "*",
    //     type: "formData",
    //     "Access-Control-Allow-Headers": "*",
    //   },
    //   data: formdata,
    // })
    //   .then((res) => {
    //     // console.log(res)
    //     setLoading(false);
    //     toast.success("File Uploaded Successfully");
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //     setLoading(false);
    //     if (err) {
    //       setErrorUpload(true);
    //       toast.error(err.response.data.message);
    //       setMsg(err.response.data.message);
    //       setErrMsg(err.response.data.data);
    //     }
    //   });
    // }
  };

  const handleClosePopup = () => setErrorUpload(false);
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  const ErrModal = () => {
    // let errHead = ["Subject Id", "Subject Name", "Error"];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>
                      {key + 1}. {elm.message}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  return (
    <div className={styles.container}>
      <Box className={styles.top_box} component="div">
        <Box component="div" className={styles.top_box_l}>
          <div>
            <label>Board</label>
            <Box
              component="select"
              className={styles.options}
              onChange={(e) => {
                changedBoard(e, 'board');
              }}
            >
              <option value="-1">{'Board'}</option>
              {state.optBD.map((el, key) => (
                <option value={el.board_id} key={key}>
                  {el.display_name}
                </option>
              ))}
            </Box>
          </div>
          <div>
            <label>Course</label>
            <Box
              component="select"
              className={styles.options}
              onChange={(e) => {
                changedBoard(e, 'course');
              }}
            >
              <option value="-1">{'Course'}</option>
              {state.optCourse.map((el, key) => (
                <option value={el.course_id} key={key}>
                  {el.display_name}
                </option>
              ))}
            </Box>
          </div>
        </Box>
        <Box component="div" className={styles.buttons}>
          <Box component="div" className={styles.buttons_add} onClick={handleClickOpen}>
            Add
          </Box>
          <Box component="div" className={styles.buttons_format} onClick={downloadFile}>
            <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ paddingRight: '10px' }} />
            Download Subject List
          </Box>
          <Box component="div" className={styles.buttons_data}>
            <Formik>
              {(formik) => {
                return (
                  <div>
                    <Form>
                      <Field
                        type="file"
                        id="filetoupload"
                        name="filetoupload"
                        onChange={(e) => {
                          uploadFile(e);
                          e.target.value = null;
                        }}
                        style={{ display: 'none' }}
                      />
                      <label
                        htmlFor="filetoupload"
                        style={{
                          width: '120px',
                          height: '32px',
                          marginBottom: '0px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                          fontWeight: 'bold',
                          fontSize: '10px',
                          color: 'rgb(155, 139, 139)',
                          cursor: 'pointer',
                          textAlign: 'center',
                          padding: '11px',
                        }}
                      >
                        <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ paddingRight: '10px' }} />
                        Upload Subject List
                      </label>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <div className={styles.loader}>
          <ReactBootStrap.Spinner animation="border" variant="success" /> &nbsp;
          <span className={styles.loaderTxt}>Loading...</span>
        </div>
      ) : null}
      <Box component="div" className={styles.tableBox}>
        <div className={styles.table_box}>
          {isLoading === true ? (
            <Example />
          ) : (
            <div className={styles.schoolList_Table}>
              <div className={styles.schoolList_Table_head}>
                {t_head.map((elm, key) => (
                  <div key={key} className={styles.schoolList_Table_head_data}>
                    {elm}
                  </div>
                ))}
              </div>
              {
                <div className={styles.schoolList_Table_body}>
                  {state.subjectList.length === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      No Data Found
                      <span role="img" aria-label="jsx-a11y/accessible-emoji">
                        😔
                      </span>
                    </div>
                  ) : (
                    state.subjectList.map((elm, key) => (
                      <div
                        key={key}
                        className={styles.schoolList_Table_body_row}
                        onClick={() => {
                          onTableClick(board_id, course_id, subjectId);
                        }}
                      >
                        <div className={styles.schoolList_Table_body_row_td2}>{elm.display_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.board_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.course_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.subject_code}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.sub_subjects_count}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.medium_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.taxonomy_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.description}</div>
                        <div
                          className={styles.schoolList_Table_body_row_td3}
                          onClick={() => {
                            handleEdit(
                              elm.cmn_subject_id,
                              elm.cmn_subject_name,
                              elm.cmn_syllabus_name,
                              elm.qb_class_number,
                              elm.qb_subject_name,
                              elm.cmn_school_syllabus_id,
                              elm.qb_class_id,
                              elm.qb_subject_id,
                            );
                          }}
                        >
                          <Icon>
                            <img
                              src={require('../../../../assets/icons/edit.png')}
                              alt="edit"
                              style={{
                                width: '16px',
                                height: '16px',
                                cursor: 'pointer',
                              }}
                            />
                          </Icon>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              }
            </div>
          )}
        </div>
      </Box>

      <ModalComponent show={state.showHide} onClick={handleClose}>
        <Box className={styles.main_box} component="div" style={{ marginTop: '-20px' }}>
          <Formik initialValues={initialValues || state} validationSchema={validationBox} onSubmit={handleSave} enableReinitialize>
            {(formik) => {
              return (
                <Box component="div" style={{ width: '100%', height: 'auto' }}>
                  <Form className={styles.modal_Form}>
                    <label htmlFor="board">Board</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="board"
                        onChange={handleBoardChange}
                        value={editVal ? state.cmn_school_syllabusID : undefined}
                        className={styles.d_options}
                        // onChange={(e)=>setState({...state,board:e.target.value})}
                      >
                        <option>{'Board'}</option>
                        {state.drop_board.map((elm, key) => {
                          return (
                            <option value={elm.cmn_school_syllabus_id} key={key}>
                              {elm.cmn_syllabus_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Grade</label>
                    <Box className={styles.main_box_2} component="div">
                      <Field
                        as="select"
                        name="class"
                        className={styles.d_options}
                        value={editVal ? state.qb_classID : undefined}
                        onChange={(e) => setState({ ...state, qb_classID: e.target.value })}
                      >
                        <option>{editVal ? state.class : 'Grade'}</option>
                        {state.drop_class.map((elm, key) => {
                          return (
                            <option value={elm.qb_class_id} key={key}>
                              {elm.qb_class_number}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Subject (Stucle) to be linked</label>
                    <Box className={styles.main_box_2} component="div">
                      <Field
                        as="select"
                        name="subject"
                        className={styles.d_options}
                        value={editVal ? selectedSubject() : undefined}
                        onChange={(e) => setState({ ...state, qb_subjectID: e.target.value })}
                        // onChange={(e)=>formik.setFieldValue('subject',e.target.value)}
                      >
                        <option>{'Subject'}</option>
                        {state.drop_sub.map((elm, key) => {
                          return (
                            <option value={elm.id} key={key}>
                              {elm.name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Subject Display Name</label>
                    <Box className={styles.main_box_3} component="div">
                      <Field
                        name="subject_name"
                        placeholder="Subject display name"
                        className={styles.B_Field}
                        id="subject_name"
                        value={editVal ? state.subject_name : undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            subject_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box className={styles.buttonss}>
                      {editVal ? (
                        <Box className={styles.deleteBtn} onClick={handleDelete}>
                          <img
                            src={require('../../../../assets/icons/DeleteBox.png')}
                            alt="deletebox"
                            style={{ cursor: 'pointer' }}
                          />
                        </Box>
                      ) : null}
                      <ButtonComponent className={styles.cancelBtn} onClick={handleClose} type="reset">
                        Cancel
                      </ButtonComponent>
                      <ButtonComponent
                        className={styles.saveBtn}
                        type="submit"
                        onClick={(e) => {
                          let addedit_value = document.getElementById('subject_name').value;
                          formik.setFieldValue('subject_name', addedit_value);
                        }}
                        // disabled={!formik.isValid || formik.isSubmitting}
                      >
                        Save
                      </ButtonComponent>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </ModalComponent>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default Subject;
