import React, { useState, useEffect } from 'react';
import { Icon, Box } from '@material-ui/core';
// import { Pagination } from '@material-ui/lab';
import { saveAs } from 'file-saver';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import { Dialogbox } from '../../../../components/index';
import Axios from 'axios';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';
import styles from './SubSubject.module.css';
import { BASE_URL } from '../../../../config/Api';
import { ModalComponent } from '../../../../components/index';
import ButtonComponent from '../../../../components/button/Button';
import { getAllMediums } from '../../../../api/mediums';
import { getCoursesListOfAnInstitution } from '../../../../api/institutionCourses';
import {
  downloadSubSubjectExcel,
  addUpdateDeleteSubSubjectFromExcel,
  getListOfSubSubjects,
} from '../../../../api/institutionSubSubjects';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';
import { getAllSubjectsByBoardId } from '../../../../api/InstitutionSubjects';

var board_id = -1;
var course_id = -1;
var subject_id = -1;
var medium_id = -1;
var pageNum = 1;
var rows = 10;
var boardID;
var classID;
var subjectID;
var topicID;
var default_page = 1;
const initialValues = {
  board: '',
  class: '',
  subject: '',
  subSubject: '',
  topic: '',
  topic_name: '',
  desc: '',
};

const validationBox = Yup.object({
  board: Yup.string(),
  class: Yup.string(),
  subject: Yup.string(),
  subSubject: Yup.string(),
  topic: Yup.string(),
  topic_name: Yup.string(),
  desc: Yup.string(),
});

var t_head = ['Sub Subject', 'Board', 'Ref Course', 'Subject', 'Stucle Sub Subject(Code)', 'Medium', 'Description', ' '];

let pageNo = 10;

const SubSubject = () => {
  const [editVal, setEditVal] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  console.log(pageNum, default_page, pageNo, setMsg, loading, setErrMsg);

  const [state, setState] = useState({
    optBD: [],
    optCourse: [],
    optSubject: [],
    optMed: [],
    boardId: '-1',
    courseId: '-1',
    subjectId: '-1',
    subSubjectList: [],
    board_id: '',
    class_id: '',
    subject_id: '',
    language_id: '',
    topic_id: '',
    cmn_topic_id: '',
    topic_name: '',
    desc: '',
    subSubject: '',
    pagecount: null,
    board_editvalue: 'Board',
    class_editvalue: 'Class',
    subject_editvalue: 'Subject',
    topic_stucle_editvalue: 'Topic(Stucle) to be linked',
    topic_editvalue: '',
    qb_topic_id_editvalue: '',
    cmn_subject_id_editvalue: '',
    cmn_topic_id_editvalue: '',
  });

  const school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 3);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchResults = async () => {
    setIsLoading(true);
    try {
      // PORT-MD-1: Retrieve list of all mediums
      const getMedium = await getAllMediums(Auth);
      // const getMedium = await Axios.get(`${BASE_URL}/mediums/`, Auth);
      // PORT-IB-5: Retrieve list of boards of an institution

      const getBoard = await getBoardsByNameInInstitution(school_id);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards`,
      //   Auth
      // );
      // PORT-ISS-3: Retrieve list of sub subjects
      const getSubSubjects = await getListOfSubSubjects(school_id, state.boardId, state.courseId, state.subjectId, medium_id, Auth);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/${state.courseId}/subjects/${state.subjectId}/sub_subjects/detailed-info?medium_id=${medium_id}`,
      //   Auth
      // );

      // console.log(getBoard);
      // console.log(getCourse);
      // console.log(getSubject);

      // const TableList = await (
      //   await Axios.get(
      //     `${BASE_URL}/institution/${schoolId}/topic/search?board_id=&class_id=&subject_id=&rows=10&page_number=1`,
      //     Auth
      //   )
      // ).data;

      // let extraPage = TableList.count % pageNo === 0 ? 0 : 1;

      setIsLoading(false);
      setState((prevState) => ({
        ...prevState,
        optBD: getBoard.data.data,
        optMed: getMedium.data.data,
        subSubjectList: getSubSubjects.data.data,
        // optCourse: getCourse.data,
        // optSubject: getSubject.data
        // pagecount: Math.floor(TableList.count / pageNo + extraPage),
      }));
    } catch (err) {
      console.log(err, 'error while fetching data');
    }
  };
  // console.log(state.drop_board)

  // console.log(state.pagecount)
  // console.log(pageNo);
  // console.log(pageNum);
  // console.log(default_page)

  const changedBoard = async (e, selectedvalue) => {
    if (selectedvalue === 'board') {
      pageNum = 1;
      default_page = 1;
      board_id = e.target.value;
      if (board_id === 'Board') {
        board_id = '';
        pageNum = 1;
        default_page = 1;
      }
    } else if (selectedvalue === 'course') {
      course_id = e.target.value;
      pageNum = 1;
      default_page = 1;
      if (course_id === 'Course') {
        course_id = '';
        pageNum = 1;
        default_page = 1;
      }
    } else if (selectedvalue === 'subject') {
      subject_id = e.target.value;
      pageNum = 1;
      default_page = 1;
      if (subject_id === 'subject') {
        subject_id = '';
        pageNum = 1;
        default_page = 1;
      }
    } else if (selectedvalue === 'medium') {
      medium_id = e.target.value;
      pageNum = 1;
      default_page = 1;
    } else if (selectedvalue === 'pagenumber') {
      window.scrollTo(0, 0);
      window.screenTop = 0;
      pageNum = e;
    } else if (selectedvalue === 'showrow') {
      window.screenTop = 0;
      window.scrollTo(0, 0);
      rows = e.target.value;
      pageNo = rows;
      setState({ ...state });
      pageNum = 1;
      default_page = 1;
    }
    try {
      // PORT-IC-4: Retrieve list of courses of an institution
      const getCourse = await getCoursesListOfAnInstitution(school_id, board_id, Auth);
      // const getCourse = await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses`,
      //   Auth
      // );

      const getSubject = await getAllSubjectsByBoardId(school_id, board_id, course_id, '');
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects`,
      //   Auth
      // );
      // PORT-ISS-3: Retrieve list of sub subjects
      const getSubSubjects = await getListOfSubSubjects(school_id, state.boardId, state.courseId, state.subjectId, medium_id, Auth);
      // const getSubSubjects = await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/detailed-info?medium_id=${medium_id}`,
      //   Auth
      // );

      // let extraPage = getTableData.count % rows === 0 ? 0 : 1;
      setState((prevState) => ({
        ...prevState,
        boardId: board_id,
        courseId: course_id,
        subjectId: subject_id,
        optCourse: getCourse.data.data,
        optSubject: getSubject.data.data,
        subSubjectList: getSubSubjects.data.data,
        // pagecount: Math.floor(getTableData.count / rows + extraPage),
      }));
    } catch (err) {
      console.log(err, 'error');
    }
  };

  // const pagechange = (e, value) => {
  //   // console.log(value)
  //   default_page = value;
  //   changedBoard(value, 'pagenumber');
  // };

  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };
  const handleClickOpen = () => {
    setState({ ...state, showHide: true });
    setEditVal(false);
  };

  const handleSave = (values, submitProps, formik) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    if (editVal) {
      // This api is not implimented yet
      Axios.put(
        `${BASE_URL}/topic`,
        {
          name: values.topic_name,
          cmn_subject_id: state.cmn_subject_id_editvalue,
          qb_topic_id: state.qb_topic_id_editvalue,
          cmn_topic_id: state.cmn_topic_id_editvalue,
        },
        Auth,
      )
        .then((res) => {
          toast.success('data edited successfully!');
          if (res.data.status === true) {
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    } else {
      // This api is not implimented yet
      Axios.post(
        `${BASE_URL}/topic`,
        {
          name: values.topic_name,
          topic_id: topicID,
          subject_id: subjectID,
          // cmn_topic_id: state.cmn_topic_id_editvalue,
        },
        Auth,
      )
        .then((res) => {
          toast.success('Topic Crerated successfully!');
          if (res.data.status === true) {
            setTimeout(() => {
              window.location.reload(false);
              toast.success(res.data.data.status);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const handleEdit = async (cmn_syllabus_name, qb_class_number, cmn_subject_name, qb_topic_name, cmn_topic_name, qb_topic_id) => {
    setEditVal(true);
    setState({
      ...state,
      board_editvalue: cmn_syllabus_name,
      class_editvalue: qb_class_number,
      subject_editvalue: cmn_subject_name,
      topic_stucle_editvalue: qb_topic_name,
      topic_name: cmn_topic_name,
      qb_topic_id_editvalue: qb_topic_id,
      showHide: true,
    });
  };

  let request = '';
  const handleDelete = async () => {
    if (state.cmn_topic_id_editvalue) request = request + 'cmn_topic_id=' + state.cmn_topic_id_editvalue;
    if (state.qb_topic_id_editvalue) request = request + (request.length === 0) ? '' : '&qb_topic_id=' + state.qb_topic_id_editval;
    if (state.cmn_subject_id_editvalue)
      request = request + (request.length === 0) ? '' : '&cmn_subject_id=' + state.cmn_subject_id_editvalue;
    // This api is not implimented yet
    Axios.delete(
      `${BASE_URL}/topic?cmn_topic_id=${
        state.cmn_topic_id_editvalue === null ? '' : state.cmn_topic_id_editvalue
      }&qb_topic_id=${state.qb_topic_id_editvalue}&cmn_subject_id=${state.cmn_subject_id_editvalue}&name=delete`,
      Auth,
    )
      .then((res) => {
        toast.error('data deleted Successfully!');
        if (res.data.status === true) {
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const downloadFile = async () => {
    // try {
    // if(state.boardId  === "-1"){
    //   toast.error('Please select board')
    // } else if(state.courseId === ""){
    //   toast.error('Please select course')
    // } else if(state.subjectId === ""){
    //   toast.error('Please select subject')
    // } else {

    // PORT-ISS-1: Download Sub Subjects for a Subject via Excel Download
    try {
      const response = await downloadSubSubjectExcel(school_id, state.boardId, state.courseId, state.subjectId, medium_id, Auth);
      let filename = response.headers['content-disposition'].split('=')[1];
      var blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      saveAs(blob, filename);
      toast.success('file downloaded');
    } catch (err) {
      console.log(err.response, 'error');
      toast.error('No records');
    }

    //   await Axios.get(
    //     `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/${state.courseId}/subjects/${state.subjectId}/sub_subjects/excel-export?medium_id=${medium_id}`,
    //     {
    //       ...Auth,
    //       responseType: "arraybuffer",
    //     }
    //   ).then((response) => {
    //     // console.log(response)
    //     let filename = response.headers["content-disposition"].split("=")[1];
    //     var blob = new Blob([response.data], {
    //       type: "application/octet-stream",
    //     });
    //     saveAs(blob, filename);
    //     toast.success("file downloaded");
    //   });
    //   // }
    // } catch (err) {
    //   console.log(err.response, "error");
    //   toast.error("No records");
    // }
  };

  const uploadFile = async (e) => {
    // if(state.boardId === "-1"){
    //   toast.error('Please select board')
    // } else if(state.courseId === ""){
    //   toast.error('Please select course')
    // } else if(state.subjectId === ""){
    //   toast.error('Please select subject')
    // } else {
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);

    formdata.append('filetoupload', file);
    // PORT-ISS-2: Add, update, delete sub subjects via excel
    try {
      await addUpdateDeleteSubSubjectFromExcel(school_id, state.boardId, state.courseId, state.subjectId, Auth);
      setLoading(false);
      toast.success('File Uploaded Successfully');
    } catch (error) {}

    // Axios({
    //   url: `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/${state.courseId}/subjects/${state.subjectId}/sub_subjects/excel-import`,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //     "Content-Type": "multipart/form-data",
    //     Accept: "*",
    //     type: "formData",
    //     "Access-Control-Allow-Headers": "*",
    //   },
    //   data: formdata,
    // })
    //   .then((res) => {
    //     // console.log(res)
    //     setLoading(false);
    //     toast.success("File Uploaded Successfully");
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //     setLoading(false);
    //     if (err) {
    //       setErrorUpload(true);
    //       toast.error(err.response.data.message);
    //       setMsg(err.response.data.message);
    //       setErrMsg(err.response.data.data);
    //     }
    //   });
    // }
  };

  const handleClosePopup = () => setErrorUpload(false);

  const ErrModal = () => {
    // let errHead = ["Subject Id", "Subject Name", "Error"];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>
                      {key + 1}. {elm.message}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  const handleBoardChange = async (e) => {
    boardID = e.target.value;
    // languageID = selectedBoard();
    // This api is not implimented yet
    const getClass = await Axios.get(`${BASE_URL}/institution/${school_id}/board/${boardID}/class`, Auth);
    setState({ ...state, board: boardID, drop_class: getClass.data.data });
  };

  const handleClassChange = async (e) => {
    classID = e.target.value;
    // This api is not implimented yet
    const getSubject = await Axios.get(`${BASE_URL}/institution/${school_id}/board/${boardID}/class/${classID}/subject`, Auth);
    setState({ ...state, class_id: classID, drop_sub: getSubject.data.data });
  };

  const handleSubjectChange = async (e) => {
    subjectID = e.target.value;
    // This api is not implimented yet
    const getSubject = await Axios.get(
      `${BASE_URL}/topic/search?syllabus_id=${boardID}&class_id=${classID}&subject_id=${subjectID}`,
      Auth,
    );
    setState({ ...state, drop_topic: getSubject.data.data });
  };
  // const handleTopicChange = async (e) => {
  //   topicID = e.target.value;
  //   // setState({ ...state, drop_topic: getSubject.data });
  // };

  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  return (
    <div className={styles.container}>
      <Box className={styles.top_box} component="div">
        <Box component="div" className={styles.top_box_l}>
          <div>
            <label>Board</label>
            <Box
              component="select"
              className={styles.options}
              onChange={(e) => {
                changedBoard(e, 'board');
              }}
            >
              <option value="-1">{'Board'}</option>
              {state.optBD.map((el, key) => (
                <option value={el.board_id} key={key}>
                  {el.display_name}
                </option>
              ))}
            </Box>
          </div>
          <div>
            <label>Ref Course</label>
            <Box
              component="select"
              className={styles.options}
              onChange={(e) => {
                changedBoard(e, 'course');
              }}
            >
              <option value="-1">{'Course'}</option>
              {state.optCourse.map((el, key) => (
                <option value={el.course_id} key={key}>
                  {el.display_name}
                </option>
              ))}
            </Box>
          </div>
          <div>
            <label>Subject</label>
            <Box
              component="select"
              className={styles.options}
              onChange={(e) => {
                changedBoard(e, 'subject');
              }}
            >
              <option value="-1">{'Subject'}</option>
              {state.optSubject.map((el, key) => (
                <option value={el.subject_id} key={key}>
                  {el.display_name}
                </option>
              ))}
            </Box>
          </div>
          <div>
            <label>Medium</label>
            <Box
              component="select"
              className={styles.options}
              onChange={(e) => {
                changedBoard(e, 'medium');
              }}
            >
              <option value="-1">{'Medium'}</option>
              {state.optMed.map((el, key) => (
                <option value={el.medium_id} key={key}>
                  {el.medium_name}
                </option>
              ))}
            </Box>
          </div>
        </Box>
        <Box component="div" className={styles.buttons}>
          <Box component="div" className={styles.buttons_add} onClick={handleClickOpen}>
            Add
          </Box>
          <Box component="div" className={styles.buttons_format} onClick={downloadFile}>
            <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ paddingRight: '10px' }} />
            Download SubSubject List
          </Box>
          <Box component="div" className={styles.buttons_data}>
            <Formik>
              {(formik) => {
                return (
                  <div>
                    <Form>
                      <Field
                        type="file"
                        id="filetoupload"
                        name="filetoupload"
                        onChange={(e) => {
                          uploadFile(e);
                          e.target.value = null;
                        }}
                        style={{ display: 'none' }}
                      />
                      <label
                        htmlFor="filetoupload"
                        style={{
                          width: '120px',
                          height: '32px',
                          marginBottom: '0px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                          fontWeight: 'bold',
                          fontSize: '10px',
                          color: 'rgb(155, 139, 139)',
                          cursor: 'pointer',
                          textAlign: 'center',
                          padding: '11px',
                        }}
                      >
                        <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ paddingRight: '10px' }} />
                        Upload SubSubject List
                      </label>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
      <Box component="div" className={styles.tableBox}>
        <div className={styles.table_box}>
          {isLoading === true ? (
            <Example />
          ) : (
            <div className={styles.schoolList_Table}>
              <div className={styles.schoolList_Table_head}>
                {t_head.map((elm, key) => (
                  <div key={key} className={styles.schoolList_Table_head_data}>
                    {elm}
                  </div>
                ))}
              </div>
              {
                <div className={styles.schoolList_Table_body}>
                  {state.subSubjectList.length === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      No Data Found
                      <span role="img" aria-label="jsx-a11y/accessible-emoji">
                        😔
                      </span>
                    </div>
                  ) : (
                    state.subSubjectList.map((elm, key) => (
                      <div key={key} className={styles.schoolList_Table_body_row}>
                        <div className={styles.schoolList_Table_body_row_td2}>{elm.display_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.board_name}</div>
                        {/* <div className={styles.schoolList_Table_body_row_td3}>
                            {"-"}
                          </div> */}
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.course_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.subject_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.sub_subject_code}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.medium_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.description}</div>
                        <div
                          className={styles.schoolList_Table_body_row_td3}
                          onClick={() => {
                            handleEdit(
                              elm.display_name,
                              elm.board_name,
                              elm.course_name,
                              elm.subject_name,
                              elm.sub_subject_code,
                              elm.description,
                            );
                          }}
                        >
                          <Icon>
                            <img
                              src={require('../../../../assets/icons/edit.png')}
                              alt="edit"
                              style={{
                                width: '16px',
                                height: '16px',
                                cursor: 'pointer',
                              }}
                            />
                          </Icon>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              }
            </div>
          )}
        </div>
      </Box>
      {/* <Box className={styles.pages+" "+"pagination"} component="div">
        {default_page === 1 ? <div className={styles.first+" "+styles.inActive}>First</div> :
        <div className={styles.first} onClick={(e) =>pagechange(e,1)}>First</div>}
        <div
          className="col-md-10"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "50px",
          }}
        >
          <Pagination
            page={default_page}
            count={state.pagecount}
            onChange={pagechange}
          />
        </div>
        {default_page === state.pagecount ? <div className={styles.last+" "+styles.inActive}>Last</div> :
        <div className={styles.last} onClick={(e) =>pagechange(e,state.pagecount)}>Last</div>}
        <div className={styles.showRows}>
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "showrow");
            }}
          >
            <option value="all">{"Show All"}</option>
            <option value="10">{"Show 10"}</option>
            <option value="20">{"Show 20"}</option>
          </Box>
        </div>
      </Box> */}
      <ModalComponent show={state.showHide} onClick={handleClose} className="topicModal">
        <Box className={styles.main_box} component="div" style={{ marginTop: '-20px' }}>
          <Formik initialValues={initialValues || state} validationSchema={validationBox} onSubmit={handleSave} enableReinitialize>
            {(formik) => {
              return (
                <Box component="div" style={{ width: '100%', height: 'auto' }}>
                  <Form className={styles.modal_Form}>
                    <label htmlFor="board">Board</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="board"
                        value={editVal ? state.board_editvalue : undefined}
                        // value={undefined}
                        className={styles.d_options}
                        onChange={(e) => handleBoardChange(e)}
                      >
                        <option>{editVal ? state.board_editvalue : 'Board'}</option>
                        {state.drop_board.map((elm, key) => {
                          return (
                            <option value={elm.cmn_school_syllabus_id} key={key}>
                              {elm.cmn_syllabus_name ? elm.cmn_syllabus_name : elm.qb_syllabus_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <div className={styles.hierarchy}>Filter with hierarchy</div>
                    <label htmlFor="board">Course</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="class"
                        id="class"
                        value={editVal ? state.class_editvalue : undefined}
                        // value={undefined}
                        className={styles.d_options}
                        onChange={(e) => handleClassChange(e)}
                      >
                        <option>{editVal ? state.class_editvalue : 'Course'}</option>
                        {state.drop_class.map((elm, key) => {
                          return (
                            <option value={elm.qb_class_id} key={key}>
                              {elm.qb_class_number}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Subject</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="subject"
                        className={styles.d_options}
                        // value={undefined}
                        value={editVal ? state.subject_editvalue : undefined}
                        onChange={handleSubjectChange}
                      >
                        <option>{editVal ? state.subject_editvalue : 'Subject'}</option>
                        {state.drop_sub.map((elm, key) => {
                          return (
                            <option value={elm.cmn_subject_id} key={key}>
                              {elm.cmn_subject_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    {/* <label htmlFor="board">Sub-Subject</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="subSubject"
                        className={styles.d_options}
                        // value={undefined}
                        value={editVal ? state.subject_editvalue : undefined}
                        onChange={handleSubjectChange}
                      >
                        <option>
                          {editVal ? state.subject_editvalue : "sub-subject"}
                        </option>
                        {state.drop_sub.map((elm, key) => {
                          return (
                            <option value={elm.cmn_subject_id} key={key}>
                              {elm.cmn_subject_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box> */}
                    {/* <label htmlFor="board">Topic (Stucle) to be linked</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="topic"
                        className={styles.d_options}
                        value={
                          editVal ? state.topic_stucle_editvalue : undefined
                        }
                        // value={undefined}
                        onChange={(e) => handleTopicChange(e)}
                      >
                        <option>
                          {editVal
                            ? state.topic_stucle_editvalue
                            : "Topic(Stucle) to be linked"}
                        </option>
                        {state.drop_topic.map((elm, key) => {
                          return (
                            <option value={elm.qb_topic_id} key={key}>
                              {elm.qb_topic_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box> */}
                    <label htmlFor="topic_name">Stucle subSubject (Code)</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        name="subSubject"
                        // placeholder="Topic Display Name"
                        className={styles.B_Field}
                        id="subSubject"
                        value={editVal ? state.subSubject : undefined}
                        // value={undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            subSubject: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <label htmlFor="board">Topic Display Name</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        name="topic_name"
                        placeholder="Topic Display Name"
                        className={styles.B_Field}
                        id="topic_name"
                        value={editVal ? state.topic_name : undefined}
                        // value={undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            topic_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <label htmlFor="board">Description</label>
                    <Box className={styles.main_box_1} component="div" style={{ height: '60px' }}>
                      <Field
                        name="desc"
                        // placeholder="Topic Display Name"
                        component="textarea"
                        className={styles.B_Field}
                        id="desc"
                        value={editVal ? state.desc : undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            desc: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box className={styles.buttonss}>
                      {editVal ? (
                        <Box className={styles.deleteBtn} onClick={handleDelete}>
                          <img
                            src={require('../../../../assets/icons/DeleteBox.png')}
                            alt="deletebox"
                            style={{ cursor: 'pointer' }}
                          />
                        </Box>
                      ) : null}
                      <ButtonComponent className={styles.cancelBtn} onClick={handleClose} type="reset">
                        Cancel
                      </ButtonComponent>
                      <ButtonComponent
                        className={styles.saveBtn}
                        type="submit"
                        onClick={(e) => {
                          let addedit_value = document.getElementById('topic_name').value;
                          formik.setFieldValue('topic_name', addedit_value);
                        }}
                        // disabled={!formik.isValid || formik.isSubmitting}
                      >
                        Save
                      </ButtonComponent>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </ModalComponent>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default SubSubject;
