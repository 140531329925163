import https from './init/https';

// PORT-ICS-01: Retrieve list of Subject Tags for a Board
export const getSubjectTagsForBoardAPI = async (school_id, board_id) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/subject_tags`);
};

// PORT-ICS-02: Create a new Course-Subject Linkage
export const createCourseSubjectLinkageAPI = async (school_id, board_id, course_id, data) => {
  return await https.post(`/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects`, data);
};

// PORT-ICS-03: Update a Course-Subject Linkage
export const updateCourseSubjectLinkageAPI = async (institution_id, board_id, course_id, subject_id, data) => {
  return await https.put(`/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}`, data);
};

// PORT-ICS-04: Delete a new Course-Subject Linkage
export const deleteCourseSubjectLinkageAPI = async (institution_id, board_id, course_id, subject_id) => {
  return await https.delete(`/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}`);
};
