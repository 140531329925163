import React, { useState, useEffect } from 'react';
import { Box, InputBase } from '@material-ui/core';
// import { Pagination } from "@material-ui/lab";
import { toast, ToastContainer } from 'react-toastify';
import { Dialogbox } from '../../../../components/index';
import { saveAs } from 'file-saver';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TextError from '../../../../variables/errors/Text_error';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';
import styles from './GroupInstitutionStyle.module.css';
import add_emb from '../../../../assets/images/add_emblem.PNG';
import addImg from '../../../../assets/images/add.PNG';
import s3 from '../../../../config/DigitalOcean';
import configuration from '../../../../config/config';
import ButtonComponent from '../../../../components/button/Button';
import {
  getGroupExcelExport,
  postGroupExcelImport,
  getGroupInstListTableData,
  getOneGroupInfo,
  getChildGroups,
  putBasicInfo,
  downloadInsWithinGroup,
  uploadInsToGroup,
  getAllInstitutionsOfGrp,
  downloadEducatorsForGroup,
  addNewEducatorstoGroup,
  getListofEducatorsforGroup,
} from '../../../../api/groupInsitution';

var emb_file_path;
var imgpath1;
var imgpath2;
var imgpath3;
var imgpath4;
var imgpath5;

const initialValues = {
  grpInstitute_name: '',
  shortName: '',
  display_code: '',
  display_info: '',
  hierarchy: '',
  emblem: [],
  images: [],
};

const validationSchema = Yup.object({
  add_emblem: Yup.string(),
  institute_name: Yup.string(),
  display_info: Yup.string(),
});
// var t_head = [
//   "Board",
//   "Stucle Board (code)",
//   "Hierarchy",
//   "Courses",
//   "Description",
//   "",
// ];
// var default_page = 1;
var searchString;
var downloadApiUrl;
var uploadApiUrl;

// let pageNo = 10;
// var t_head = ["Group Institution", "Parent", "Child Groups", "Hierarchy", "Institution", "Description"]

var child_t_head = ['Group Institution Name', 'Hierarchy', 'Institutions', 'Display Info'];
var educator_t_head = ['Name', 'Email ID', 'Courses', 'Subjects'];
var inst_t_head = ['Short Name', 'Display code', 'Ref Parent Group', 'Hierarchy', 'Place', 'Pincode'];

const GroupInstitution = () => {
  const [editVal, setEditVal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [msg, setMsg] = useState('');
  const [errorUpload, setErrorUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [state, setState] = useState({
    t_body: [],
    childGrp_data: [],
    childGrpEdu_data: [],
    childGrpInst_data: [],
    pagecount: null,
    grpInstitute_name: '',
    shortName: '',
    display_code: '',
    display_info: '',
    hierarchy: '',
    imagePreview: add_emb,
    imgPrev1: addImg,
    imgPrev2: addImg,
    imgPrev3: addImg,
    imgPrev4: addImg,
    imgPrev5: addImg,
  });

  const school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('groupTabIndex', 1);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  console.log(Auth, loading, downloadApiUrl);
  const fetchResults = async () => {
    setIsLoading(true);
    try {
      // PORT-GI-3: Display Group Institution List as a Table

      const getGroupInst = await getGroupInstListTableData('');
      // await Axios.get(
      //   `${BASE_URL}/group_institutions/search?search_text=`,
      //   Auth
      // );
      // let extraPage = TableList.count % pageNo === 0 ? 0 : 1;

      setIsLoading(false);
      console.log(getGroupInst.data.data);
      setState((prevState) => ({
        ...prevState,
        t_body: getGroupInst.data.data,
        // pagecount: Math.floor(TableList.count / pageNo + extraPage),
      }));
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changedBoard = async (e, selectedvalue) => {
    if (selectedvalue === 'selectedsearch') {
      searchString = e.target.value;
      // default_page = 1;
      if (searchString === '') {
        // default_page = 1;
      }
    }
    // Debounce logic
    clearTimeout(changedBoard.timeoutId);
    changedBoard.timeoutId = setTimeout(async () => {
      try {
        // PORT-GI-3: Display Group Institution List as a Table

        const getInstitution = await getGroupInstListTableData(searchString);
        setState((prevState) => ({
          ...prevState,
          t_body: getInstitution.data.data,
          // pagecount: Math.floor(getTableData.count / rows + extraPage),
        }));

        //  await Axios.get(
        //   `${BASE_URL}/group_institutions/search?search_text=${searchString}`,
        //   Auth
        // );

        // let extraPage = getTableData.count % rows === 0 ? 0 : 1;
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
      }
    }, 500); // 500ms debounce delay
  };

  const onTableClick = async (grpInst_id) => {
    window.localStorage.setItem('grpInstId', grpInst_id);
    console.log(grpInst_id, 'grpInst_id');
    // PORT-GI-4: Retrieve Info about a Group Institution
    try {
      if (grpInst_id && grpInst_id !== null) {
        const grpInstList = await getOneGroupInfo(grpInst_id);
        // await Axios.get(
        //   `${BASE_URL}/group_institutions/${grpInst_id}/basic_info`,
        //   Auth
        // );

        // PORT-GI-5: Retrieve Child Group Institutions

        const childGrpList = await getChildGroups(grpInst_id);
        // await Axios.get(
        //   `${BASE_URL}/group_institutions/${grpInst_id}/child_group_institutions`,
        //   Auth
        // );

        // PORT-GI-9: Display list of insitutions for a Group Insitution

        const childGrpInstList = await getAllInstitutionsOfGrp(grpInst_id);
        //  await Axios.get(
        //   `${BASE_URL}/group_institutions/${grpInst_id}/institutions`,
        //   Auth
        // );

        // PORT-GI-12: Display list of Group Educators for a Group Institution

        const childGrpEduList = await getListofEducatorsforGroup(grpInst_id);
        // await Axios.get(
        //   `${BASE_URL}/group_institutions/${grpInst_id}/group_educators`,
        //   Auth
        // );
        setEditVal(true);
        setState((prevState) => ({
          ...prevState,
          childGrp_data: childGrpList.data.data,
          childGrpEdu_data: childGrpEduList.data.data,
          childGrpInst_data: childGrpInstList.data.data,
          grpInstitute_name: grpInstList.data.data[0].group_institution_name,
          shortName: grpInstList.data.data[0].short_name,
          display_code: grpInstList.data.data[0].display_code,
          display_info: grpInstList.data.data[0].display_info,
          hierarchy: grpInstList.data.data[0].parent_hierarchy_path,
          imagePreview: grpInstList.data.data[0].emblem_file_path !== null ? grpInstList.data.data[0].emblem_file_path : add_emb,
          imgPrev1: grpInstList.data.data[0].image_1_file_path !== null ? grpInstList.data.data[0].image_1_file_path : addImg,
          imgPrev2: grpInstList.data.data[0].image_2_file_path !== null ? grpInstList.data.data[0].image_2_file_path : addImg,
          imgPrev3: grpInstList.data.data[0].image_3_file_path !== null ? grpInstList.data.data[0].image_3_file_path : addImg,
          imgPrev4: grpInstList.data.data[0].image_4_file_path !== null ? grpInstList.data.data[0].image_4_file_path : addImg,
          imgPrev5: grpInstList.data.data[0].image_5_file_path !== null ? grpInstList.data.data[0].image_5_file_path : addImg,
        }));
      }
    } catch (error) {
      console.log(error);
    }
    setNextPage(true);
  };

  const downloadFile = async (e, val) => {
    var id = window.localStorage.getItem('grpInstId');
    let response;
    toast.info('Downloading...');
    try {
      if (val === 'grpList') {
        // downloadApiUrl = `${BASE_URL}/group_institutions/excel-export?search_text=`;

        // PORT-GI-1: Excel Export of Group Institutions

        response = await getGroupExcelExport();
      } else if (val === 'instDownload') {
        // downloadApiUrl = `${BASE_URL}/group_institutions/${id}/institutions/excel-export`;

        // PORT-GI-7: Excel Export of Insitutions within a Group
        response = await downloadInsWithinGroup(id);
      } else if (val === 'eduDownload') {
        // PORT-GI-10: Excel Export of Group Educators for a Group Institution

        response = await downloadEducatorsForGroup(id);
        // downloadApiUrl = `${BASE_URL}/group_institutions/${id}/group_educators/excel-export`;
      }

      let filename = response?.headers['content-disposition'].split('=')[1];
      var blob = new Blob([response?.data], {
        type: 'application/octet-stream',
      });
      saveAs(blob, filename);
      toast.success('file downloaded');
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      toast.error('No records');
    }
    console.log(val);
    // try {
    //   await Axios.get(downloadApiUrl, {
    //     ...Auth,
    //     responseType: "arraybuffer",
    //   }).then((response) => {
    //     // console.log(response)
    //     let filename = response.headers["content-disposition"].split("=")[1];
    //     var blob = new Blob([response.data], {
    //       type: "application/octet-stream",
    //     });
    //     saveAs(blob, filename);
    //     toast.success("file downloaded");
    //   });
    //   // }
    // } catch (err) {
    //   if (err.response && err.response.data) {
    //     console.log(err.response.data.message);
    //     toast.error(err.response.data.message);
    //   }
    //   toast.error("No records");
    // }
  };

  const uploadFile = async (e, val) => {
    var id = window.localStorage.getItem('grpInstId');
    console.log(val);
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);
    console.log(uploadApiUrl);
    formdata.append('filetoupload', file);

    try {
      if (val === 'grpList') {
        // uploadApiUrl = `${BASE_URL}/group_institutions/excel-import`;
        // PORT-GI-2: Excel Import of Group Insititutions

        await postGroupExcelImport(formdata);
      } else if (val === 'InstUpload') {
        console.log('instUp');
        // PORT-GI-8: Excel Import of Insitutions for a Group Insitution

        await uploadInsToGroup(id, formdata);
        // uploadApiUrl = `${BASE_URL}/group_institutions/${id}/institutions/excel-import`;
      } else if (val === 'eduUpload') {
        // PORT-GI-11: Excel Import of Group Educators for a Group Institution

        await addNewEducatorstoGroup(id, formdata);
        // uploadApiUrl = `${BASE_URL}/group_institutions/${id}/group_educators/excel-import`;
      }
      setLoading(false);
      toast.success('File Uploaded Successfully');
      // onTableClick(window.localStorage.getItem('grpInstId'));
      fetchResults();
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      if (err) {
        setErrorUpload(true);
        if (err.response && err.response.data) {
          console.log(err?.response?.data?.message);
          toast.error(err?.response?.data?.message);
        }
        setMsg(err?.response?.data.message);
        setErrMsg(err?.response.data.data);
      }
    }

    // Axios({
    //   url: uploadApiUrl,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //     "Content-Type": "multipart/form-data",
    //     Accept: "*",
    //     type: "formData",
    //     "Access-Control-Allow-Headers": "*",
    //   },
    //   data: formdata,
    // })
    //   .then((res) => {
    //     // console.log(res)
    //     setLoading(false);
    //     toast.success("File Uploaded Successfully");
    //     onTableClick(window.localStorage.getItem("grpInstId"));
    //     fetchResults();
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //     setLoading(false);
    //     if (err) {
    //       setErrorUpload(true);
    //       if (err.response && err.response.data) {
    //         console.log(err.response.data.message);
    //         toast.error(err.response.data.message);
    //       }
    //       setMsg(err.response.data.message);
    //       setErrMsg(err.response.data.data);
    //     }
    //   });
    // }
  };

  var FilePath;

  const handleChange = (e, imgPath) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (imgPath === 'add_emblem') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imagePreview: reader.result,
        });
      };
    } else if (imgPath === 'imagepath1') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev1: reader.result,
        });
      };
    } else if (imgPath === 'imagepath2') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev2: reader.result,
        });
      };
    } else if (imgPath === 'imagepath3') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev3: reader.result,
        });
      };
    } else if (imgPath === 'imagepath4') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev4: reader.result,
        });
      };
    } else if (imgPath === 'imagepath5') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev5: reader.result,
        });
      };
    }
    reader.readAsDataURL(file);
    if (e.target.files && e.target.files[0]) {
      const blob = e.target.files[0];
      let fileType = e.target.files[0].type;
      let ext = fileType.substr(fileType.lastIndexOf('/') + 1);

      if (imgPath === 'add_emblem') {
        FilePath = configuration.folderName + '/emblem' + school_id + '.' + ext;
        emb_file_path = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath1') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image1_' + school_id + '.' + ext;
        imgpath1 = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath2') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image2_' + school_id + '.' + ext;
        imgpath2 = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath3') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image3_' + school_id + '.' + ext;
        imgpath3 = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath4') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image4_' + school_id + '.' + ext;
        imgpath4 = configuration.digitalOceanSpaces + FilePath;
      } else if (imgPath === 'imagepath5') {
        FilePath = configuration.folderName + '/' + configuration.subFolderName + '/image5_' + school_id + '.' + ext;
        imgpath5 = configuration.digitalOceanSpaces + FilePath;
      }

      const params = {
        Body: blob,
        Bucket: `${configuration.bucketName}`,
        Key: FilePath,
      };
      s3.putObject(params)
        .on('build', (request) => {
          request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
          request.httpRequest.headers['Content-Length'] = blob.size;
          request.httpRequest.headers['Content-Type'] = blob.type;
          request.httpRequest.headers['x-amz-acl'] = 'public-read';
          request.httpRequest.headers['Access-Control-Allow-Origin'] = '*';
        })
        .send((err) => {
          if (err) console.log(err, 'Img error');
          else {
            const imageUrl = `${configuration.digitalOceanSpaces}` + blob.name;
            console.log(imageUrl);
          }
        });
    }
  };
  const handleClickBack = () => {
    setNextPage(false);
  };
  const handleClickOpen = () => {
    setEditVal(false);
    setNextPage(true);
  };
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );
  const handleFormSave = async () => {
    var grpId = window.localStorage.getItem('grpInstId');
    const payload = {
      name: state.grpInstitute_name,
      short_name: state.shortName,
      code: state.display_code,
      description: state.display_info,
      emblem_file_path: emb_file_path,
      image_1_file_path: imgpath1,
      image_2_file_path: imgpath2,
      image_3_file_path: imgpath3,
      image_4_file_path: imgpath4,
      image_5_file_path: imgpath5,
      parent_hierarchy_path: state.hierarchy,
    };

    try {
      // PORT-GI-6: Save Group Insitution Basic Info

      const res = await putBasicInfo(grpId, payload);
      if (res.data.status === true) {
        setState({
          ...state,
          imagePreview: emb_file_path,
          imgPrev1: imgpath1,
          imgPrev2: imgpath2,
          imgPrev3: imgpath3,
          imgPrev4: imgpath4,
          imgPrev5: imgpath5,
        });
        onTableClick(window.localStorage.getItem('grpInstId'));
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 3000);
        setNextPage(true);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }

    // Axios.put(
    //   `${BASE_URL}/group_institutions/${grpId}`,
    //   {
    //     name: state.grpInstitute_name,
    //     short_name: state.shortName,
    //     code: state.display_code,
    //     description: state.display_info,
    //     emblem_file_path: emb_file_path,
    //     image_1_file_path: imgpath1,
    //     image_2_file_path: imgpath2,
    //     image_3_file_path: imgpath3,
    //     image_4_file_path: imgpath4,
    //     image_5_file_path: imgpath5,
    //     parent_hierarchy_path: state.hierarchy,
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("data edited Successfully!");
    //     if (res.data.status === true) {
    //       setState({
    //         ...state,
    //         imagePreview: emb_file_path,
    //         imgPrev1: imgpath1,
    //         imgPrev2: imgpath2,
    //         imgPrev3: imgpath3,
    //         imgPrev4: imgpath4,
    //         imgPrev5: imgpath5,
    //       });
    //       onTableClick(window.localStorage.getItem("grpInstId"));
    //       // setTimeout(() => {
    //       //   window.location.reload(false);
    //       // }, 3000);
    //       setNextPage(true);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //   });
  };

  const handleClosePopup = () => setErrorUpload(false);

  const ErrModal = () => {
    // let errHead = ["Subject Id", "Subject Name", "Error"];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>
                      {key + 1}. {elm.message}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  const NewScreen = () => {
    return (
      <Box component="div" className={styles.main_box}>
        <Box className={styles.top_box}>
          <div className={styles.backBtn}>
            <div className={styles.bck} onClick={handleClickBack}>
              <span className={styles.back}>
                <img src={require('../../../../assets/icons/back.png')} alt="back" />
              </span>
              {editVal ? '' : <span className={styles.study}>Create New Group Institution</span>}
            </div>
          </div>
          <div className={styles.top_box_body}>
            <div className={styles.top_box_format}>
              <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ paddingRight: '10px' }} />
              Download Institute
            </div>
            <div className={styles.top_box_data}>
              <img src={require('../../../../assets/icons/up.png')} alt="down" style={{ paddingRight: '10px' }} />
              Upload Institute
            </div>
          </div>
        </Box>
        <Box className={styles.form_box}>
          <Formik initialValues={state || initialValues} validationSchema={validationSchema} enableReinitialize>
            {(formik) => {
              return (
                <Box className={styles.form_container} component="div">
                  <Form className={styles.form_box}>
                    <Box className={styles.form_box_fields} component="div">
                      <Box component="div" className={styles.form_box_fields_l} id="get_embed">
                        <Field
                          type="file"
                          id="add_emblem"
                          name="add_emblem"
                          className={styles.add_Emblem}
                          onChange={(e) => handleChange(e, 'add_emblem')}
                          accept="image/x-png,image/jpeg,image/jpg"
                          multiple
                        />

                        <img
                          htmlFor="add_emblem"
                          src={state.imagePreview !== '' ? state.imagePreview : add_emb}
                          alt=""
                          style={{
                            width: '195px',
                            height: '195px',
                            borderRadius: '6px',
                          }}
                        />

                        <ErrorMessage name="add_emblem" component={TextError}>
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                      </Box>
                      <Box component="div" className={styles.form_box_fields_r}>
                        <label htmlFor="institute_name" className={styles.labels}>
                          Institute Name
                        </label>
                        <Box component="div" className={styles.institute_name}>
                          <Field
                            type="text"
                            id="grpInstitute_name"
                            name="grpInstitute_name"
                            className={styles.institute_name_f}
                            value={editVal ? state.grpInstitute_name : ''}
                            onChange={(e) =>
                              setState({
                                ...state,
                                grpInstitute_name: e.target.value,
                              })
                            }
                          />
                        </Box>
                        <div className={styles.shortContainer}>
                          <div>
                            <label htmlFor="InstituionShortName" className={styles.labels}>
                              Short Name
                            </label>
                            <div className={styles.displayInfoshort}>
                              <Field
                                type="text"
                                id="shortName"
                                name="shortName"
                                value={editVal ? state.shortName : ''}
                                className={styles.display_info_f}
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    shortName: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <label htmlFor="InstituionDisplayCode" className={styles.labels}>
                              Display Code
                            </label>
                            <div className={styles.displayInfoshort}>
                              <Field
                                type="text"
                                id="display_code"
                                name="display_code"
                                value={editVal ? state.display_code : ''}
                                className={styles.display_info_f}
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    display_code: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <label htmlFor="display_info" className={styles.labels}>
                          Display Info
                        </label>
                        <Box component="div" className={styles.display_info}>
                          <Field
                            type="text"
                            id="display_info"
                            name="display_info"
                            value={editVal ? state.display_info : ''}
                            className={styles.display_info_f}
                            onChange={(e) =>
                              setState({
                                ...state,
                                display_info: e.target.value,
                              })
                            }
                          />
                        </Box>
                        <label htmlFor="images" className={styles.labels}>
                          Images
                        </label>
                        <Box component="div" className={styles.images}>
                          <Box className={styles.imgs1} component="div">
                            <Field
                              type="file"
                              id="imagepath1"
                              name="imagepath1"
                              className={styles.add_Emblem}
                              onChange={(e) => handleChange(e, 'imagepath1')}
                              accept="image/x-png,image/jpeg,image/jpg"
                              multiple
                            />
                            <img
                              htmlFor="image_1"
                              src={state.imgPrev1 !== '' ? state.imgPrev1 : addImg}
                              alt=""
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '6px',
                              }}
                            />
                          </Box>
                          <Box className={styles.imgs2} component="div">
                            <Field
                              type="file"
                              id="imagepath2"
                              name="imagepath2"
                              className={styles.add_Emblem}
                              onChange={(e) => handleChange(e, 'imagepath2')}
                              accept="image/x-png,image/jpeg,image/jpg"
                              multiple
                            />
                            <img
                              htmlFor="image_1"
                              src={state.imgPrev2 !== '' ? state.imgPrev2 : addImg}
                              alt=""
                              className={styles.imags}
                            />
                          </Box>
                          <Box className={styles.imgs3} component="div">
                            <Field
                              type="file"
                              id="imagepath3"
                              name="imagepath3"
                              className={styles.add_Emblem}
                              onChange={(e) => handleChange(e, 'imagepath3')}
                              accept="image/x-png,image/jpeg,image/jpg"
                              multiple
                            />
                            <img
                              htmlFor="image_1"
                              src={state.imgPrev3 !== '' ? state.imgPrev3 : addImg}
                              alt=""
                              className={styles.imags}
                            />
                          </Box>
                          <Box className={styles.imgs4} component="div">
                            <Field
                              type="file"
                              id="imagepath4"
                              name="imagepath4"
                              className={styles.add_Emblem}
                              onChange={(e) => handleChange(e, 'imagepath4')}
                              accept="image/x-png,image/jpeg,image/jpg"
                              multiple
                            />
                            <img
                              htmlFor="image_1"
                              src={state.imgPrev4 !== '' ? state.imgPrev4 : addImg}
                              alt=""
                              className={styles.imags}
                            />
                          </Box>
                          <Box className={styles.imgs5} component="div">
                            <Field
                              type="file"
                              id="imagepath5"
                              name="imagepath5"
                              className={styles.add_Emblem}
                              onChange={(e) => handleChange(e, 'imagepath5')}
                              accept="image/x-png,image/jpeg,image/jpg"
                              multiple
                            />
                            <img
                              htmlFor="image_1"
                              src={state.imgPrev5 !== '' ? state.imgPrev5 : addImg}
                              alt=""
                              className={styles.imags}
                            />
                          </Box>
                        </Box>
                        <label htmlFor="display_info" className={styles.labels}>
                          Hierarchy
                        </label>
                        <Box component="div" className={styles.display_info}>
                          <Field
                            type="text"
                            id="hierarchy"
                            name="hierarchy"
                            value={editVal ? state.hierarchy : ''}
                            className={styles.display_info_f}
                            onChange={(e) => setState({ ...state, hierarchy: e.target.value })}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Form>
                  <Box component="div" className={styles.table_f}>
                    <div className={styles.file_container}>
                      <Box className={styles.file_box} component="div">
                        <div className={styles.file_text}>
                          <label>Child Groups</label>
                        </div>
                        <div className={styles.file_opt}>
                          {/* <Box
                            component="div"
                            onClick={(e) => downloadFile(e, "grpList")}
                            className={styles.file_download}
                          >
                            <img
                              src={require("../../../../assets/icons/down.png")}
                              alt="down"
                              style={{
                                marginRight: "10px",
                                width: "13px",
                                height: "16px",
                              }}
                            />
                            Download List
                          </Box> */}
                          {/* <Formik>
                            {(formik) => {
                              return (
                                <div>
                                  <Form>
                                    <Field
                                      type="file"
                                      id="childFiletoupload"
                                      name="filetoupload"
                                      // onChange={(e) => {
                                      //   uploadFile(e);
                                      //   e.target.value = null;
                                      // }}
                                      style={{ display: "none" }}
                                    />
                                    <Box
                                      component="div"
                                      className={styles.file_upload}
                                    >
                                      <label
                                        href="#"
                                        htmlFor="childFiletoupload"
                                        style={{
                                          borderRadius: "10px",
                                          width: "220px",
                                          height: "32px",
                                          textAlign: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img
                                          src={require("../../../../assets/icons/up.png")}
                                          alt="up"
                                          style={{
                                            marginRight: "10px",
                                            width: "13px",
                                            height: "16px",
                                          }}
                                        />
                                        Upload List
                                      </label>
                                    </Box>
                                  </Form>
                                </div>
                              );
                            }}
                          </Formik> */}
                        </div>
                      </Box>
                    </div>
                    <div className={styles.schoolList_Table + ' childGrp'}>
                      <div className={styles.schoolList_Table_head}>
                        {child_t_head.map((elm, key) => (
                          <div key={key} className={styles.schoolList_Table_head_data + ' ' + styles.child_t_head}>
                            {elm}
                          </div>
                        ))}
                      </div>
                      <div className={styles.schoolList_Table_body}>
                        {state.childGrp_data.map((elm, key) => (
                          <div key={key} className={styles.schoolList_Table_body_row}>
                            <div className={styles.schoolList_Table_body_row_td2 + ' ' + styles.child_t_body}>
                              {elm.group_instsitution_name}
                            </div>
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>
                              {elm.parent_hierarchy_path}
                            </div>
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>
                              {elm.institution_count}
                            </div>
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>
                              {elm.description}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Box>
                  <Box component="div" className={styles.table_f}>
                    <div className={styles.file_container}>
                      <Box className={styles.file_box} component="div">
                        <div className={styles.file_text}>
                          <label>Group Educator</label>
                        </div>
                        <div className={styles.file_opt}>
                          <Box component="div" onClick={(e) => downloadFile(e, 'eduDownload')} className={styles.file_download}>
                            <img
                              src={require('../../../../assets/icons/down.png')}
                              alt="down"
                              style={{
                                marginRight: '10px',
                                width: '13px',
                                height: '16px',
                              }}
                            />
                            Download List
                          </Box>
                          <Formik>
                            {(formik) => {
                              return (
                                <div>
                                  <Form>
                                    <Field
                                      type="file"
                                      id="eduFiletoupload"
                                      name="filetoupload"
                                      onChange={(e) => {
                                        uploadFile(e, 'eduUpload');
                                        e.target.value = null;
                                      }}
                                      style={{ display: 'none' }}
                                    />
                                    <Box component="div" className={styles.file_upload}>
                                      <label
                                        href="#"
                                        htmlFor="eduFiletoupload"
                                        style={{
                                          borderRadius: '10px',
                                          width: '220px',
                                          height: '32px',
                                          textAlign: 'center',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <img
                                          src={require('../../../../assets/icons/up.png')}
                                          alt="up"
                                          style={{
                                            marginRight: '10px',
                                            width: '13px',
                                            height: '16px',
                                          }}
                                        />
                                        Upload List
                                      </label>
                                    </Box>
                                  </Form>
                                </div>
                              );
                            }}
                          </Formik>
                        </div>
                      </Box>
                    </div>
                    <div className={styles.schoolList_Table + ' childGrp'}>
                      <div className={styles.schoolList_Table_head}>
                        {educator_t_head.map((elm, key) => (
                          <div key={key} className={styles.schoolList_Table_head_data + ' ' + styles.child_t_head}>
                            {elm}
                          </div>
                        ))}
                      </div>
                      <div className={styles.schoolList_Table_body}>
                        {state.childGrpEdu_data.map((elm, key) => (
                          <div key={key} className={styles.schoolList_Table_body_row}>
                            <div className={styles.schoolList_Table_body_row_td2 + ' ' + styles.child_t_body}>
                              {elm.group_educator_name}
                            </div>
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>{elm.email}</div>
                            {/* <div
                              className={styles.schoolList_Table_body_row_td3 + " " + styles.child_t_body}
                            >
                              {"-"}
                            </div> */}
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>
                              {elm.course_count}
                            </div>
                            {/* <div
                              className={styles.schoolList_Table_body_row_td3 + " " + styles.child_t_body}
                            >
                              {"-"}
                            </div> */}
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>
                              {elm.subject_count}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Box>
                  <Box component="div" className={styles.table_f}>
                    <div className={styles.file_container}>
                      <Box className={styles.file_box} component="div">
                        <div className={styles.file_text}>
                          <label>Institutions ({state.childGrpInst_data.length})</label>
                        </div>
                        <div className={styles.file_opt}>
                          <Box component="div" onClick={(e) => downloadFile(e, 'instDownload')} className={styles.file_download}>
                            <img
                              src={require('../../../../assets/icons/down.png')}
                              alt="down"
                              style={{
                                marginRight: '10px',
                                width: '13px',
                                height: '16px',
                              }}
                            />
                            Download List
                          </Box>
                          <Formik>
                            {(formik) => {
                              return (
                                <div>
                                  <Form>
                                    <Field
                                      type="file"
                                      id="instFiletoupload"
                                      name="filetoupload"
                                      onChange={(e) => {
                                        uploadFile(e, 'InstUpload');
                                        e.target.value = null;
                                      }}
                                      style={{ display: 'none' }}
                                    />
                                    <Box component="div" className={styles.file_upload}>
                                      <label
                                        href="#"
                                        htmlFor="instFiletoupload"
                                        style={{
                                          borderRadius: '10px',
                                          width: '220px',
                                          height: '32px',
                                          textAlign: 'center',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <img
                                          src={require('../../../../assets/icons/up.png')}
                                          alt="up"
                                          style={{
                                            marginRight: '10px',
                                            width: '13px',
                                            height: '16px',
                                          }}
                                        />
                                        Upload List
                                      </label>
                                    </Box>
                                  </Form>
                                </div>
                              );
                            }}
                          </Formik>
                        </div>
                      </Box>
                    </div>
                    <div className={styles.schoolList_Table + ' childGrp'}>
                      <div className={styles.schoolList_Table_head}>
                        {inst_t_head.map((elm, key) => (
                          <div key={key} className={styles.schoolList_Table_head_data + ' ' + styles.child_t_head}>
                            {elm}
                          </div>
                        ))}
                      </div>
                      <div className={styles.schoolList_Table_body}>
                        {state.childGrpInst_data.map((elm, key) => (
                          <div key={key} className={styles.schoolList_Table_body_row}>
                            <div className={styles.schoolList_Table_body_row_td2 + ' ' + styles.child_t_body}>
                              {elm.instsitution_short_name}
                            </div>
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>
                              {elm.display_code}
                            </div>
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>
                              {elm.ref_parent_group}
                            </div>
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>
                              {elm.parent_hierarchy_path}
                            </div>
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>{elm.place}</div>
                            <div className={styles.schoolList_Table_body_row_td3 + ' ' + styles.child_t_body}>{elm.pincode}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Box>
                  <Box className={styles.buttons}>
                    <div className={styles.buttons_div}>
                      <ButtonComponent
                        className={styles.btn_cancel}
                        onClick={() => {
                          handleClickBack();
                        }}
                      >
                        Cancel
                      </ButtonComponent>
                      <ButtonComponent className={styles.btn_save} onClick={handleFormSave}>
                        Save
                      </ButtonComponent>
                    </div>
                  </Box>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </Box>
    );
  };

  return (
    <div>
      {nextPage === false ? (
        <div className={styles.container}>
          <Box className={styles.topBox}>
            <Box component="div" className={styles.left}>
              <Box
                component="div"
                className={styles.search_box}
                onChange={(e) => {
                  changedBoard(e, 'selectedsearch');
                }}
              >
                <div>
                  <InputBase placeholder="Search" className={styles.searchBoxIn} inputProps={{ 'aria-label': 'search' }} />
                </div>
              </Box>
            </Box>
            <Box component="div" className={styles.right}>
              <Box className={styles.add_box} component="div" onClick={handleClickOpen}>
                Create Group Institution
              </Box>
              <Box component="div" className={styles.download_list} onClick={(e) => downloadFile(e, 'grpList')}>
                <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ marginRight: '10px' }} />
                Download Institution List
              </Box>
              <Box component="div" className={styles.upload_list}>
                <Formik>
                  {(formik) => {
                    return (
                      <div className={styles.upload}>
                        <Form>
                          <Field
                            type="file"
                            id="grpfiletoupload"
                            name="filetoupload"
                            // multiple
                            // accept=".*"
                            onChange={(e) => {
                              uploadFile(e, 'grpList');
                              e.target.value = null;
                            }}
                            style={{ display: 'none' }}
                          />
                          <label
                            htmlFor="grpfiletoupload"
                            style={{
                              borderRadius: '10px',
                              width: '165px',
                              height: '32px',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              padding: '10px',
                            }}
                          >
                            <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ marginRight: '10px' }} />
                            Upload Institution List
                          </label>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Box>
          <Box component="div" className={styles.tableBox}>
            <div className={styles.table_box}>
              {isLoading === true ? (
                <Example />
              ) : (
                <div className={styles.schoolList_Table}>
                  <div className={styles.schoolList_Table_head}>
                    <div className={styles.schoolList_Table_head_data_inst}>Group Institution</div>
                    <div className={styles.schoolList_Table_head_data}>Child Count</div>
                    <div className={styles.schoolList_Table_head_data_hie}>Hierarchy</div>
                    <div className={styles.schoolList_Table_head_data_ins_cou}>Institution Count</div>
                    <div className={styles.schoolList_Table_head_data_inst}>Description</div>
                  </div>
                  {
                    <div className={styles.schoolList_Table_body}>
                      {state.t_body.length === 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          No Data Found
                          <span role="img" aria-label="jsx-a11y/accessible-emoji">
                            😔
                          </span>
                        </div>
                      ) : (
                        state.t_body.map((elm, key) => (
                          <div
                            key={key}
                            className={styles.schoolList_Table_body_row}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              onTableClick(elm.group_institution_id);
                            }}
                          >
                            <div className={styles.schoolList_Table_body_row_td2}>{elm.group_institution_name}</div>
                            {/* <div className={styles.schoolList_Table_body_row_td3}>
                              {""}
                            </div> */}
                            <div className={styles.schoolList_Table_body_row_td3}>{elm.child_count}</div>
                            <div
                              className={styles.schoolList_Table_body_row_td4}
                              // style={{ width: "14%" }}
                            >
                              {elm.parent_hierarchy_path}
                            </div>
                            <div className={styles.schoolList_Table_body_row_td3}>{elm.institution_count}</div>
                            <div className={styles.schoolList_Table_body_row_td2}>{elm.description}</div>
                          </div>
                        ))
                      )}
                    </div>
                  }
                </div>
              )}
            </div>
          </Box>
          {/* <Box className={styles.pages+" "+"pagination"} component="div">
            {default_page === 1 ? <div className={styles.first+" "+styles.inActive}>First</div> :
            <div className={styles.first} onClick={(e) =>pagechange(e,1)}>First</div>}
            <div
              // className="col-md-10"
              // style={{
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
              //   marginLeft: "50px",
              // }}
            >
              <Pagination
                page={default_page}
                count={state.pagecount}
                onChange={pagechange}
              />
            </div>
            {default_page === state.pagecount ? <div className={styles.last+" "+styles.inActive}>Last</div> :
            <div className={styles.last} onClick={(e) =>pagechange(e,state.pagecount)}>Last</div>}
            <div className={styles.showRows}>
              <Box
                component="select"
                className={styles.options}
                onChange={(e) => {
                  changedBoard(e, "showrow");
                }}
              >
                
                <option value="10">{"Show 10"}</option>
                <option value="20">{"Show 20"}</option>
              </Box>
            </div>
          </Box> */}
        </div>
      ) : (
        NewScreen()
      )}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default GroupInstitution;
