import https from './init/https';

// PORT-GE-1: Excel Export of Group Educators
export const getGroupEducators = async () => {
  const response = await https.get(`/group_educators/excel-export`, { responseType: 'arraybuffer' });
  return response;
};

// PORT-GE-2: Excel Import of Group Educators
export const uploadGroupEducators = async (data) => {
  const response = await https.post(`/group_educators/excel-import`, data);
  return response;
};

// PORT-GE-3: Retrieve the List of Group Educators
export const getGroupEducatorList = async () => {
  const response = await https.get(`/group_educators/search`);
  return response;
};

// PORT-GE-4: Retrieve the list of Group Institutions for the selected Group Educator
export const getGroupInstitutions = async (id) => {
  const response = await https.get(`/group_educators/${id}/group_institutions`);
  return response;
};

// PORT-GE-5: Retrieve Basic Info of the Selected Group Educator and Group Institution Combination
export const getSelectedGroupEducator = async (group_educator_id, group_institution_id) => {
  const response = await https.get(`/group_educators/${group_educator_id}/group_institutions/${group_institution_id}/basic_info`);
  return response;
};

// PORT-GE-6: Excel Export - Group Educator Subjects
export const getGroupEducatorSubjects = async (group_educator_id) => {
  const response = await https.get(`/group_educators/${group_educator_id}/subjects/excel_export`, {
    responseType: 'arraybuffer',
  });
  return response;
};

// PORT-GE-7: Excel Import - Group Educator Subjects
export const uploadGroupEducatorSubjects = async (group_educator_id, data) => {
  const response = await https.post(`/group_educators/${group_educator_id}/subjects/excel_import`, data);
  return response;
};

// PORT-GE-8: Display Group Educator Subject List
export const getGroupEducatorSubjectList = async (group_educator_id) => {
  const response = await https.get(`/group_educators/${group_educator_id}/subjects/`);
  return response;
};

// PORT-GE-9: Retrieve the Group study circle allocations of a Group educator
export const getGroupStudyCircleAllocations = async (group_educator_id) => {
  const response = await https.get(`/group_educators/${group_educator_id}/group_study_circles`);
  return response;
};

//--------------------------------------------------------------------------------------------------
