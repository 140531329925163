import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Divider, Box } from '@material-ui/core';
import * as Yup from 'yup';
import styles from './NewPasswordStyle.module.css';
import TextError from '../../variables/errors/Text_error';
import ReactLoading from 'react-loading';
import { checkTokenValidity, setPassword } from '../../api/login';

// debugger;
const initialValues = {
  NewPassword: '',
  passwordConfirm: '',
};

const validationSchema = Yup.object({
  NewPassword: Yup.string().required('New password is Required').min(5, 'password too short'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('NewPassword'), null], 'Password must match')
    .required('Password confirm is required'),
});

const NewPassword = (props) => {
  let user_id = props.match.params.user_id;
  const [pass, setPass] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRes = async () => {
      try {
        // PORT-LOGIN-2: Check validity of the emailed token
        const results = await checkTokenValidity(user_id);

        if (results.data.status === false) {
          setTimeout(() => {
            return props.history.push('/');
          }, 3000);
        } else {
          // console.log(results);
        }
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
          setTimeout(() => {
            return props.history.push('/');
          }, 3000);
        }
      }
    };
    fetchRes();
  }, [props.history, user_id]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const onSubmit = async (values) => {
    setLoading(true);
    // PORT-LOGIN-3: Set password
    try {
      await setPassword(user_id, values.NewPassword);

      toast.success('New Password Created Successfully');
      setLoading(false);
      setTimeout(() => {
        // return (window.location = "/");
        setPass(false);
      }, 3000);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setLoading(false);
      setTimeout(() => {
        return (window.location = '/');
      }, 3000);
    }
  };

  function showPassword() {
    var temp = document.getElementById('passwordConfirm');
    if (temp.type === 'password') {
      temp.type = 'text';
    } else {
      temp.type = 'password';
    }
  }
  return (
    <div className={styles.container}>
      {loading ? <ReactLoading type="spin" color="#4fb64b" className={styles.startUploadLoadSpin} /> : null}
      <Box className={styles.header} p={1}>
        <div className={styles.header_body}>
          <img src={require('../../assets/images/stucle.png')} alt="header-logo" className={styles.image} />
        </div>
      </Box>
      <Divider style={{ background: '#A6C8A6', height: '1px', width: '100%' }} />
      <main className={styles.body}>
        <div className={styles.left}>
          <Box component="div" className={styles.reset_box} mb={4}>
            <Box component="p" className={styles.reset} mb={0}>
              New Password
            </Box>
            <p className={styles.txt}>To change your current Password, type the new password, and then retype it. Click Save.</p>
            <p className={styles.txt1}>
              We recommend you to have long password with a mix of capital and lowercase letters, numbers, and symbols.
            </p>
            {pass ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                className={styles.formik}
              >
                {(formik) => {
                  return (
                    <div className={styles.form_div}>
                      <Form className={styles.form}>
                        <Box className={styles.password} component="div">
                          <Field
                            type="password"
                            id="NewPassword"
                            name="NewPassword"
                            placeholder="Password"
                            className={styles.password_field}
                          />
                        </Box>
                        <ErrorMessage name="NewPassword" component={TextError}>
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                        <Box className={styles.password} component="div">
                          <Field
                            type="password"
                            id="passwordConfirm"
                            name="passwordConfirm"
                            placeholder="Re-enter Password"
                            className={styles.password_field}
                          />
                          <Box component="span" className={styles.show_password} onClick={() => showPassword()}>
                            show password
                          </Box>
                        </Box>
                        <ErrorMessage name="passwordConfirm" component={TextError}>
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                        <button type="submit" className={styles.Button} disabled={!formik.isValid || formik.isSubmitting}>
                          <div>Save</div>
                        </button>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <div className={styles.form_div}>
                <div className={styles.txt2}>Your new password has been saved.</div>
                <Link to="/">
                  <div className={styles.login_redirect}>
                    Login
                    <img src={require('../../assets/images/rightArrow.png')} alt="right-arrow" />
                  </div>
                </Link>
              </div>
            )}
          </Box>
        </div>
        <div className={styles.right}></div>
      </main>
      <footer className={styles.footer}>
        &copy; {new Date().getFullYear()} Apps'n'Devices Technologies Pvt Ltd. All rights reserved.
      </footer>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </div>
  );
};

export default NewPassword;
