import https from './init/https';

// PORT-STU-1: Retrieve the list of Institution Defined Groups
export const getInstitutionDefinedGroup = async (school_id) => {
  return await https.get(`/institutions/${school_id}/students/inst_defined_groups`);
};
// PORT-STU-2: Download Student list as excel
export const downloadStudentListExcel = async (institution_id, inst_defined_group) => {
  return await https.get(`/institutions/${institution_id}/students/${inst_defined_group}/excel-export`, {
    responseType: 'arraybuffer',
  });
};

// PORT-STU-3: Add / update / delete students via excel
export const uploadStudentsToSIS = async (institutionId, data) => {
  return await https.post(`/institutions/${institutionId}/students/excel-import`, data);
};

// PORT-STU-4: Retrieve the count of students given the search conditions
export const getStudentCountBySearchConditions = async (school_id, IDG_id, searchKey) => {
  return await https.get(`/institutions/${school_id}/students/${IDG_id}/search/record-count?search_text=${searchKey}`);
};

// PORT-STU-5: Display the student list in a tabular format (with search and pagination) Copy
export const getStudentRecords = async (institution_id, inst_defined_group, searchKey, limit, offset) => {
  return await https.get(
    `/institutions/${institution_id}/students/${inst_defined_group}/search?search_text=${searchKey}&limit=${limit}&offset=${offset}`,
  );
};

// PORT-STU-6: Add Profile picture for students
export const addProfilePictureForStudent = async (school_id, studentId, data) => {
  return await https.put(`/institutions/${school_id}/students/${studentId}`, data);
};

// PORT-STU-7: Add Big and Small Profile Picture of students
export const setBigSmallPicturesForStudents = async (student_id, data) => {
  return await https.put(`/students/${student_id}/`, data);
};
// PORT-STU-8: Bulk upload student profile pictures
export const bulkUploadImagesForStudents = async (formdata) => {
  return await https.post(`/students/bulkupload`, formdata);
};
