import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import styles from './CourseStyle.module.css';
// import * as Yup from "yup";
import { saveAs } from 'file-saver';
import { toast, ToastContainer } from 'react-toastify';
import { Box } from '@material-ui/core';
import { ModalComponent, Dialogbox } from '../../../../components/index';
import ReactLoading from 'react-loading';
import {
  getCoursesByInstitutionIdExcel,
  uploadCourseToSchool,
  getAllCoursesOfAInstitution,
} from '../../../../api/institutionCourses';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';

const initialValues = {
  board: '',
  hierarchy1: '',
  hierarchy2: '',
  hierarchy3: '',
  hierarchy4: '',
  hierarchy5: '',
  code: '',
  displayName: '',
  incharge: '',
  desc: '',
};
var course_t_head = ['Course', 'Board', 'Hierarchy', 'Subjects', 'Stucle Course (Code)', 'Course incharge', 'Description'];
var board_id = '';

const Course = () => {
  const [errorUpload, setErrorUpload] = useState(false);
  const [msg, setMsg] = useState('');
  const [errMsg, setErrMsg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    showHide: false,
    optBD: [],
    boardId: '-1',
    courseList: [],
  });

  const school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 1);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  useEffect(() => {
    fetchAPI();
    // eslint-disable-next-line
  }, []);

  const fetchAPI = async () => {
    setIsLoading(true);
    try {
      // PORT-IB-5: Retrieve list of boards of an institution

      const getBoard = await getBoardsByNameInInstitution(school_id);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards`,
      //   Auth
      // );

      // console.log(getBoard)
      // PORT-IC-3: Retrieve detailed info of the courses of an institution
      const getCourseList = await getAllCoursesOfAInstitution(school_id, state.boardId);

      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/detailed-info`,
      //   Auth
      // );

      setState((prevState) => ({
        ...prevState,
        optBD: getBoard.data.data,
        courseList: getCourseList.data.data,
      }));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err.response, 'error');
    }
  };

  const handleClose = (value) => {
    setState((prevState) => ({
      ...prevState,
      showHide: false,
    }));
  };
  const handleClosePopup = () => setErrorUpload(false);

  // const handleClickOpen = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     showHide: true
  //   }));
  // };

  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  const changedBoard = async (e) => {
    setIsLoading(true);
    board_id = e.target.value;
    // console.log(board_id)
    try {
      // PORT-IC-3: Retrieve detailed info of the courses of an institution
      const getCourseList = await getAllCoursesOfAInstitution(school_id, state.boardId);
      // const getCourseList = await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/detailed-info`,
      //   Auth
      // );

      setState((prevState) => ({
        ...prevState,
        boardId: board_id,
        courseList: getCourseList.data.data,
      }));
      setIsLoading(false);
    } catch (err) {
      console.log(err.response, 'error');
    }
  };

  // console.log(state.boardId)
  // console.log(state.courseList)

  const downloadFile = async () => {
    // if(state.boardId === "-1"){
    //   toast.error('Please select board')
    // } else {

    // PORT-IC-1: download courses of an institution via excel export
    try {
      const response = await getCoursesByInstitutionIdExcel(school_id, state.boardId, Auth);
      let filename = response.headers['content-disposition'].split('=')[1];
      var blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      saveAs(blob, filename);
      toast.success('file downloaded');
    } catch (err) {
      console.log(err.response, 'error');
      toast.error('No records');
    }
    // await Axios.get(
    //   `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/excel-export`,
    //   {
    //     ...Auth,
    //     responseType: "arraybuffer",
    //   }
    // )

    // console.log(response);

    // }
  };
  const uploadFile = async (e) => {
    // if(state.boardId === "-1"){
    //   toast.error('Please select board')
    // } else {
    let formdata = new FormData();
    let file = e.target.files[0];
    setIsLoading(true);

    formdata.append('filetoupload', file);
    // PORT-IC-2: Add, update, delete courses to an institution via excel import
    try {
      await uploadCourseToSchool(school_id, formdata);
      setIsLoading(false);
      toast.success('File Uploaded Successfully');
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (err) {
      // Axios({
      //   url: `${BASE_URL}/institutions/${school_id}/courses/excel-import`,
      //   mode: "no-cors",
      //   method: "POST",
      //   headers: {
      //     ...Auth.headers,
      //     "Content-Type": "multipart/form-data",
      //     Accept: "*",
      //     type: "formData",
      //     "Access-Control-Allow-Headers": "*",
      //   },
      //   data: formdata,
      // })

      // console.log(err.response.data.data);
      setIsLoading(false);
      if (err) {
        setErrorUpload(true);
        toast.error(err.response.data.message);
        setMsg(err.response.data.message);
        setErrMsg(err.response.data.data);
      }
    }
    // }
  };

  const ErrModal = () => {
    return (
      <Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <div className={styles.content_table}>
            {errMsg.map((elm, key) => {
              return (
                <div key={key}>
                  <div>
                    {key + 1}. {elm.message}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Dialogbox>
    );
  };

  return (
    <div>
      <Box className={styles.container}>
        <Box className={styles.top_box} component="div">
          <Box component="div" className={styles.top_box_l}>
            <div>
              <label>Board</label>
              <Box
                component="select"
                className={styles.options}
                onChange={(e) => {
                  changedBoard(e);
                }}
              >
                <option value="-1">{'Board'}</option>
                {state.optBD.map((el, key) => (
                  <option value={el.board_id} key={key}>
                    {el.display_name}
                  </option>
                ))}
              </Box>
            </div>
          </Box>
          <Box className={styles.top_box}>
            {/* <Box
              className={styles.add_box}
              component="div"
              onClick={handleClickOpen}
             >
              Add Course
            </Box> */}
            <div className={styles.top_box_body}>
              <div className={styles.top_box_format} onClick={downloadFile}>
                <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ paddingRight: '10px' }} />
                Download Course
              </div>
              <Box component="div" className={styles.top_box_data}>
                <Formik>
                  {(formik) => {
                    return (
                      <div>
                        <Form>
                          <Field
                            type="file"
                            id="filetoupload"
                            name="filetoupload"
                            onChange={(e) => {
                              uploadFile(e);
                              e.target.value = null;
                            }}
                            style={{ display: 'none' }}
                          />
                          <label
                            htmlFor="filetoupload"
                            style={{
                              width: '120px',
                              height: '32px',
                              marginBottom: '0px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignContent: 'center',
                              fontWeight: 'bold',
                              fontSize: '10px',
                              color: 'rgb(155, 139, 139)',
                              cursor: 'pointer',
                              textAlign: 'center',
                              padding: '22px',
                            }}
                          >
                            <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ paddingRight: '10px' }} />
                            Upload Course
                          </label>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </Box>
              {/* <div className={styles.top_box_data}>
                <img
                  src={require("../../../../assets/icons/up.png")}
                  alt="down"
                  style={{ paddingRight: "10px" }}
                />
                Upload Course
              </div> */}
            </div>
          </Box>
        </Box>
        <Box>
          <div className={styles.table_box_list}>
            {isLoading === true ? (
              <Example />
            ) : (
              <div className={styles.table_box}>
                {isLoading === true ? (
                  <Example />
                ) : (
                  <div className={styles.courseList_Table}>
                    <div className={styles.courseList_Table_head}>
                      {course_t_head.map((elm, key) => (
                        <div key={key} className={styles.courseList_Table_head_data}>
                          {elm}
                        </div>
                      ))}
                    </div>
                    {
                      <div className={styles.courseList_Table_body}>
                        {state.courseList.length === 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            No Data Found
                            <span role="img" aria-label="jsx-a11y/accessible-emoji">
                              😔
                            </span>
                          </div>
                        ) : (
                          state.courseList.map((el, key) => {
                            return (
                              <div key={key} className={styles.courseList_Table_body_row} style={{ cursor: 'pointer' }}>
                                <div className={styles.courseList_Table_body_row_td}>{el.display_name}</div>
                                <div className={styles.courseList_Table_body_row_td}>{el.board_display_name}</div>
                                <div className={styles.courseList_Table_body_row_td}>{el.hierarchy_concat}</div>
                                {/* <div className={styles.courseList_Table_body_row_td}>{el.hierarchy_2_value}</div>
                                        <div className={styles.courseList_Table_body_row_td}>{el.hierarchy_3_value}</div>
                                        <div className={styles.courseList_Table_body_row_td}>{"-"}</div>
                                        <div className={styles.courseList_Table_body_row_td}>{"-"}</div> */}
                                <div className={styles.courseList_Table_body_row_td}>{el.subjects_count}</div>
                                <div className={styles.courseList_Table_body_row_td} style={{ paddingRight: '15px' }}>
                                  {el.course_code}
                                </div>
                                <div className={styles.courseList_Table_body_row_td} style={{ paddingRight: '15px' }}>
                                  {el.incharge_educator_name}
                                </div>
                                <div
                                  className={styles.courseList_Table_body_row_td}
                                  style={{
                                    paddingRight: '20px',
                                    paddingLeft: '0px',
                                  }}
                                >
                                  {el.description}
                                </div>
                                {/* <Icon className={styles.deleteBtn}>
                                            <img
                                            src={require("../../../../assets/icons/DeleteBox.png")}
                                            alt="deletebox"
                                            style={{ cursor: "pointer" }}
                                            //   onClick={(e) => handleDelete(e)}
                                            />
                                        </Icon> */}
                              </div>
                            );
                          })
                        )}
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </Box>
        <ModalComponent show={state.showHide} onClick={handleClose} className="courseModal">
          <Box className={styles.main_box} component="div">
            <Formik
              initialValues={initialValues}
              // validationSchema
              onSubmit
              enableReinitialize
            >
              {(formik) => {
                return (
                  <Box component="div" className={styles.coursePopUp}>
                    <Form>
                      <div className={styles.modal_Form}>
                        <div className={styles.courseModal_left}>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Board</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div">
                            <Field as="select" name="board" className={styles.d_options}>
                              <option>{'Select'}</option>
                            </Field>
                          </Box>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Hierarchy1</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div">
                            <Field as="select" name="hierarchy1" className={styles.d_options}>
                              <option>{'Select'}</option>
                            </Field>
                          </Box>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Hierarchy2</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div">
                            <Field as="select" name="hierarchy2" className={styles.d_options}>
                              <option>{'Select'}</option>
                            </Field>
                          </Box>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Hierarchy3</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div">
                            <Field as="select" name="hierarchy3" className={styles.d_options}>
                              <option>{'Select'}</option>
                            </Field>
                          </Box>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Hierarchy4</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div">
                            <Field as="select" name="hierarchy4" className={styles.d_options}>
                              <option>{'Select'}</option>
                            </Field>
                          </Box>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Hierarchy5</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div">
                            <Field as="select" name="hierarchy5" className={styles.d_options}>
                              <option>{'Select'}</option>
                            </Field>
                          </Box>
                        </div>
                        <div className={styles.courseModal_right}>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Stucle Course (Code)</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div">
                            <Field as="select" name="code" className={styles.d_options}>
                              <option>{'Select'}</option>
                            </Field>
                          </Box>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Course dispaly Name</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div">
                            <Field name="displayName" type="text" className={styles.B_Field} id="displayName" />
                          </Box>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Course incharge</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div">
                            <Field name="incharge" type="text" className={styles.B_Field} id="incharge" />
                          </Box>
                          <Box component="div" className={styles.courseLable}>
                            <div className={styles.modal_Text}>Description</div>
                          </Box>
                          <Box className={styles.main_box_1} component="div" style={{ height: '80px' }}>
                            <Field name="desc" component="textarea" className={styles.B_Field} id="desc" />
                          </Box>
                        </div>
                      </div>
                      <Box className={styles.buttonss}>
                        <button className={styles.cancelBtn} onClick={handleClose} type="reset">
                          Cancel
                        </button>
                        <button className={styles.saveBtn} type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                          Save
                        </button>
                      </Box>
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          </Box>
        </ModalComponent>
      </Box>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default Course;
