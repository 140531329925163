import React, { useState, useEffect } from 'react';
import { Icon, Box, InputBase } from '@material-ui/core';
// import { Pagination } from "@material-ui/lab";
import { toast, ToastContainer } from 'react-toastify';
// import { fade, makeStyles } from "@material-ui/core/styles";
import { Dialogbox } from '../../../../components/index';
import { saveAs } from 'file-saver';
import { Formik, Form, Field } from 'formik';
// import Axios from 'axios';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';
import styles from './InstitutionStyle.module.css';
// import { BASE_URL } from '../../../../config/Api';
import { ModalComponent } from '../../../../components/index';
import { getInstitutionList, exportToExcel, institutionsFileUpload } from '../../../../api/institutionList';

// var pageNum = 1;
// var rows = 10;
// var default_page = 1;
var searchString;

const initialValues = {
  short_name: '',
  display_code: '',
  ref_parent: '',
  place: '',
  country_name: '',
  pincode: '',
};

const validationBox = Yup.object({
  short_name: Yup.string(),
  display_code: Yup.string(),
  ref_parent: Yup.string(),
  place: Yup.string(),
  country_name: Yup.string(),
  pincode: Yup.string(),
});

// let pageNo = 10;

const Institution = () => {
  const [editVal, setEditVal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [msg, setMsg] = useState('');
  const [errorUpload, setErrorUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    t_body: [],
    short_name: '',
    display_code: '',
    ref_parent: '',
    place: '',
    country_name: '',
    pincode: '',
  });

  // const school_id = window.localStorage.getItem("school_id");
  window.localStorage.setItem('groupTabIndex', 0);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  console.log(Auth, loading);
  const fetchResults = async () => {
    setIsLoading(true);
    try {
      const getInstitution = await getInstitutionList('');
      // await Axios.get(
      //   `${BASE_URL}/institutions/search?search_text=`,
      //   Auth
      // );
      // let extraPage = TableList.count % pageNo === 0 ? 0 : 1;
      setIsLoading(false);
      setState((prevState) => ({
        ...prevState,
        t_body: getInstitution.data.data,
        // pagecount: Math.floor(TableList.count / pageNo + extraPage),
      }));
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changedBoard = async (e, selectedvalue) => {
    if (selectedvalue === 'selectedsearch') {
      searchString = e.target.value;
      // pageNum = 0;
      // default_page = 1;
      if (searchString === '') {
        // searchString = "";
        // pageNum = 0;
        // default_page = 1;
      }
    }
    try {
      const getInstitution = await getInstitutionList(searchString);
      // await Axios.get(
      //   `${BASE_URL}/institutions/search?search_text=${searchString}`,
      //   Auth
      // );

      // let extraPage = getTableData.count % rows === 0 ? 0 : 1;
      setState((prevState) => ({
        ...prevState,
        t_body: getInstitution.data.data,
        // pagecount: Math.floor(getTableData.count / rows + extraPage),
      }));
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };
  // const pagechange = (e, value) => {
  //   // console.log(value)
  //   default_page = value;
  //   changedBoard(value, "pagenumber");
  // };

  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };

  const handleSave = (values, submitProps, formik) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    // console.log(values)
  };

  const handleEdit = async (short_name, display_code, ref_parent, place, country_name, pincode) => {
    setEditVal(true);
    setState({
      ...state,
      short_name: short_name,
      display_code: display_code,
      ref_parent: ref_parent,
      place: place,
      country_name: country_name,
      pincode: pincode,
      showHide: true,
    });
  };

  const downloadFile = async () => {
    try {
      const response = await exportToExcel('');
      let filename = response.headers['content-disposition'].split('=')[1];
      var blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      saveAs(blob, filename);
      toast.success('file downloaded');

      // await Axios.get(`${BASE_URL}/institutions/excel-export?search_text=`, {
      //   ...Auth,
      //   responseType: "arraybuffer",
      // }).then((response) => {
      //   // console.log(response)
      //   let filename = response.headers["content-disposition"].split("=")[1];
      //   var blob = new Blob([response.data], {
      //     type: "application/octet-stream",
      //   });
      //   saveAs(blob, filename);
      //   toast.success("file downloaded");
      // });
      // }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      toast.error('No records');
    }
  };

  const uploadFile = async (e) => {
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);

    formdata.append('filetoupload', file);
    try {
      await institutionsFileUpload(formdata);
      setLoading(false);
      toast.success('File Uploaded Successfully');
      fetchResults();
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      if (err) {
        setErrorUpload(true);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
        setMsg(err.response.data.message);
        setErrMsg(err.response.data.data);
      }
    }

    // Axios({
    //   url: `${BASE_URL}/institutions/excel-import`,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //     "Content-Type": "multipart/form-data",
    //     Accept: "*",
    //     type: "formData",
    //     "Access-Control-Allow-Headers": "*",
    //   },
    //   data: formdata,
    // })
    //   .then((res) => {
    //     // console.log(res)
    //     setLoading(false);
    //     toast.success("File Uploaded Successfully");
    //     fetchResults();
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //     setLoading(false);
    //     if (err) {
    //       setErrorUpload(true);
    //       if (err.response && err.response.data) {
    //         console.log(err.response.data.message);
    //         toast.error(err.response.data.message);
    //       }
    //       setMsg(err.response.data.message);
    //       setErrMsg(err.response.data.data);
    //     }
    //   });
    // }
  };
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  const handleClosePopup = () => setErrorUpload(false);

  const ErrModal = () => {
    // let errHead = ["Subject Id", "Subject Name", "Error"];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>
                      {key + 1}. {elm.message}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  return (
    <div className={styles.container}>
      <Box className={styles.topBox}>
        <Box component="div" className={styles.left}>
          <Box
            component="div"
            className={styles.search_box}
            onChange={(e) => {
              changedBoard(e, 'selectedsearch');
            }}
          >
            <div>
              <InputBase placeholder="Search" className={styles.searchBoxIn} inputProps={{ 'aria-label': 'search' }} />
            </div>
          </Box>
        </Box>
        <Box component="div" className={styles.right}>
          <Box component="div" className={styles.download_list} onClick={downloadFile}>
            <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ marginRight: '10px' }} />
            Download Institution List
          </Box>
          <Box component="div" className={styles.upload_list}>
            <Formik>
              {(formik) => {
                return (
                  <div className={styles.upload}>
                    <Form>
                      <Field
                        type="file"
                        id="filetoupload"
                        name="filetoupload"
                        // multiple
                        // accept=".*"
                        onChange={(e) => {
                          uploadFile(e);
                          e.target.value = null;
                        }}
                        style={{ display: 'none' }}
                      />
                      <label
                        htmlFor="filetoupload"
                        style={{
                          borderRadius: '10px',
                          width: '165px',
                          height: '32px',
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          padding: '10px',
                        }}
                      >
                        <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ marginRight: '10px' }} />
                        Upload Institution List
                      </label>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
      <Box component="div" className={styles.tableBox}>
        <div className={styles.table_box}>
          {isLoading === true ? (
            <Example />
          ) : (
            <div className={styles.schoolList_Table}>
              <div className={styles.schoolList_Table_head}>
                <div className={styles.schoolList_Table_head_data_inst}>Institute short name</div>
                <div className={styles.schoolList_Table_head_data}>Display code</div>
                <div className={styles.schoolList_Table_head_data_ref}>Ref Parent</div>
                <div className={styles.schoolList_Table_head_data}>Place</div>
                <div className={styles.schoolList_Table_head_data}>Country</div>
                <div className={styles.schoolList_Table_head_data}>Pincode</div>
                <div className={styles.schoolList_Table_head_data_edit}> </div>
              </div>
              {
                <div className={styles.schoolList_Table_body}>
                  {state.t_body.length === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      No Data Found
                      <span role="img" aria-label="jsx-a11y/accessible-emoji">
                        😔
                      </span>
                    </div>
                  ) : (
                    state.t_body.map((elm, key) => (
                      <div key={key} className={styles.schoolList_Table_body_row}>
                        <div className={styles.schoolList_Table_body_row_td2}>{elm.short_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.display_code}</div>
                        <div className={styles.schoolList_Table_body_row_td4}>{elm.ref_parent}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.place}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.country_name}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.pincode}</div>
                        <div
                          className={styles.schoolList_Table_body_row_td5}
                          onClick={() => {
                            handleEdit(elm.short_name, elm.display_code, elm.ref_parent, elm.place, elm.country_name, elm.pincode);
                          }}
                        >
                          <Icon>
                            <img
                              src={require('../../../../assets/icons/edit.png')}
                              alt="edit"
                              style={{
                                width: '16px',
                                height: '16px',
                                cursor: 'pointer',
                              }}
                            />
                          </Icon>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              }
            </div>
          )}
        </div>
      </Box>
      {/* <Box className={styles.pages+" "+"pagination"} component="div">
        {default_page === 1 ? <div className={styles.first+" "+styles.inActive}>First</div> :
        <div className={styles.first} onClick={(e) =>pagechange(e,1)}>First</div>}
        <div
          // className="col-md-10"
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   marginLeft: "50px",
          // }}
        >
          <Pagination
            page={default_page}
            count={state.pagecount}
            onChange={pagechange}
          />
        </div>
        {default_page === state.pagecount ? <div className={styles.last+" "+styles.inActive}>Last</div> :
        <div className={styles.last} onClick={(e) =>pagechange(e,state.pagecount)}>Last</div>}
        <div className={styles.showRows}>
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "showrow");
            }}
          >
            
            <option value="10">{"Show 10"}</option>
            <option value="20">{"Show 20"}</option>
          </Box>
        </div>
      </Box> */}
      <ModalComponent show={state.showHide} onClick={handleClose} className="grpInstModal">
        <Box className={styles.main_box} component="div" style={{ marginTop: '-20px' }}>
          <Formik initialValues={initialValues || state} validationSchema={validationBox} onSubmit={handleSave} enableReinitialize>
            {(formik) => {
              return (
                <Box component="div" style={{ width: '100%', height: 'auto' }}>
                  <Form className={styles.modal_Form}>
                    <label htmlFor="board">Institution short Name</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        name="short_name"
                        placeholder="Institution short Name"
                        className={styles.B_Field}
                        id="short_name"
                        value={editVal ? state.short_name : undefined}
                        // value={undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            short_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <label htmlFor="board">Display Code</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        name="display_code"
                        placeholder="Display code"
                        className={styles.B_Field}
                        id="display_code"
                        value={editVal ? state.display_code : undefined}
                        // value={undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            display_code: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <label htmlFor="board">Ref_Parent</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        name="ref_parent"
                        placeholder="Ref Parent"
                        className={styles.B_Field}
                        id="ref_parent"
                        value={editVal ? state.ref_parent : undefined}
                        // value={undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            ref_parent: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <label htmlFor="board">Place</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        name="place"
                        placeholder="Place"
                        className={styles.B_Field}
                        id="place"
                        value={editVal ? state.place : undefined}
                        // value={undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            place: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <label htmlFor="board">Country Name</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        name="country_name"
                        placeholder="country_name code"
                        className={styles.B_Field}
                        id="country_name"
                        value={editVal ? state.country_name : undefined}
                        // value={undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            country_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <label htmlFor="board">Pincode</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        name="Pincode"
                        placeholder="Pincode"
                        className={styles.B_Field}
                        id="Pincode"
                        value={editVal ? state.pincode : undefined}
                        // value={undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            pincode: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box className={styles.buttonss}>
                      {editVal ? (
                        <Box
                          className={styles.deleteBtn}
                          // onClick={handleDelete}
                        >
                          <img
                            src={require('../../../../assets/icons/DeleteBox.png')}
                            alt="deletebox"
                            style={{ cursor: 'pointer' }}
                          />
                        </Box>
                      ) : null}
                      <button className={styles.cancelBtn} onClick={handleClose} type="reset">
                        Cancel
                      </button>
                      <button
                        className={styles.saveBtn}
                        type="submit"
                        onClick={(e) => {
                          formik.setFieldValue('short_name', state.short_name);
                          formik.setFieldValue('display_code', state.display_code);
                          formik.setFieldValue('ref_parent', state.ref_parent);
                          formik.setFieldValue('place', state.place);
                          formik.setFieldValue('country_name', state.country_name);
                          formik.setFieldValue('pincode', state.pincode);
                        }}
                        disabled={!formik.isValid || formik.isSubmitting}
                      >
                        Save
                      </button>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </ModalComponent>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default Institution;
