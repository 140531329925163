import https from './init/https';

// PORT-IST-1: Download Sub Topics via Excel Download
export const getSubTopics = async (school_id, boardId, courseId, subjectId, subSubjectId, topicId, medium_id) => {
  return await https.get(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/${subSubjectId}/topics/${topicId}/sub_topics/excel-export?medium_id=${medium_id}`,
    { responseType: 'arraybuffer' },
  );
};

// PORT-IST-2: Add, update, delete sub topics via excel
export const addSubTopics = async (school_id, boardId, courseId, subjectId, subSubjectId, topicId, data) => {
  return await https.post(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/${subSubjectId}/topics/${topicId}/sub_topics/excel-import`,
    data,
  );
};

// PORT-IST-3: Retrieve list of Sub Topics
export const getSubTopicsList = async (school_id, boardId, courseId, subjectId, subSubjectId, topicId, medium_id) => {
  return await https.get(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/${subSubjectId}/topics/${topicId}/sub_topics/detailed-info?medium_id=${medium_id}`,
  );
};

// PORT-IST-4: Retrieve list of topics for an institution
export const getTopicsList = async (institution_id, board_id, course_id, subject_id, sub_subject_id, topic_id) => {
  return await https.get(
    `/institutions/${institution_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${sub_subject_id}/topics/${topic_id}/sub_topics`,
  );
};
