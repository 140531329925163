import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { fetchResults } from './DashboardData';
import DashboardChart from '../../variables/charts/BarChart';
import Calander from '../../components/calander/Calander';
import styles from './DashboardStyle.module.css';
import { BASE_URL } from '../../config/Api';
import liveClassIcon from '../../assets/icons/liveclass.png';
import educatorIcon from '../../assets/icons/educator.png';
import queryIcon from '../../assets/icons/Query.png';
import VideoIcon from '../../assets/icons/Videos.png';
import homeworkIcon from '../../assets/icons/homework.png';
import studentIcon from '../../assets/icons/student.png';

let months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
let prevDate;
let nextDate;
let totaldaysInMonth;

const Dashboard = () => {
  let date_format = localStorage.getItem('oncalendardate') === null ? new Date() : new Date(localStorage.getItem('oncalendardate'));
  let today;
  let month = date_format.getMonth();
  let year = date_format.getFullYear();
  let formateddate = new Date(date_format).toISOString().slice(0, 10);
  const [date, setDate] = useState(date_format);
  const [state, setState] = useState({
    activity: [],
    activity_status: [],
    maxVal: null,
    chart_value: [],
    liveClass_value: '',
    educator_value: '',
    query_value: '',
    video_n_notes: '',
    homework_value: '',
    test_value: '',
    student_value: '',
    Attendance_value: '',
    Activeness_value: '',
    Score_value: '',
    total_student: '',
    total_session_student_count: '',
    total_session_student_present_count: '',
    boxSelected: localStorage.getItem('boxSelected') === null ? 1 : localStorage.getItem('boxSelected'),
    graphText: localStorage.getItem('graphText') === null ? 'LIVE CLASS' : localStorage.getItem('graphText'),
  });

  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  const school_id = window.localStorage.getItem('school_id');
  const dataFetch = async () => {
    // This Api is not implemented OR feature is removed
    const live_class = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/live-class?date=${formateddate}`, Auth);
    // This Api is not implemented OR feature is removed
    const educator = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/educator`, Auth);
    // This Api is not implemented OR feature is removed
    const query = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/query`, Auth);
    // This Api is not implemented OR feature is removed
    const content = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/content?date=${formateddate}`, Auth);
    // This Api is not implemented OR feature is removed
    const assignment = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/assignment?date=${formateddate}`, Auth);
    // This Api is not implemented OR feature is removed
    const test = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/test?date=${formateddate}`, Auth);
    // This Api is not implemented OR feature is removed
    const student = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/student?date=${formateddate}`, Auth);

    prevDate = `${year}-${month + 1}-01`;
    nextDate = `${year}-${month + 1}-${totaldaysInMonth}`;
    // This Api is not implemented OR feature is removed
    const liveChart = axios.get(
      `${BASE_URL}/institutions/${school_id}/dashboard/live-class-chart?from_date=${prevDate}&to_date=${nextDate}`,
      Auth,
    );
    await axios
      .all([live_class, educator, query, content, assignment, test, student, liveChart])
      .then(
        axios.spread((...res) => {
          const live_class_res = res[0].data;
          const educator_res = res[1].data;
          const query_res = res[2].data;
          const content_res = res[3].data;
          const assignment_res = res[4].data;
          const test_res = res[5].data;
          const student_res = res[6].data;
          const liveChart_res = res[7].data;

          setState({
            ...state,
            activity: fetchResults.data.activity,
            activity_status: fetchResults.data.activity.data,

            total_session_student_count:
              student_res.data && student_res.data.total_session_student_count ? student_res.data.total_session_student_count : 0,
            total_session_student_present_count:
              student_res.data && student_res.data.total_session_student_present_count
                ? student_res.data.total_session_student_present_count
                : 0,
            liveClass_value:
              live_class_res.data && live_class_res.data.live_session_count ? live_class_res.data.live_session_count : '--',
            educator_value: educator_res.data && educator_res.data.cmn_teacher_count ? educator_res.data.cmn_teacher_count : '--',
            video_n_notes: content_res.data && content_res.data.cnt_content_count ? content_res.data.cnt_content_count : '--',
            homework_value:
              assignment_res.data && assignment_res.data.asg_assignment_count ? assignment_res.data.asg_assignment_count : '--',
            test_value: test_res.data && test_res.data.tst_test_count ? test_res.data.tst_test_count : '--',
            chart_value: liveChart_res.data,
            query_value: query_res.data && query_res.data.query_count === 0 ? 0 : '--',
            student_value:
              student_res.data && student_res.data.total_session_student_count
                ? student_res.data.total_session_student_count
                : '--',
            Attendance_value:
              student_res.data && student_res.data.total_session_student_present_count
                ? student_res.data.total_session_student_present_count
                : '--',
            Activeness_value: student_res.data && student_res.data.avg_activeness ? student_res.data.avg_activeness : '--',
            Score_value: student_res.data && student_res.data.avg_score ? student_res.data.avg_score : '--',
            total_student: student_res.data && student_res.data.total_student_count ? student_res.data.total_student_count : '--',
          });
        }),
      )
      .catch((err) => console.log(err.response));
  };

  let selectedBox;
  let text;
  const handleClick = (e, val, name) => {
    if (val === 'liveClass') {
      text = name;
      selectedBox = 1;
      localStorage.setItem('boxSelected', selectedBox);
      localStorage.setItem('graphText', text);
    } else if (val === 'educator') {
      text = name;
      selectedBox = 2;
      localStorage.setItem('boxSelected', selectedBox);
      localStorage.setItem('graphText', text);
    } else if (val === 'query') {
      text = name;
      selectedBox = 3;
      localStorage.setItem('boxSelected', selectedBox);
      localStorage.setItem('graphText', text);
    } else if (val === 'videos n notes') {
      text = name;
      selectedBox = 4;
      localStorage.setItem('boxSelected', selectedBox);
      localStorage.setItem('graphText', text);
    } else if (val === 'homework') {
      text = name;
      selectedBox = 5;
      localStorage.setItem('boxSelected', selectedBox);
      localStorage.setItem('graphText', text);
    } else if (val === 'test') {
      text = name;
      selectedBox = 6;
      localStorage.setItem('boxSelected', selectedBox);
      localStorage.setItem('graphText', text);
    } else if (val === 'student') {
      text = name;
      selectedBox = 7;
      localStorage.setItem('boxSelected', selectedBox);
      localStorage.setItem('graphText', text);
    }
    setState({
      ...state,
      boxSelected: selectedBox,
      graphText: text,
    });
  };

  const handleChange = async (e, val) => {
    var currentdate = new Date(date);
    if (val === 'prev') {
      let previousday = new Date(currentdate.getTime() - 24 * 60 * 60 * 1000);
      today = previousday.getDate();
      month = previousday.getMonth();
      year = previousday.getFullYear();
      setDate(new Date(year, month, today));
      localStorage.setItem('oncalendardate', new Date(year, month, today));
      formateddate = year + '-' + (month + 1) + '-' + today;
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
    } else if (val === 'next') {
      let nextday = new Date(currentdate.getTime() + 24 * 60 * 60 * 1000);
      today = nextday.getDate();
      month = nextday.getMonth();
      year = nextday.getFullYear();
      setDate(new Date(year, month, today));
      localStorage.setItem('oncalendardate', new Date(year, month, today));
      formateddate = year + '-' + (month + 1) + '-' + today;
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
    } else if (val === 'calander') {
      setDate(e);
      let date_val = new Date(e);
      localStorage.setItem('oncalendardate', date_val);
      month = date_val.getMonth();
      today = date_val.getDate();
      year = date_val.getFullYear();
      const getDaysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
      };

      totaldaysInMonth = getDaysInMonth(month + 1, year);
      formateddate = year + '-' + (month + 1) + '-' + today;
      prevDate = `${year}-${month + 1}-01`;
      nextDate = year + '-' + (month + 1) + '-' + totaldaysInMonth;
    }
    // This Api is not implemented OR feature is removed
    const live_class = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/live-class?date=${formateddate}`, Auth);
    // This Api is not implemented OR feature is removed
    const educator = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/educator`, Auth);
    // This Api is not implemented OR feature is removed
    const query = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/query`, Auth);
    // This Api is not implemented OR feature is removed
    const content = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/content?date=${formateddate}`, Auth);
    // This Api is not implemented OR feature is removed
    const assignment = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/assignment?date=${formateddate}`, Auth);
    // This Api is not implemented OR feature is removed
    const test = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/test?date=${formateddate}`, Auth);
    // This Api is not implemented OR feature is removed
    const student = axios.get(`${BASE_URL}/institutions/${school_id}/dashboard/student?date=${formateddate}`, Auth);
    // This Api is not implemented OR feature is removed
    const liveChart = axios.get(
      `${BASE_URL}/institutions/${school_id}/dashboard/live-class-chart?from_date=${prevDate}&to_date=${nextDate}`,
      Auth,
    );

    await axios
      .all([live_class, educator, query, content, assignment, test, student, liveChart])
      .then(
        axios.spread((...res) => {
          const live_class_res = res[0].data;
          const educator_res = res[1].data;
          const query_res = res[2].data;
          const content_res = res[3].data;
          const assignment_res = res[4].data;
          const test_res = res[5].data;
          const student_res = res[6].data;
          const liveChart_res = res[7].data;

          setState({
            ...state,
            activity: fetchResults.data.activity,
            activity_status: fetchResults.data.activity.data,
            total_session_student_count:
              student_res.data && student_res.data.total_session_student_count ? student_res.data.total_session_student_count : 0,
            total_session_student_present_count:
              student_res.data && student_res.data.total_session_student_present_count
                ? student_res.data.total_session_student_present_count
                : 0,
            liveClass_value:
              live_class_res.data && live_class_res.data.live_session_count ? live_class_res.data.live_session_count : '--',
            educator_value: educator_res.data && educator_res.data.cmn_teacher_count ? educator_res.data.cmn_teacher_count : '--',
            video_n_notes: content_res.data && content_res.data.cnt_content_count ? content_res.data.cnt_content_count : '--',
            homework_value:
              assignment_res.data && assignment_res.data.asg_assignment_count ? assignment_res.data.asg_assignment_count : '--',
            test_value: test_res.data && test_res.data.tst_test_count ? test_res.data.tst_test_count : '--',
            chart_value: liveChart_res.data,
            query_value: query_res.data && query_res.data.query_count === 0 ? 0 : '--',
            //
            student_value:
              student_res.data && student_res.data.total_session_student_count
                ? student_res.data.total_session_student_count
                : '--',
            Attendance_value:
              student_res.data && student_res.data.total_session_student_present_count
                ? student_res.data.total_session_student_present_count
                : '--',
            Activeness_value: student_res.data && student_res.data.avg_activeness ? student_res.data.avg_activeness : '--',
            Score_value: student_res.data && student_res.data.avg_score ? student_res.data.avg_score : '--',
            total_student: student_res.data && student_res.data.total_student_count ? student_res.data.total_student_count : '--',
          });
        }),
      )
      .catch((err) => console.log(err.response));
  };

  useEffect(() => {
    let maxVal = Math.floor(Math.max(state.chart_value) / 10) * 10 + 10;
    state.chart_value.max = maxVal;
    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  totaldaysInMonth = getDaysInMonth(month + 1, year);

  let newArr1 = [];
  let filteredArr = () => {
    var mapped_val = state.chart_value.map((el) => el.live_session_date);
    for (let value in mapped_val) {
      let dotted = `${mapped_val[value]}`;
      newArr1.push(Number(dotted.slice(8, 10)));
    }
  };
  filteredArr();

  let datesofMonth = [];
  let chartvalues = [...state.chart_value.map((el) => el.live_session_count)];
  for (let k = 1; k <= totaldaysInMonth; k++) {
    if (newArr1.includes(k)) console.log('E');
    else chartvalues.splice(k - 1, 0, '0');
    datesofMonth.push(k);
  }

  let value = (state.total_session_student_present_count / state.total_session_student_count) * 100;
  return (
    <>
      <div className={styles.dashboard_top}>
        <div className={styles.dashboard_txt}>DASHBOARD</div>
        <div className={styles.righ}>
          <div className={styles.cal}>
            <div className={styles.leftArr} onClick={(e) => handleChange(e, 'prev')}>
              <img src={require('../../assets/icons/arrowLeft.png')} alt="left arr" />
            </div>
            <div>
              <Calander selected={date} onChange={(e) => handleChange(e, 'calander')} id="date" name="date" type="date" />
            </div>
            <div className={styles.rightArr} onClick={(e) => handleChange(e, 'next')}>
              <img src={require('../../assets/icons/arrowRight.png')} alt="left arr" />
            </div>
          </div>
          <div>
            <img src={require('../../assets/icons/notification.png')} alt="notification" />
          </div>
        </div>
      </div>
      <Box component="div" className={styles.main_div}>
        <div className={styles.top}>
          <Box component="div" className={styles.top_left}>
            <Box
              component="div"
              className={state.boxSelected === 1 ? styles.border : styles.top_left_1}
              onClick={(e) => handleClick(e, 'liveClass', 'LIVE CLASS')}
              p={5}
            >
              <div>
                <div className={styles.txt}>LIVE CLASS</div>
                <div className={styles.val}>{state.liveClass_value}</div>
              </div>
              <div className={styles.rgtC}>
                {state.boxSelected === 1 ? (
                  <Link
                    to={{
                      pathname: '/admin/status/live-class',
                      dashboardProps: `${new Date(date_format)}`,
                    }}
                  >
                    <div className={styles.rgtC_txt}>
                      <span>Details</span>
                      <span style={{ marginLeft: '5px' }}>
                        <img src={require('../../assets/icons/arrowRight.png')} alt="detail" />
                      </span>
                    </div>
                  </Link>
                ) : null}
                <div className={styles.rgtC_img}>
                  <img src={liveClassIcon} alt="live class" />
                </div>
              </div>
            </Box>
            <Box
              component="div"
              onClick={(e) => handleClick(e, 'educator', 'EDUCATOR')}
              className={state.boxSelected === 2 ? styles.border : styles.top_left_2}
              p={5}
            >
              <div>
                <div className={styles.txt}>EDUCATOR</div>
                <div className={styles.val}>{state.educator_value}</div>
              </div>
              <div className={styles.rgtC}>
                {state.boxSelected === 2 ? (
                  <Link
                    to={{
                      pathname: '/admin/status/educator',
                      dashboardProps: `${new Date(date_format)}`,
                    }}
                  >
                    <div className={styles.rgtC_txt}>
                      <span>Details</span>
                      <span style={{ marginLeft: '5px' }}>
                        <img src={require('../../assets/icons/arrowRight.png')} alt="detail" />
                      </span>
                    </div>
                  </Link>
                ) : null}
                <div className={styles.rgtC_img}>
                  <img src={educatorIcon} alt="educator" />
                </div>
              </div>
            </Box>
            <Box
              component="div"
              onClick={(e) => handleClick(e, 'query', 'QUERY')}
              className={state.boxSelected === 3 ? styles.border : styles.top_left_3}
              p={5}
            >
              <div>
                <div className={styles.txt}>QUERY</div>
                <div className={styles.val}>{state.query_value}</div>
              </div>
              <div className={styles.rgtC}>
                {state.boxSelected === 3
                  ? // <Link to="/admin/status/live-class">
                    //   <div className={styles.rgtC_txt}>
                    //     <span>Details</span>
                    //     <span style={{ marginLeft: "5px" }}>
                    //       <img
                    //         src={require("../../assets/icons/arrowRight.png")}
                    //         alt="detail"
                    //       />
                    //     </span>
                    //   </div>
                    // </Link>
                    null
                  : null}
                <div className={styles.rgtC_img}>
                  <img src={queryIcon} alt="query" />
                </div>
              </div>
            </Box>
            <Box
              component="div"
              onClick={(e) => handleClick(e, 'videos n notes', 'VIDEOS & NOTES')}
              className={state.boxSelected === 4 ? styles.border : styles.top_left_4}
              p={5}
            >
              <div>
                <div className={styles.txt}>VIDEOS & NOTES</div>
                <div className={styles.val}>{state.video_n_notes}</div>
              </div>
              <div className={styles.rgtC}>
                {state.boxSelected === 4
                  ? // <Link to="/admin/status/educator">
                    //   <div className={styles.rgtC_txt}>
                    //     <span>Details</span>
                    //     <span style={{ marginLeft: "5px" }}>
                    //       <img
                    //         src={require("../../assets/icons/arrowRight.png")}
                    //         alt="detail"
                    //       />
                    //     </span>
                    //   </div>
                    // </Link>
                    null
                  : null}

                <div className={styles.rgtC_img}>
                  <img src={VideoIcon} alt="live class" />
                </div>
              </div>
            </Box>
          </Box>
          <Box component="div" className={styles.top_right}>
            <Box component="div" className={styles.top_right_chart} p={3}>
              <Box component="div" className={styles.chart_vv}>
                <Box>{state.graphText}</Box>
                <Box>
                  {months[month].slice(0, 3)} {year}
                </Box>
              </Box>
              <Box className={styles.chart_dd}>
                <DashboardChart dates={datesofMonth} values={chartvalues} />
              </Box>
            </Box>
            <Box component="div" className={styles.top_right_bottom}>
              <Box component="div" className={styles.top_right_bottom_left}>
                <Box
                  component="div"
                  p={5}
                  className={state.boxSelected === 5 ? styles.border : styles.top_right_bottom_left_1}
                  onClick={(e) => handleClick(e, 'homework', 'HOMEWORK')}
                >
                  <div>
                    <div className={styles.txt}>HOMEWORK</div>
                    <div className={styles.val}>{state.homework_value}</div>
                  </div>
                  <div className={styles.rgtC}>
                    {state.boxSelected === 5
                      ? // <Link to="/admin/status/educator">
                        //   <div className={styles.rgtC_txt}>
                        //     <span>Details</span>
                        //     <span style={{ marginLeft: "5px" }}>
                        //       <img
                        //         src={require("../../assets/icons/arrowRight.png")}
                        //         alt="detail"
                        //       />
                        //     </span>
                        //   </div>
                        // </Link>
                        null
                      : null}
                    <div className={styles.rgtC_img}>
                      <img src={homeworkIcon} alt="live class" />
                    </div>
                  </div>
                </Box>
                <Box
                  component="div"
                  className={state.boxSelected === 6 ? styles.border : styles.top_right_bottom_left_2}
                  onClick={(e) => handleClick(e, 'test', 'TEST')}
                  p={5}
                >
                  <div>
                    <div className={styles.txt}>TEST</div>
                    <div className={styles.val}>{state.test_value}</div>
                  </div>
                  <div className={styles.rgtC}>
                    {state.boxSelected === 6
                      ? // <Link to="/admin/status/educator">
                        //   <div className={styles.rgtC_txt}>
                        //     <span>Details</span>
                        //     <span style={{ marginLeft: "5px" }}>
                        //       <img
                        //         src={require("../../assets/icons/arrowRight.png")}
                        //         alt="detail"
                        //       />
                        //     </span>
                        //   </div>
                        // </Link>
                        null
                      : null}
                    <div className={styles.rgtC_img}>
                      <img src={studentIcon} alt="live class" />
                    </div>
                  </div>
                </Box>
              </Box>
              <Box
                component="div"
                className={styles.top_right_bottom_right}
                p={5}
                // className={ state.boxSelected === 7 ? styles.border : styles.top_right_bottom_right}
                onClick={(e) => handleClick(e, 'student', 'STUDENT')}
              >
                <div className={styles.top_right_bottom_right_top}>
                  <div style={{ width: '100%' }}>
                    <div className={styles.txt}>STUDENT</div>
                    <div className={styles.val}>{state.total_student}</div>
                  </div>
                  <Link to="/admin/status/student">
                    <div>
                      <img src={studentIcon} alt="live class" />
                    </div>
                  </Link>
                </div>
                <div className={styles.top_right_bottom_right_bottom}>
                  <div className={styles.top_right_bottom_right_botom_val}>
                    <span>Attendence</span>
                    <span>{isNaN(value) ? '-- ' : value}%</span>
                  </div>
                  <div className={styles.top_right_bottom_right_botom_val}>
                    <span>Activeness</span>
                    <span>{state.Activeness_value} %</span>
                  </div>
                  <div className={styles.top_right_bottom_right_botom_val}>
                    <span>Score</span>
                    <span>{state.Score_value} %</span>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
        </div>
        {/* <div className={styles.bottom} p={5}>
          <Box className={styles.Bottom_left} p={5}>
            <div style={{ marginBottom: "30px" }} className={styles.txt}>
              ACTIVITY
            </div>
            <div>
              <img src={activityIcon} alt="activity_img" />
            </div>
          </Box>
          <Box className={styles.article_container}>
            {state.activity_status.map((el, key) => (
              <div key={key} className={styles.article}>
                <div>
                  <Avatar
                    src={el.img}
                    alt="avatar"
                    style={{ height: "16px", width: "16px" }}
                  />
                </div>
                <div className={styles.status_name}>{el.name}</div>
                <div className={styles.status_status}>{el.status}</div>
                <div className={styles.status_update}>{el.updated}</div>
              </div>
            ))}
          </Box>
        </div> */}
      </Box>
    </>
  );
};

export default Dashboard;
