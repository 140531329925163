import React, { useState, useCallback, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import './courseSubject.css';
import ReactLoading from 'react-loading';
import styles from '../SubjectHierarchy/TopicStyle.module.css';
import TableRow from './TableRow';
import arrayMove from './ArrayMove';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { getAllMediums } from '../../../../api/mediums';
import { getCoursesListOfAnInstitution } from '../../../../api/institutionCourses';
import { getAllSubjectsByInstitute } from '../../../../api/InstitutionSubjects';
import { getSubjectTagsForBoardAPI } from '../../../../api/insitutionCourseSubjectLinking';
import { createCourseSubjectLinkageAPI, deleteCourseSubjectLinkageAPI } from '../../../../api/insitutionCourseSubjectLinking';
import { getBoardHierarchyForBoard } from '../../../../api/boardHierarchy';
import { getTaxonomiesList } from '../../../../api/taxonomy';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';

// var newId;
var tempObject;
var tempItems;
// var clickedRow;
// var savedCount = 0;
var school_id = -1;
var board_id = -1;
// var tempId;
var rowData;
var deleteData;
var tempTable = [];
var newTable = [];
var tempMedTax = [];
var newTempIndex;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <TableRow {...props} />);

function CourseSubject() {
  const [items, setItems] = useState([]);
  // const [board_id, setBoard_id] = useState(-1);
  const [loading, setLoading] = useState(false);
  // const [move, setMove] = useState(false);
  const [state, setState] = useState({
    boardOptions: [],
    courseOptions: [],
    mediumOptions: [],
    taxonomyOptions: [],
    subjectTagOptions: [],
    subjectOptions: [],
  });

  school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 3);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    console.log(oldIndex, newIndex);
    setLoading(true);
    setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
    // newId = newIndex;
    // setMove(true);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchBoard = async () => {
      console.log(456);
      try {
        // PORT-IB-5: Retrieve list of boards of an institution

        const getboard = await getBoardsByNameInInstitution(school_id);
        // await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/boards`,
        //   Auth
        // );
        // PORT-MD-1: Retrieve list of all mediums
        const getMedium = await getAllMediums(Auth);
        // const getMedium = await Axios.get(`${BASE_URL}/mediums/`, Auth);

        const getTaxonomy = await getTaxonomiesList();
        // await Axios.get(`${BASE_URL}/taxonomies`, Auth);
        setState({
          ...state,
          boardOptions: getboard.data.data,
          mediumOptions: getMedium.data.data,
          taxonomyOptions: getTaxonomy.data.data,
        });
        console.log(getboard.data.data);
        console.log(getMedium.data.data);
        console.log(getTaxonomy.data.data);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setLoading(false);
      }
    };

    fetchBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchTableData() {
    newTable = [];
    setLoading(true);
    console.log(school_id, board_id);
    try {
      // PORT-BH-4: Retrieve the Board Hierarchy for a Board
      const getTableData = await getBoardHierarchyForBoard(school_id, board_id, Auth);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/hierarchy/`,
      //   Auth
      // );

      // PORT-ICS-01: Retrieve list of Subject Tags for a Board
      const getSubjectTag = await getSubjectTagsForBoardAPI(school_id, board_id, Auth);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subject_tags`,
      //   Auth
      // );
      // PORT-IC-4: Retrieve list of courses of an institution
      const getCourseList = await getCoursesListOfAnInstitution(school_id, board_id, Auth);
      setState({
        ...state,
        courseOptions: getCourseList.data.data,
      });
      // const getCourseList = await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses`,
      //   Auth
      // );
      if (getTableData.data.data.length === 0) {
        tempTable = [
          {
            course_id: '',
            course_name: '',
            course_seq_no: '',
            hierarchy_1_value: '',
            medium: '',
            medium_id: '',
            subjectOptions: [],
            subject_id: '',
            subject_name: '',
            subject_seq_no: '',
            subject_tag: '',
            taxonomy: '',
            taxonomy_id: '',
            translated_subject_name: '',
          },
        ];
        console.log(tempTable);
        setItems(tempTable);
      } else {
        tempTable = getTableData.data.data;
        for (var i = 0; i < tempTable.length; i++) {
          if (tempTable[i].subject_tag === null || tempTable[i].subject_tag === '') {
            newTable.push({ ...tempTable[i], subjectOptions: [] });
          } else {
            try {
              // PORT-IS-4: Retrieve list of subjects for an institutions
              var getSubjectOptions = await getAllSubjectsByInstitute(school_id, board_id, tempTable[i].subject_tag, Auth);
              // await Axios.get(
              //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subjects?subject_tag=${tempTable[i].subject_tag}`,
              //   Auth
              // );
              console.log(getSubjectOptions.data.data);
              // setLoading(false);
              newTable.push({
                ...tempTable[i],
                subjectOptions: getSubjectOptions.data.data,
              });
            } catch (error) {
              if (error.response && error.response.data) {
                console.log(error.response.data.message);
                toast.error(error.response.data.message);
              }
              setLoading(false);
            }
          }
        }
        console.log(tempTable, newTable, 'tempTable');
        // tempTable = getTableData.data.data.map((row) => {
        //   if (
        //     row.subject_tag === null ||
        //     row.subject_tag === ""
        //   ) {
        //     return { ...row, subjectOptions: [] };
        //   } else {
        //     try {
        //       var getSubjectOptions = await Axios.get(
        //         `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subjects?subject_tag=${row.subject_tag}`,
        //         Auth
        //       );
        //       console.log(getSubjectOptions.data.data);
        //       // setLoading(false);
        //       return { ...row, subjectOptions: getSubjectOptions.data.data };
        //     } catch (error) {
        //       if (error.response && error.response.data) {
        //         console.log(error.response.data.message);
        //         toast.error(error.response.data.message);
        //       }
        //       setLoading(false);
        //     }
        //   }
        // });
        console.log(newTable);
        setItems(newTable);
      }
      setState({
        ...state,
        courseOptions: getCourseList.data.data,
        subjectTagOptions: getSubjectTag.data.data,
      });
      console.log(getSubjectTag.data.data);
      console.log(getCourseList.data.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  }

  const changedBoard = async (e, selectedvalue) => {
    console.log('123');
    setLoading(true);
    if (+e.target.value === -1) {
      // setBoard_id(e.target.value);
      board_id = e.target.value;
      setItems([]);
      setLoading(false);
    } else {
      if (selectedvalue === 'board') {
        // setBoard_id(e.target.value);
        board_id = e.target.value;
        fetchTableData();
      }
    }
  };

  async function handleChange(event, first, id) {
    var text = event.target.value;
    console.log(text, first, id);
    // if (savedCount === 0) {
    //   clickedRow = items[id];
    //   savedCount = savedCount + 1;
    // }
    tempObject = items;
    tempObject = tempObject.map((item, i) => {
      if (i === id) {
        console.log(123);
        return { ...item, [first]: text, notSaved: true };
      } else {
        return item;
      }
    });
    if (first === 'subject_tag') {
      setLoading(true);
      // text = JSON.stringify(text);
      try {
        // PORT-IS-4: Retrieve list of subjects for an institutions
        const getSubject = await getAllSubjectsByInstitute(school_id, board_id, text, Auth);
        // const getSubject = await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subjects?subject_tag=${text}`,
        //   Auth
        // );
        console.log(getSubject.data.data);
        // setState({
        //   ...state,
        //   subjectOptions: getSubject.data.data,
        // });
        tempObject = tempObject.map((item, i) => {
          if (i === id) {
            console.log(456);
            return {
              ...item,
              subjectOptions: getSubject.data.data,
              notSaved: true,
            };
          } else {
            return item;
          }
        });
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setLoading(false);
      }
    }
    if (first === 'subject_id') {
      // tempMedTax = tempObject[id].subjectOptions;
      tempMedTax = tempObject.map((item, i) => {
        if (i === id) {
          return item.subjectOptions.map((sub, subIndex) => {
            console.log(sub.subject_id, +text);
            if (+sub.subject_id === +text) {
              console.log(888);
              newTempIndex = subIndex;
              return sub;
            } else {
              return sub;
            }
          });
        } else {
          return item;
        }
      });
      console.log(tempMedTax);
      tempObject = tempObject.map((item, i) => {
        if (i === id) {
          console.log(4567);
          return {
            ...item,
            // subjectOptions: getSubject.data.data,
            medium_id: tempMedTax[id][newTempIndex].medium_id,
            medium: tempMedTax[id][newTempIndex].medium,
            taxonomy_id: tempMedTax[id][newTempIndex].taxonomy_id,
            taxonomy: tempMedTax[id][newTempIndex].taxonomy_name,
            notSaved: true,
          };
        } else {
          return item;
        }
      });
    }
    console.log(tempObject);
    setItems(tempObject);
  }

  async function handleAddRow(id, rowDetails) {
    tempItems = items;
    // tempId = +rowDetails.course_id + 100000;
    tempItems.splice(id + 1, 0, {
      // ...rowDetails,
      course_id: '',
      course_name: '',
      course_seq_no: null,
      hierarchy_1_value: '',
      medium: '',
      medium_id: '',
      notSaved: true,
      subjectOptions: [],
      subject_id: '',
      subject_name: '',
      subject_seq_no: '',
      subject_tag: '',
      taxonomy: '',
      taxonomy_id: '',
      translated_subject_name: '',
      // course_id: tempId,
    });
    console.log(tempItems);
    setItems([...tempItems]);
  }

  function handleCancel(id) {
    fetchTableData();
    // console.log(savedCount);
    // if (savedCount !== 0) {
    //   tempItems = items;
    //   tempItems.splice(id, 1);
    //   console.log(clickedRow);
    //   tempItems.splice(id, 0, { ...clickedRow, notSaved: false });
    //   setItems([...tempItems]);
    //   savedCount = 0;
    // } else if (savedCount === 0) {
    //   tempItems = items;
    //   tempItems.splice(id, 1);
    //   setItems([...tempItems]);
    // }
  }

  async function handleSave(id, rowDetails) {
    setLoading(true);
    console.log(id);
    console.log(rowDetails);
    tempObject = items;
    tempObject = tempObject.map((item, i) => {
      if (i === id) {
        console.log(123);
        return { ...item, notSaved: false };
      } else {
        return item;
      }
    });
    setItems(tempObject);
    // if (rowDetails.course_id >= 100000) {
    //   var newcourseId = rowDetails.course_id - 100000;
    //   console.log(newcourseId);
    //   rowData = {
    //     subject_tag: rowDetails.subject_tag,
    //     subject_id: rowDetails.subject_id,
    //     medium_name: rowDetails.medium_name,
    //     taxonomy_name: rowDetails.taxonomy_name,
    //   };
    //   console.log(rowData, 456);
    //   try {
    //     const createCourseSubject = await Axios.post(
    //       `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${newcourseId}/subjects`,
    //       rowData,
    //       Auth
    //     );
    //     console.log(createCourseSubject.data.data);
    //     toast.success("Successfuly created new Course-Subject link");
    //     fetchTableData();
    //   } catch (error) {
    //     if (error.response && error.response.data) {
    //       console.log(error.response.data.message);
    //       toast.error(error.response.data.message);
    //     }
    //     fetchTableData();
    //   }
    // } else {
    rowData = {
      subject_tag: rowDetails.subject_tag,
      subject_id: rowDetails.subject_id,
      medium_name: rowDetails.medium,
      taxonomy_name: rowDetails.taxonomy,
    };
    console.log(rowData, rowDetails, 789);
    try {
      // PORT-ICS-02: Create a new Course-Subject Linkage
      const updateCourseSubject = await createCourseSubjectLinkageAPI(school_id, board_id, rowDetails.course_id, rowData, Auth);

      // await Axios.post(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${rowDetails.course_id}/subjects`,
      //   rowData,
      //   Auth
      // );
      console.log(updateCourseSubject.data.data);
      toast.success('Successfuly updated');
      fetchTableData();
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      fetchTableData();
    }
    // }
  }

  async function handleDelete(id, rowDetails) {
    console.log(id);
    tempItems = items;
    tempItems.splice(id, 1);
    setItems([...tempItems]);
    console.log(deleteData);
    try {
      const deleteRow = await deleteCourseSubjectLinkageAPI(school_id, board_id, rowDetails.course_id, rowDetails.subject_id, Auth);
      // await Axios.delete(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${rowDetails.course_id}/subjects/${rowDetails.subject_id}`,
      //   {
      //     headers: Auth.headers,
      //   }
      // );
      console.log(deleteRow.data.data);
      // toast.success(deleteRow.data.data.o_result);
      toast.success('Successfully deleted course-subject link');
      fetchTableData();
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      fetchTableData();
    }
  }

  return (
    <div>
      {loading ? <ReactLoading type="bars" color="#4fb64b" className="startUploadLoadSpin" /> : null}
      <div className={styles.mainTop_box}>
        <Box className={styles.top_box} component="div">
          <Box component="div" className={styles.top_box_l}>
            <div>
              <label>Board</label>
              <Box
                component="select"
                className={styles.options}
                onChange={(e) => {
                  changedBoard(e, 'board');
                }}
              >
                <option value="-1">{'Board'}</option>
                {state.boardOptions.map((el, key) => (
                  <option value={el.board_id} key={key}>
                    {el.display_name}
                  </option>
                ))}
              </Box>
            </div>
          </Box>
          <Box component="div" className={styles.buttons}>
            <Box
              component="div"
              className={styles.buttons_format}
              //   onClick={downloadFile}
            >
              <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ paddingRight: '10px' }} />
              Download
            </Box>
            <Box component="div" className={styles.buttons_data}>
              <Formik>
                {(formik) => {
                  return (
                    <div>
                      <Form>
                        <Field
                          type="file"
                          id="filetoupload"
                          name="filetoupload"
                          onChange={(e) => {
                            // uploadFile(e);
                            e.target.value = null;
                          }}
                          style={{ display: 'none' }}
                        />
                        <label
                          htmlFor="filetoupload"
                          style={{
                            width: '120px',
                            height: '32px',
                            marginBottom: '0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '10px',
                            color: 'rgb(155, 139, 139)',
                            cursor: 'pointer',
                            textAlign: 'center',
                            padding: '11px',
                          }}
                        >
                          <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ paddingRight: '10px' }} />
                          Upload
                        </label>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </div>
      {items !== [] ? (
        <table className="boardTable">
          <thead>
            <tr className="theadDragTable">
              <th>Move</th>
              <th>Course</th>
              <th>Subject Tag</th>
              <th>Subject</th>
              <th>Medium</th>
              <th>Taxonomy</th>
              <th>Action</th>
            </tr>
          </thead>
          <SortableCont
            onSortEnd={onSortEnd}
            axis="y"
            lockAxis="y"
            lockToContainerEdges={true}
            lockOffset={['30%', '50%']}
            helperClass="helperContainerClass"
            useDragHandle={true}
          >
            {items.map((value, index) => (
              <SortableItem
                key={`item-${index}`}
                handleChange={handleChange}
                index={index}
                id={index}
                rowDetails={value}
                handleSave={handleSave}
                handleDelete={handleDelete}
                handleAddRow={handleAddRow}
                handleCancel={handleCancel}
                subject={value.subjectOptions}
                course={state.courseOptions}
                medium={state.mediumOptions}
                taxonomy={state.taxonomyOptions}
                subjectTag={state.subjectTagOptions}
                notSaved={value.notSaved ? true : false}
                first={value.course_id}
                second={value.subject_tag}
                third={value.subject_id}
                fourth={value.medium}
                fifth={value.taxonomy}
              />
            ))}
          </SortableCont>
        </table>
      ) : null}
    </div>
  );
}

export default CourseSubject;
