export { default as Login } from './Login/Login';
export { default as Dashboard } from './Dashboard/Dashboard';
export { default as Manage } from './Manage/Manage';
export { default as MasterData } from './MasterData/MasterData';
export { default as GroupData } from './GroupData/GroupData';
export { default as Reports } from './Reports/Reports';
export { default as Status } from './Status/Status';
export { default as Educator } from './Status/Educator/Educator';
export { default as LiveClass } from './Status/LiveClass/LiveClass';
export { default as Student } from './Status/Student/Student';
export { default as Settings } from './Settings/Settings';

export { default as ResetPassword } from './ResetPassword/ResetPassword';
export { default as NewPassword } from './NewPassword/NewPassword';
