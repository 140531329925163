// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calander_input__s4L5O {
    border: none;
    width: 100px;
    background: none;
    font-size: 15px;
    font-weight: bold;
    opacity: .7;
    outline: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.Calander_react-datepicker__input-container__oGbWj{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/calander/Calander.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".input {\n    border: none;\n    width: 100px;\n    background: none;\n    font-size: 15px;\n    font-weight: bold;\n    opacity: .7;\n    outline: none;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n.react-datepicker__input-container{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    align-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Calander_input__s4L5O`,
	"react-datepicker__input-container": `Calander_react-datepicker__input-container__oGbWj`
};
export default ___CSS_LOADER_EXPORT___;
