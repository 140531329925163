// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchIcon{
    padding: 0px !important;
    height: 100%;
    display: flex;
    /* padding: 0px 16px; */
    position: absolute;
    align-items: center;
    pointer-events: none;
    justify-content: center;
  }
  
  .MuiInputBase-input{
    padding: 6px 26px 7px !important;
  }`, "",{"version":3,"sources":["webpack://./src/screens/Login/login.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;IACpB,uBAAuB;EACzB;;EAEA;IACE,gCAAgC;EAClC","sourcesContent":[".searchIcon{\n    padding: 0px !important;\n    height: 100%;\n    display: flex;\n    /* padding: 0px 16px; */\n    position: absolute;\n    align-items: center;\n    pointer-events: none;\n    justify-content: center;\n  }\n  \n  .MuiInputBase-input{\n    padding: 6px 26px 7px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
