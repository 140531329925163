import https from './init/https';

// PORT-SC-1: List the study circles in tabular format
export const listStudyCircles = async (school_id) => {
  return await https.get(`/institutions/${school_id}/study_circles/detailed-info`);
};

// PORT-SC-2: Create a new study circle
export const createStudyCircle = async (school_id, data) => {
  return await https.post(`/institutions/${school_id}/study_circles`, data);
};

// PORT-SC-3: Edit Study Circle Details
export const editStudyCircleDetails = async (school_id, study_circle_ID, data) => {
  return await https.put(`/institutions/${school_id}/study_circles/${study_circle_ID}`, data);
};

// PORT-SC-4: Delete Study Circle
export const deleteStudyCircle = async (institution_id, study_circle_id) => {
  return await https.delete(`/institutions/${institution_id}/study_circles/${study_circle_id}`);
};

// PORT-SC-5: Download Student List in Excel
export const downloadStudentListExcel = async (school_id, study_circle_ID, academic_period_name) => {
  return await https.get(
    `/institutions/${school_id}/study_circles/${study_circle_ID}/students/excel-export?academic_period_name=${academic_period_name}`,
    { responseType: 'arraybuffer' },
  );
};

// PORT-SC-6: Add / update / delete students via excel import
export const uploadStudentListCSV = async (school_id, study_circle_ID, academic_period_name, formdata) => {
  return await https.post(
    `/institutions/${school_id}/study_circles/${study_circle_ID}/students/excel-import?academic_period_name=${academic_period_name}`,
    formdata,
  );
};
// PORT-SC-7: Display Student List Table
export const getStudentListTableData = async (institution_id, study_circle_id, academic_period_name) => {
  return await https.get(
    `/institutions/${institution_id}/study_circles/${study_circle_id}/students/detailed-info?academic_period=${academic_period_name}`,
  );
};

// PORT-SC-8: Add Subject to Study Circle
export const addSubjectToStudyCircle = async (institution_id, study_circle_id, data) => {
  return await https.post(`/institutions/${institution_id}/study_circles/${study_circle_id}/subjects`, data);
};

// PORT-SC-9: Display Subjects for a Study Circle
export const getSubjectsForStudyCircle = async (institution_id, study_circle_id) => {
  return await https.get(`/institutions/${institution_id}/study_circles/${study_circle_id}/subjects/detailed-info`);
};

// PORT-SC-10: Delete Subject from Study Circle
export const removeSubjectFromStudyCircle = async (institution_id, study_circle_id, study_circle_subject_id) => {
  return await https.delete(`/institutions/${institution_id}/study_circles/${study_circle_id}/subjects/${study_circle_subject_id}`);
};

// PORT-SC-11: Retrieve list of educators available for a study circle subject
export const getEducatorsForSubjectInStudyCircle = async (institution_id, study_circle_id, subject_id) => {
  return await https.get(`/institutions/${institution_id}/study_circles/${study_circle_id}/subjects/${subject_id}/educators`);
};

// PORT-SC-12: Add Educator to Study Circle Subject
export const addEducatorToSubjectInStudyCircle = async (institution_id, study_circle_id, subject_id, data) => {
  return await https.post(
    `/institutions/${institution_id}/study_circles/${study_circle_id}/subjects/${subject_id}/educators`,
    data,
  );
};

// PORT-SC-13: Delete Educator from Study Circle Subject
export const deleteEducatorFromSubjectInStudyCircle = async (
  institution_id,
  study_circle_id,
  subject_id,
  study_circle_subject_educator_id,
) => {
  return await https.delete(
    `/institutions/${institution_id}/study_circles/${study_circle_id}/subjects/${subject_id}/educators/${study_circle_subject_educator_id}`,
  );
};
