import React, { useState, useEffect, useRef } from 'react';
import { Box, Icon, Avatar, InputBase } from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import TextError from '../../../../variables/errors/Text_error';
import styles from './GroupEducatorStyle.module.css';
import addPhoto from '../../../../assets/images/AddPhoto.png';
import Axios from 'axios';
import * as ReactBootStrap from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { BASE_URL } from '../../../../config/Api';
import s3 from '../../../../config/DigitalOcean';
import configuration from '../../../../config/config';
import ButtonComponent from '../../../../components/button/Button';
import Moment from 'moment';
import { ModalComponent, Dialogbox, Calander } from '../../../../components/index';
import { getCoursesListOfAnInstitution } from '../../../../api/institutionCourses';
import { assignNewSubjectToEducator } from '../../../../api/educator';
import { getAllSubjectsByBoardId } from '../../../../api/InstitutionSubjects';

import dum from '../../../../assets/images/av-3.png';
import {
  getGroupEducators,
  uploadGroupEducators,
  getGroupEducatorList,
  getGroupInstitutions,
  getSelectedGroupEducator,
  getGroupEducatorSubjects,
  uploadGroupEducatorSubjects,
  getGroupEducatorSubjectList,
  getGroupStudyCircleAllocations,
} from '../../../../api/groupEducator';

const initialValues = {
  Name: '',
  DOB: '',
  reg_mob_no: '',
  alt_mob: '',
  city: '',
  pincode: '',
  isp: '',
  file: '',
};
const initialAddVal = {
  board: '',
  course: '',
  subject: '',
  desc: '',
};
var DateOfBirth;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
var grpInst_id = -1;
var downloadApiUrl;
var uploadApiUrl;
var dropGrpInst;

console.log(downloadApiUrl, uploadApiUrl);
const validationAdd = Yup.object({
  board: Yup.string(),
  course: Yup.string(),
  subject: Yup.string(),
});
const validationSchema = Yup.object({
  Name: Yup.string().min(1, 'educator name must contain a character'),
  // .required('An Educator Must Be a Name'),
  DOB: Yup.string(),
  reg_mob_no: Yup.number(),
  // .min(10,'Number must be 10 digits')
  // .required('An Educator Must Be a Contact Number')
  alt_mob: Yup.number(),
  city: Yup.string(),
  pincode: Yup.number(),
  int_service_provider: Yup.string(),
});

var subject_head = ['Board', 'Course', 'Subject', 'Description', ''];
var study_circle_head = ['Study Circle', 'Subject', 'Details', 'Role'];
// var img_file_path;
var FilePath;

const Educator = () => {
  const [open, setOpen] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [msg, setMsg] = useState('');
  const [errMsg, setErrMsg] = useState([]);
  const [addEd, setEd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editVal, setEditVal] = useState(false);
  // const [calander, setCalander] = useState(false);
  const [state, setState] = useState({
    educatorList: [],
    dropGrpInst: [],
    subject_body: [],
    study_circle_body: [],
    drop_Board: [],
    drop_course: [],
    drop_Subject: [],
    edu_alt_phone_number: '',
    edu_city: '',
    edu_profile_pic: '',
    edu_dob: new Date(),
    edu_id: '',
    edu_name: '',
    edu_phone_number: '',
    edu_pincode: '',
    edu_isp: '',
    edu_email: '',
    educatorId: '',
    isp: '',
    desc: '',
    showHide: false,
    activeId: null,
    activeEducatorId: null,
    // Name: "",
    // DOB: "",
    // reg_mob_no: "",
    imgPrev1: addPhoto,
    // file: "",
  });

  const uploadEducatorList = useRef(null);
  // const uploadSubjectList = useRef(null);

  const school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('groupTabIndex', 2);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  const handleClose = (value) => {
    setState((prevState) => ({
      ...prevState,
      showHide: false,
    }));
    setOpen(false);
  };
  // const handleClickOpen = () => {
  //   setState((prevState) => ({
  //     ...prevState, showHide: true }));
  // };

  const handleClick = (event) => {
    uploadEducatorList.current.click();
  };
  const fetchResults = async () => {
    // PORT-GE-3: Retrieve the List of Group Educators

    const educatorList = await getGroupEducatorList();
    // await Axios.get(
    //   `${BASE_URL}/group_educators/search`,
    //   Auth
    // );

    setState((prevState) => ({
      ...prevState,
      educatorList: educatorList.data.data,
    }));

    if (window.localStorage.getItem('detailInfo_id') == null) {
      if (educatorList.data.data.length === 0) {
        // console.log(educatorList.data.length);
      } else {
        getDetail(educatorList.data.data[0].id);
        window.localStorage.setItem('detailInfo_id', educatorList.data.data[0].id);
      }
    } else {
      getDetail(window.localStorage.getItem('detailInfo_id'));
    }
  };

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line
  }, []);

  const getDetail = async (id) => {
    const id_gd = window.localStorage.getItem('activedEducator');
    if (id_gd) id = id_gd;

    setState((prevState) => ({
      ...prevState,
      dropGrpInst: [],
    }));

    // PORT-GE-9: Retrieve the Group study circle allocations of a Group educator

    const studyCircleList = await getGroupStudyCircleAllocations(id);
    // await Axios.get(
    //   `${BASE_URL}/group_educators/${id}/group_study_circles`,
    //   Auth
    // );

    // PORT-GE-8: Display Group Educator Subject List

    const educatorSub = await getGroupEducatorSubjectList(id);
    // await Axios.get(
    //   `${BASE_URL}/group_educators/${id}/subjects/`,
    //   Auth
    // );

    // PORT-GE-4: Retrieve the list of Group Institutions for the selected Group Educator

    const educatorGrpInst = await getGroupInstitutions(id);
    // await Axios.get(
    //   `${BASE_URL}/group_educators/${id}/group_institutions`,
    //   Auth
    // );
    console.log(dropGrpInst, `dropdown`);
    if (educatorGrpInst.data.data.length === 0) {
      toast.error('Please add Group Institution for Educator');
      dropGrpInst = -1;
    } else {
      dropGrpInst = educatorGrpInst.data.data[0].group_institution_id;
    }
    if (dropGrpInst !== -1) {
      // PORT-GE-5: Retrieve Basic Info of the Selected Group Educator and Group Institution Combination

      const educatorGrpInfo = await getSelectedGroupEducator(id, dropGrpInst);
      // await Axios.get(
      //   `${BASE_URL}/group_educators/${id}/group_institutions/${dropGrpInst}/basic_info`,
      //   Auth
      // );

      var strToDate = Moment(educatorGrpInfo.data.data[0].date_of_birth, 'DD/MM/YYYY').format('YYYY-MM-DD');
      var date = new Date(strToDate);

      setState((prevState) => ({
        ...prevState,
        dropGrpInst: educatorGrpInst.data.data,
        edu_alt_phone_number: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].phone_number_alt : '',
        edu_city: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].city : '',
        edu_dob: educatorGrpInfo.data.data[0] ? date : '',
        edu_id: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].group_educator_id : '',
        edu_name: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].educator_name : '',
        edu_phone_number: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].phone_number : '',
        edu_pincode: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].pincode : '',
        edu_isp: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].isp : '',
        edu_email: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].email : '',
        imgPrev1: educatorGrpInfo.data.data[0] !== null ? educatorGrpInfo.data.data[0].profile_pic_url : addPhoto,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      subject_body: educatorSub?.data.data,
      study_circle_body: studyCircleList.data.data,
    }));
  };

  const changedBoard = async (e, selectedvalue) => {
    const grpEduId = window.localStorage.getItem('activedEducator');
    if (selectedvalue === 'board') {
      board_id = e.target.value;
    } else if (selectedvalue === 'course') {
      course_id = e.target.value;
    } else if (selectedvalue === 'subject') {
      subject_id = e.target.value;
    } else if (selectedvalue === 'grpInst') {
      grpInst_id = e.target.value;
    }
    try {
      // PORT-IC-4: Retrieve list of courses of an institution
      const getCourse = await getCoursesListOfAnInstitution(school_id, board_id, Auth);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses`,
      //   Auth
      // );

      const getSubject = await getAllSubjectsByBoardId(school_id, board_id, course_id, '');
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects`,
      //   Auth
      // );

      let educatorGrpInfo;
      if (grpInst_id !== 'Group Institution') {
        // PORT-GE-5: Retrieve Basic Info of the Selected Group Educator and Group Institution Combination

        educatorGrpInfo = await getSelectedGroupEducator(grpEduId, grpInst_id);
      } else {
        toast.error('Please Select Group Institution');
      }
      // await Axios.get(
      //   `${BASE_URL}/group_educators/${grpEduId}/group_institutions/${grpInst_id}/basic_info`,
      //   Auth
      // );

      var strToDate = Moment(educatorGrpInfo.data.data[0].date_of_birth, 'DD/MM/YYYY').format('YYYY-MM-DD');
      var date = new Date(strToDate);
      // console.log(getSubject)
      setState((prevState) => ({
        ...prevState,
        edu_alt_phone_number: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].phone_number_alt : '',
        edu_city: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].city : '',
        edu_dob: educatorGrpInfo.data.data[0] ? date : '',
        edu_id: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].group_educator_id : '',
        edu_name: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].educator_name : '',
        edu_phone_number: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].phone_number : '',
        edu_pincode: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].pincode : '',
        edu_isp: educatorGrpInfo.data.data[0] ? educatorGrpInfo.data.data[0].isp : '',
        edu_email: educatorGrpInfo.data[0] ? educatorGrpInfo.data.data[0].email : '',
        imgPrev1: educatorGrpInfo.data.data[0] !== null ? educatorGrpInfo.data.data[0].profile_pic_url : addPhoto,
        drop_course: getCourse.data.data,
        drop_Subject: getSubject.data.data,
      }));
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  const saveEducator = async (values, submitProps) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();

    let educatorsId = window.localStorage.getItem('detailInfo_id');
    if (editVal) {
      // This Api is not implemented OR feature is removed
      await Axios.put(
        `${BASE_URL}/institution/${school_id}/educator/${educatorsId}`,
        {
          name: values.Name,
          dob: state.edu_dob,
          phone_number: values.reg_mob_no,
          alt_phone_number: values.alt_mob,
          city: values.city,
          pincode: values.pincode,
          isp: values.isp,
        },
        Auth,
      )
        .then((res) => {
          toast.success('Educator Info Edited successfully');
          if (res.data.status === true) {
            toast.success(res.datastatusText);
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
          }
        });
    } else {
      // This Api is not implemented OR feature is removed
      await Axios.post(
        `${BASE_URL}/institution/${school_id}/educator`,
        {
          name: values.Name,
          dob: state.edu_dob,
          phone_number: values.reg_mob_no,
          alt_phone_number: values.alt_mob,
          city: values.city,
          pincode: values.pincode,
          isp: values.isp,
        },
        Auth,
      )
        .then((res) => {
          toast.success('new Educator Added Successfully');
          if (res.data.status === true) {
            toast.success(res.datastatusText);
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
          }
        });
    }
  };

  const handleChange = (e, imgPath) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (imgPath === 'imagepath1') {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev1: reader.result,
        });
      };
    }
    reader.readAsDataURL(file);
    if (e.target.files && e.target.files[0]) {
      const blob = e.target.files[0];
      let fileType = e.target.files[0].type;
      let ext = fileType.substr(fileType.lastIndexOf('/') + 1);

      if (imgPath === 'imagepath1') {
        FilePath = configuration.folderName + '/educator-image' + school_id + '.' + ext;
        // img_file_path =
        // "https://stucle-dev.sgp1.digitaloceanspaces.com/" + FilePath;
      }

      const params = {
        Body: blob,
        Bucket: `${configuration.bucketName}`,
        Key: FilePath,
      };
      s3.putObject(params)
        .on('build', (request) => {
          request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
          request.httpRequest.headers['Content-Length'] = blob.size;
          request.httpRequest.headers['Content-Type'] = blob.type;
          request.httpRequest.headers['x-amz-acl'] = 'public-read';
          request.httpRequest.headers['Access-Control-Allow-Origin'] = '*';
        })
        .send((err) => {
          if (err) console.log(err, 'Img error');
          else {
            const imageUrl = `${configuration.digitalOceanSpaces}` + blob.name;
            console.log(imageUrl);
          }
        });
    }
  };

  const addSubject = async (values, submitProps) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();

    let educatorsId = window.localStorage.getItem('detailInfo_id');
    console.log(school_id, educatorsId, subject_id);
    try {
      // PORT-EDU-6: Assign a new Subject to an Educator

      const res = await assignNewSubjectToEducator(school_id, educatorsId, subject_id, values.desc);
      toast.success('Subject Added');
      if (res.data.status === true) {
        window.location.reload(false);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }

    // await Axios.post(
    //   `${BASE_URL}/institutions/${school_id}/educators/${educatorsId}/subjects/${subject_id}`,
    //   {
    //     description: values.desc,
    //   },
    //   Auth
    // )
    //   .then((res) => {
    //     toast.success("Subject Added");
    //     if (res.data.status === true) {
    //       window.location.reload(false);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response && err.response.data) {
    //       console.log(err.response.data.message);
    //       toast.error(err.response.data.message);
    //     }
    //   });
  };

  // const deleteSubject = async (e,eduSubId) => {
  //   let educatorsId = window.localStorage.getItem("detailInfo_id");
  //   Axios.delete(`${BASE_URL}/institutions/${school_id}/educators/${educatorsId}/subjects/${eduSubId}`, Auth)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         toast.error("data deleted successfully");
  //         setTimeout(() => {
  //           window.location.reload(false);
  //         }, 3000);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //       window.location.reload(false);
  //     });
  // };

  const deleteEducator = async (e) => {
    let educatorsId = window.localStorage.getItem('detailInfo_id');
    // This Api is not implemented OR feature is removed
    Axios.delete(`${BASE_URL}/institution/${school_id}/educator/${educatorsId}`, Auth)
      .then((res) => {
        toast.error(`${res.statusText},educator deleted successfully`);
        if (res.data.status === true) {
          window.location.reload(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
  };

  const handleClosePopup = () => {
    setOpen(false);
    setErrorUpload(false);
  };
  const handleEducatorAdd = () => {
    setOpen(true);
    setEd(true);
    setEditVal(false);
  };

  const handleEditEducator = () => {
    setOpen(true);
    setEd(true);
    setEditVal(true);
  };

  const downloadFile = async (e, val) => {
    const grpEduId = window.localStorage.getItem('activedEducator');
    let response;
    try {
      if (val === 'downloadEdu') {
        // downloadApiUrl = `${BASE_URL}/group_educators/excel-export`;

        // PORT-GE-1: Excel Export of Group Educators

        response = await getGroupEducators();
      } else if (val === 'downloadSub') {
        // downloadApiUrl = `${BASE_URL}/group_educators/${grpEduId}/subjects/excel_export`;

        // PORT-GE-6: Excel Export - Group Educator Subjects

        response = await getGroupEducatorSubjects(grpEduId);
      }
      let filename = response.headers['content-disposition'].split('=')[1];
      var blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      saveAs(blob, filename);
      toast.success('file downloaded');

      // await Axios.get(downloadApiUrl, {
      //   ...Auth,
      //   responseType: "arraybuffer",
      // }).then((response) => {
      //   // console.log(response)
      //   let filename = response.headers["content-disposition"].split("=")[1];
      //   var blob = new Blob([response.data], {
      //     type: "application/octet-stream",
      //   });
      //   saveAs(blob, filename);
      //   toast.success("file downloaded");
      // });
    } catch (err) {
      // console.log(err.response, "error");
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      toast.error('No records');
    }
  };

  const uploadFile = async (e, val) => {
    const grpEduId = window.localStorage.getItem('activedEducator');
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);
    formdata.append('filetoupload', file);
    console.log(Auth.headers);
    try {
      if (val === 'uploadEdu') {
        // uploadApiUrl = `${BASE_URL}/group_educators/excel-import`;

        // PORT-GE-2: Excel Import of Group Educators

        await uploadGroupEducators(formdata);
      } else if (val === 'uploadSub') {
        // PORT-GE-7: Excel Import - Group Educator Subjects

        await uploadGroupEducatorSubjects(grpEduId, formdata);
        // uploadApiUrl = `${BASE_URL}/group_educators/${grpEduId}/subjects/excel_import`;
      }

      setLoading(false);
      toast.success('File Uploaded Successfully');
      fetchResults();
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      if (err) {
        setErrorUpload(true);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
        setMsg(err.response.data.message);
        setErrMsg(err.response.data.data);
      }
    }
    // Axios({
    //   url: uploadApiUrl,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //     "Content-Type": "multipart/form-data",
    //     Accept: "*",
    //     type: "formData",
    //     "Access-Control-Allow-Headers": "*",
    //   },
    //   data: formdata,
    // })
    //   .then((res) => {
    //     // console.log(res)
    //     setLoading(false);
    //     toast.success("File Uploaded Successfully");
    //     fetchResults();
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //     setLoading(false);
    //     if (err) {
    //       setErrorUpload(true);
    //       if (err.response && err.response.data) {
    //         console.log(err.response.data.message);
    //         toast.error(err.response.data.message);
    //       }
    //       setMsg(err.response.data.message);
    //       setErrMsg(err.response.data.data);
    //     }
    //   });
  };

  const ErrModal = () => {
    let errHead = ['Teacher Id', 'Teacher Name', 'Error'];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.stucle_teacher_id}</td>
                    <td>{elm.teacher_name}</td>
                    <td>{elm.result ? elm.result : elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  const addEducator = () => {
    return (
      <Dialogbox open={open} handleClose={handleClosePopup}>
        <Box className={styles.mainBox} component="div">
          <Box className={styles.closeIcon} component="div">
            <Box component="div" className={styles.persionalInfo}>
              {editVal ? `Edit Personal Info` : `Add Personal Info`}
            </Box>
            <Box component="div" className={styles.closeBtn} onClick={() => setOpen(!open)}>
              <img src={require('../../../../assets/icons/close.png')} alt="closeBtn" style={{ height: '16px', width: '16px' }} />
            </Box>
          </Box>
          <Box>
            <Box component="div" className={styles.form}>
              <Formik
                initialValues={initialValues || state}
                validationSchema={validationSchema}
                onSubmit={saveEducator}
                enableReinitialize
                // className={styles.formik}
              >
                {(formik) => {
                  return (
                    <Box className={styles.form_container} component="div">
                      <Form className={styles.form_box}>
                        <Box className={styles.form_box_fields} component="div">
                          <Box component="div" className={styles.form_box_fields_r}>
                            <label htmlFor="name" className={styles.labels}>
                              Name <span style={{ color: 'red' }}>*</span>
                            </label>
                            <Box component="div" className={styles.institute_name}>
                              <Field
                                type="text"
                                id="Name"
                                name="Name"
                                value={editVal ? state.edu_name : undefined}
                                onChange={(e) => {
                                  e.currentTarget.value = e.currentTarget.value.replace(/^\s+/, '');
                                  setState({
                                    ...state,
                                    edu_name: e.target.value,
                                  });
                                }}
                                required={true}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage name="Name" component={TextError}>
                                {(error) => <div className="error">{error}</div>}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box component="div" className={styles.form_box_fields_r}>
                            <label htmlFor="name" className={styles.labels}>
                              Date of birth <span style={{ color: 'red' }}>*</span>
                            </label>
                            <Box component="div" className={styles.institute_name}>
                              <Calander
                                id="DOB"
                                name="DOB"
                                type="date"
                                selected={state.edu_dob}
                                style={{ width: '50px' }}
                                onChange={(val) => {
                                  // console.log(val)
                                  DateOfBirth = val;
                                  setState({
                                    ...state,
                                    edu_dob: DateOfBirth,
                                  });
                                  // setCalander(true);
                                }}
                              />{' '}
                              <ErrorMessage name="DOB" component={TextError}>
                                {(error) => <div className="error">{error}</div>}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box component="div" className={styles.form_box_fields_r}>
                            <label htmlFor="name" className={styles.labels}>
                              Registred Mobile Number <span style={{ color: 'red' }}>*</span>
                            </label>
                            <Box component="div" className={styles.institute_name}>
                              <Field
                                type="Number"
                                id="reg_mob_no"
                                name="reg_mob_no"
                                required={true}
                                value={editVal ? state.edu_phone_number : undefined}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    edu_phone_number: e.target.value,
                                  });
                                }}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage name="reg_mob_no" component={TextError}>
                                {(error) => <div className="error">{error}</div>}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box component="div" className={styles.form_box_fields_r}>
                            <label htmlFor="name" className={styles.labels}>
                              Alternate Mobile Number
                            </label>
                            <Box component="div" className={styles.institute_name}>
                              <Field
                                type="Number"
                                id="alt_mob"
                                name="alt_mob"
                                value={editVal ? state.edu_alt_phone_number : undefined}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    edu_alt_phone_number: e.target.value,
                                  });
                                }}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage name="alt_mob" component={TextError}>
                                {(error) => <div className="error">{error}</div>}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box component="div" className={styles.form_box_fields_r}>
                            <label htmlFor="name" className={styles.labels}>
                              City
                            </label>
                            <Box component="div" className={styles.institute_name}>
                              <Field
                                type="text"
                                id="city"
                                name="city"
                                value={editVal ? state.edu_city : undefined}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    edu_city: e.target.value,
                                  });
                                }}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage name="city" component={TextError}>
                                {(error) => <div className="error">{error}</div>}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box component="div" className={styles.form_box_fields_r}>
                            <label htmlFor="name" className={styles.labels}>
                              Pincode
                            </label>
                            <Box component="div" className={styles.institute_name}>
                              <Field
                                type="number"
                                id="pincode"
                                name="pincode"
                                value={editVal ? state.edu_pincode : undefined}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    edu_pincode: e.target.value,
                                  });
                                }}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage name="pincode" component={TextError}>
                                {(error) => <div className="error">{error}</div>}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box component="div" className={styles.form_box_fields_r}>
                            <label htmlFor="name" className={styles.labels}>
                              Internet Service Provider
                            </label>
                            <Box component="div" className={styles.institute_name}>
                              <Field
                                type="text"
                                id="isp"
                                name="isp"
                                className={styles.institute_name_f}
                                value={editVal ? state.edu_isp : undefined}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    int_service_provider: e.target.value,
                                    edu_isp: e.target.value,
                                  });
                                }}
                              />
                              <ErrorMessage name="int_service_provider" component={TextError}>
                                {(error) => <div className="error">{error}</div>}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box className={styles.buttonsss}>
                            <div>
                              {editVal ? (
                                <div className={styles.dltBtn} onClick={deleteEducator}>
                                  <img
                                    src={require('../../../../assets/icons/DeleteBox.png')}
                                    alt="deletebox"
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                              ) : null}
                            </div>
                            <div className={styles.scBtn}>
                              <ButtonComponent className={styles.cancelBtn} onClick={handleClose} type="reset">
                                Cancel
                              </ButtonComponent>
                              <ButtonComponent
                                className={styles.saveBtn}
                                type="submit"
                                onClick={(e) => {
                                  let ispVal = document.getElementById('isp').value;
                                  let NameVal = document.getElementById('Name').value;
                                  // let DOBVal = document.getElementById("DOB").value;
                                  let reg_mob_noVal = document.getElementById('reg_mob_no').value;
                                  let alt_mobVal = document.getElementById('alt_mob').value;
                                  let cityVal = document.getElementById('city').value;
                                  let pincodeVal = document.getElementById('pincode').value;
                                  formik.setFieldValue('isp', ispVal);
                                  formik.setFieldValue('Name', NameVal);
                                  formik.setFieldValue('reg_mob_no', reg_mob_noVal);
                                  formik.setFieldValue('alt_mob', alt_mobVal);
                                  formik.setFieldValue('city', cityVal);
                                  formik.setFieldValue('pincode', pincodeVal);
                                }}
                                disabled={!formik.isValid || formik.isSubmitting}
                              >
                                Save
                              </ButtonComponent>
                            </div>
                          </Box>
                        </Box>
                      </Form>
                    </Box>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Dialogbox>
    );
  };

  return (
    <Box component="div" className={styles.main_Container}>
      <Box component="div" className={styles.top}>
        <Box className={styles.buttons} component="div">
          <Box component="div" className={styles.buttons_add} onClick={handleEducatorAdd}>
            Add Group Educator
          </Box>
          <Box component="div" className={styles.buttons_format} onClick={(e) => downloadFile(e, 'downloadEdu')}>
            <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ paddingRight: '10px' }} />
            Download Educator's List
          </Box>
          <Box component="div" className={styles.buttons_data}>
            <div className={styles.upload} onClick={handleClick}>
              <InputBase
                type="file"
                name="filetouploadEducatorList"
                id="filetouploadEducatorList"
                onChange={(e) => {
                  uploadFile(e, 'uploadEdu');
                  e.target.value = null;
                }}
                multiple
                accept="*"
                style={{ display: 'none' }}
                ref={uploadEducatorList}
              />
              <label
                htmlFor="filetouploadEducatorList"
                style={{
                  borderRadius: '10px',
                  width: '100px',
                  height: '32px',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ marginRight: '10px' }} />
                Upload Educator's List
              </label>
            </div>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <div className={styles.loader}>
          <ReactBootStrap.Spinner animation="border" variant="success" /> &nbsp;
          <span className={styles.loaderTxt}>Loading...</span>
        </div>
      ) : null}
      <Box component="div" className={styles.body}>
        <Box component="div" className={styles.educatorList}>
          <Box component="div" className={styles.educator}>
            <Box component="div" className={styles.educator_txt}>
              Group Educator List
            </Box>
            <Box component="div" className={styles.educators}>
              {state.educatorList.length === 0 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  No Data Found
                  <span role="img" aria-label="jsx-a11y/accessible-emoji">
                    😔
                  </span>
                </div>
              ) : (
                state.educatorList.map((elm, key) => (
                  <div
                    key={key}
                    className={styles.lists}
                    onClick={() => {
                      window.localStorage.setItem('activedEducator', elm.id);
                      window.localStorage.setItem('detailInfo_id', elm.id);
                      getDetail(elm.id);
                    }}
                  >
                    <div className={styles.image}>
                      <Avatar src={dum} alt="img" style={{ height: '16px', width: '16px' }} />
                    </div>
                    <div
                      className={elm.id === Number(window.localStorage.getItem('detailInfo_id')) ? styles.activeEdu : styles.name}
                    >
                      {elm.educator_name}
                      {elm.id === Number(window.localStorage.getItem('detailInfo_id')) ? <span>{'>'}</span> : ''}
                    </div>
                  </div>
                ))
              )}
            </Box>
          </Box>
        </Box>
        <Box component="div" className={styles.Form_fields}>
          <Box component="div" className={styles.form}>
            <Box className={styles.edit} component="div">
              <Box component="div" className={styles.persional_info}>
                Personal Info
              </Box>
              <Box className={styles.grpInst}>
                <label>Select Group Institution</label>
                <Box
                  component="select"
                  className={styles.options}
                  onChange={(e) => {
                    changedBoard(e, 'grpInst');
                  }}
                >
                  <option>{'Group Institution'}</option>
                  {state.dropGrpInst.map((el, key) => (
                    <option
                      value={el.group_institution_id}
                      selected={dropGrpInst === el.group_institution_id ? true : ''}
                      key={key}
                    >
                      {el.group_institution_path}
                    </option>
                  ))}
                </Box>
              </Box>
              <Box onClick={handleEditEducator} component="div">
                {state.educatorList.length === 0 ? (
                  <div></div>
                ) : (
                  <img src={require('../../../../assets/icons/Edit1.png')} alt="edit educator" style={{ cursor: 'pointer' }} />
                )}
              </Box>
            </Box>
            <Box className={styles.form_containerr} component="div" style={{ display: 'flex' }}>
              <div className={styles.previewImg}>
                <Box component="div" className={styles.form_box_fields_r}>
                  {/* <label htmlFor="name" className={styles.labels}>
                    Add Photo
                  </label> */}
                  <Formik initialValues={initialValues} enableReinitialize>
                    {(formik) => {
                      return (
                        <Box component="div">
                          <Field
                            type="file"
                            id="imagepath1"
                            name="imagepath1"
                            className={styles.addPhoto}
                            onChange={(e) => handleChange(e, 'imagepath1')}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={state.imgPrev1 !== null ? state.imgPrev1 : addPhoto}
                            alt=""
                            style={{
                              width: '60px',
                              height: 'auto',
                              borderRadius: '6px',
                            }}
                          />
                        </Box>
                      );
                    }}
                  </Formik>
                </Box>
              </div>
              <Box className={styles.form_box} component="div">
                <Box className={styles.form_box_fields} component="div">
                  <div style={{ display: 'flex' }}>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="name" className={styles.labels}>
                        Name
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        {state.edu_name && state.edu_name}
                      </Box>
                    </Box>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="name" className={styles.labels}>
                        Date of birth
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        {state.educatorList.length === 0 ? <div></div> : <div>{Moment(state.edu_dob).format('DD/MM/YYYY')}</div>}
                      </Box>
                    </Box>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="name" className={styles.labels}>
                        Registred Mobile Number
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        {state.edu_phone_number}
                      </Box>
                    </Box>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="name" className={styles.labels}>
                        Alternate Mobile Number
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        {state.edu_alt_phone_number}
                      </Box>
                    </Box>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="name" className={styles.labels}>
                        City
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        {state.edu_city}
                      </Box>
                    </Box>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="name" className={styles.labels}>
                        Pincode
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        {state.edu_pincode}
                      </Box>
                    </Box>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="name" className={styles.labels}>
                        Internet Service Provider
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        {state.edu_isp}
                      </Box>
                    </Box>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="name" className={styles.labels}>
                        Email
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        {state.edu_email}
                      </Box>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box component="div" className={styles.subject_table}>
            <div className={styles.subText}>
              <div className={styles.subTxt}>Subject</div>
              <div
                style={{
                  display: 'flex',
                  flexDireaction: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {/* <div className={styles.addBtn} onClick={handleClickOpen}>
                  Add
                </div> */}
                <Box component="div" className={styles.buttons_format} onClick={(e) => downloadFile(e, 'downloadSub')}>
                  <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ paddingRight: '10px' }} />
                  Download Subject
                </Box>
                <Box component="div" className={styles.buttons_data}>
                  <div className={styles.upload}>
                    <InputBase
                      type="file"
                      name="filetouploadEducatorSubject"
                      id="filetouploadEducatorSubject"
                      onChange={(e) => {
                        uploadFile(e, 'uploadSub');
                        e.target.value = null;
                      }}
                      multiple
                      accept="*"
                      style={{ display: 'none' }}
                    />
                    <label
                      htmlFor="filetouploadEducatorSubject"
                      style={{
                        borderRadius: '10px',
                        width: '98px',
                        height: '32px',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ marginRight: '10px' }} />
                      Upload Subject
                    </label>
                  </div>
                </Box>
              </div>
            </div>
            <div className={styles.table_box}>
              <div className={styles.schoolList_Table}>
                <div className={styles.schoolList_Table_head}>
                  {subject_head.map((elm, key) => (
                    <div key={key} className={styles.schoolList_Table_head_data}>
                      {elm}
                    </div>
                  ))}
                </div>
                <div className={styles.schoolList_Table_body}>
                  {state.subject_body.map((elm, key) => (
                    <div key={key} className={styles.schoolList_Table_body_row}>
                      <div className={styles.schoolList_Table_body_row_td2}>{elm.board_name}</div>
                      <div className={styles.schoolList_Table_body_row_td3}>{elm.course_name}</div>
                      <div className={styles.schoolList_Table_body_row_td3}>{elm.subject_name}</div>
                      <div className={styles.schoolList_Table_body_row_td3}>{elm.description}</div>
                      <div
                        className={styles.schoolList_Table_body_row_td3}
                        // onClick={(e) =>
                        //   deleteSubject(e,elm.educator_subject_id)
                        // }
                      >
                        <Icon
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <img
                            src={require('../../../../assets/icons/DeleteBox.png')}
                            alt="deletebox"
                            style={{
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px',
                            }}
                          />
                        </Icon>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
          <Box component="div" className={styles.study_circle}>
            <div className={styles.subTxt}>Group Study Circle ( Go to Group Study Circle tab to modify data )</div>
            <div className={styles.table_box}>
              <div className={styles.table_box}>
                <div className={styles.schoolList_Table}>
                  <div className={styles.schoolList_Table_head}>
                    {study_circle_head.map((elm, key) => (
                      <div key={key} className={styles.schoolList_Table_head_data}>
                        {elm}
                      </div>
                    ))}
                  </div>
                  <div className={styles.schoolList_Table_body}>
                    {state.study_circle_body &&
                      state.study_circle_body.map((elm, key) => (
                        <div key={key} className={styles.schoolList_Table_body_row}>
                          <div className={styles.schoolList_Table_body_row_td2}>{elm.group_study_circle_name}</div>
                          <div className={styles.schoolList_Table_body_row_td3}>{elm.subject_name}</div>
                          <div className={styles.schoolList_Table_body_row_td3}>{'-'}</div>
                          <div className={styles.schoolList_Table_body_row_td3}>{elm.group_educator_role}</div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
      <ModalComponent show={state.showHide} onClick={handleClose} className="addSubModal">
        <Box className={styles.main_box} component="div">
          <Formik initialValues={initialAddVal} validationSchema={validationAdd} onSubmit={addSubject} enableReinitialize>
            {(formik) => {
              // console.log(formik, "formik get values");
              return (
                <Box component="div" style={{ width: '100%', height: 'auto' }}>
                  <Form className={styles.modal_form}>
                    <label htmlFor="board">Board</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="board"
                        id="board"
                        value={undefined}
                        className={styles.d_options}
                        onChange={(e) => changedBoard(e, 'board')}
                      >
                        <option value="-1">{'Board'}</option>
                        {state.drop_Board.map((el, key) => (
                          <option value={el.board_id} key={key}>
                            {el.display_name}
                          </option>
                        ))}
                      </Field>
                    </Box>
                    <div className={styles.hierarchy}>Filter with hierarchy</div>
                    <label htmlFor="course">Course</label>
                    <Box className={styles.main_box_2} component="div">
                      <Field
                        as="select"
                        name="course"
                        id="course"
                        value={undefined}
                        className={styles.d_options}
                        onChange={(e) => changedBoard(e, 'course')}
                      >
                        <option value="-1">{'Course'}</option>
                        {state.drop_course.map((el, key) => (
                          <option value={el.course_id} key={key}>
                            {el.display_name}
                          </option>
                        ))}
                      </Field>
                    </Box>
                    <label htmlFor="subject">Subject</label>

                    <Box className={styles.main_box_3} component="div">
                      <Field
                        as="select"
                        name="subject"
                        id="subject"
                        value={undefined}
                        // value={editVal ? selectedBoard() : undefined}
                        // onChange={(e)=>setState({...state,subject:e.terget.value})}
                        onChange={(e) => changedBoard(e, 'subject')}
                        className={styles.d_options}
                      >
                        <option value="-1">{'Subject'}</option>
                        {state.drop_Subject.map((el, key) => (
                          <option value={el.subject_id} key={key}>
                            {el.display_name}
                          </option>
                        ))}
                      </Field>
                    </Box>
                    <label htmlFor="board">Description</label>
                    <Box className={styles.main_box_1} component="div" style={{ height: '60px' }}>
                      <Field
                        name="desc"
                        // placeholder="Topic Display Name"
                        component="textarea"
                        className={styles.B_Field}
                        id="desc"
                        value={state.desc}
                        onChange={(e) => {
                          setState({
                            ...state,
                            desc: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box className={styles.buttonss}>
                      <ButtonComponent className={styles.cancelBtn} onClick={handleClose} type="reset">
                        Cancel
                      </ButtonComponent>
                      <ButtonComponent
                        className={styles.saveBtn}
                        type="submit"
                        onClick={(e) => {
                          let desc_val = document.getElementById('desc').value;
                          formik.setFieldValue('desc', desc_val);
                        }}
                      >
                        Save
                      </ButtonComponent>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </ModalComponent>
      {addEd ? addEducator() : null}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {errorUpload ? <ErrModal /> : null}
    </Box>
  );
};

export default Educator;
