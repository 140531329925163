import React, { useState, useCallback, useEffect } from 'react';
import TableRow from './CourseTableRow';
// import styled from "styled-components";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
// import { ITEMS } from "./data";
import arrayMove from './CourseArrayMove';
import './courses.css';
import { toast } from 'react-toastify';
// import { saveAs } from "file-saver";
import { Box } from '@material-ui/core';
import styles from '../SubjectHierarchy/TopicStyle.module.css';
// import { Formik, Form, Field } from "formik";
import ReactLoading from 'react-loading';
import {
  getCourseListForBoardHierarchy,
  createBoardHierarchyCourseEntry,
  updateBoardHierarchyCourseRow,
  deleteBoardHierarchyCourseRow,
  reorderBoardHierarchyCourse,
} from '../../../../api/boardHierarchy';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';

var tempObject;
var tempItems;
// var clickedRow;
// var savedCount = 0;
var school_id = -1;
var board_id = -1;
var hierarchy1;
var hierarchy2;
var hierarchy3;
var hierarchy4;
var hierarchy5;
var tempId;
var rowData;
var deleteData;
var tempTableData = [];
// var tpArray;
// var courseArray = [];
// var filterArray = [];
// var finalArray = [];
var prevCourseId;
var nextCourseId;
var moveData;
var newId;

const SortableCont = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props) => <TableRow {...props} />);

const MyTable = () => {
  const [items, setItems] = useState([]);
  const [state, setState] = useState({
    boardOptions: [],
  });
  const [loading, setLoading] = useState(false);
  const [move, setMove] = useState(false);

  // console.log(items);
  school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 1);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  async function fetchTableData() {
    // courseArray = [];
    // filterArray = [];
    // finalArray = [];
    try {
      // PORT-BH-9: Retrieve the Board Hierarchy Course List
      const getTableData = await getCourseListForBoardHierarchy(school_id, board_id, Auth);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/hierarchy/courses`,
      //   Auth
      // );
      console.log(getTableData.data.data);
      // tpArray = getTableData.data.data;
      // tpArray.map((row) => {
      //   if (!courseArray.includes(row.course_name)) {
      //     courseArray.push(row.course_name);
      //   }
      // });
      // filterArray = courseArray.map((element) => {
      //   return tpArray.findIndex((object) => {
      //     return object.course_name === element;
      //   });
      // });
      // console.log(courseArray, filterArray);
      // console.log(tpArray);
      // finalArray = filterArray.map((num) => {
      //   return tpArray[num];
      // });
      // console.log(finalArray);
      // setItems(finalArray);
      if (getTableData.data.data.length === 0) {
        tempTableData = [
          {
            course_id: 100000,
            course_name: '',
            course_seq_no: '',
            hierarchy_1_value: '',
            hierarchy_2_value: null,
            hierarchy_3_value: null,
            hierarchy_4_value: null,
            hierarchy_5_value: null,
          },
        ];
        setItems(tempTableData);
      } else {
        setItems(getTableData.data.data);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
        console.log(error.response.data.message);
      }
      setLoading(false);
    }
  }

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    console.log(oldIndex, newIndex);
    setLoading(true);
    setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
    newId = newIndex;
    setMove(true);
  }, []);

  async function handleMove() {
    console.log(items);
    prevCourseId = items[newId - 1] ? items[newId - 1].course_id : -1;
    nextCourseId = items[newId + 1] ? items[newId + 1].course_id : -1;
    console.log(prevCourseId, nextCourseId);
    moveData = {
      course_id: items[newId].course_id,
      prev_course_id: prevCourseId,
      next_course_id: nextCourseId,
    };
    try {
      // PORT-BH-13: Reorder a Board Hierarchy Course
      const moveRow = await reorderBoardHierarchyCourse(school_id, board_id, moveData, Auth);
      // await Axios.put(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/hierarchy/courses/reorder`,
      //   moveData,
      //   Auth
      // );
      console.log(moveRow);
      setMove(false);
      toast.success('Moved Successfully');
      fetchTableData();
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setMove(false);
      fetchTableData();
    }
  }

  useEffect(() => {
    if (move === true) {
      handleMove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [move]);

  function handlechange(event, first, id) {
    var text = event.target.value;
    console.log(text, first, id);
    // if (savedCount === 0) {
    //   clickedRow = items[id];
    //   savedCount = savedCount + 1;
    // }
    tempObject = items;
    tempObject = tempObject.map((item, i) => {
      if (i === id) {
        console.log(123);
        return { ...item, [first]: text, notSaved: true };
      } else {
        return item;
      }
    });
    console.log(tempObject);
    setItems(tempObject);
  }

  async function handleSave(id, rowDetails) {
    setLoading(true);
    console.log(id);
    console.log(rowDetails);
    // if (
    //   rowDetails.hierarchy_1_value !== "" &&
    //   rowDetails.hierarchy_1_value !== null &&
    //   rowDetails.hierarchy_2_value !== "" &&
    //   rowDetails.hierarchy_2_value !== null &&
    //   rowDetails.hierarchy_3_value !== "" &&
    //   rowDetails.hierarchy_3_value !== null &&
    //   rowDetails.hierarchy_4_value !== "" &&
    //   rowDetails.hierarchy_4_value !== null &&
    //   rowDetails.hierarchy_5_value !== "" &&
    //   rowDetails.hierarchy_5_value !== null
    // ) {
    tempObject = items;
    tempObject = tempObject.map((item, i) => {
      if (i === id) {
        console.log(123);
        return { ...item, notSaved: false };
      } else {
        return item;
      }
    });
    setItems(tempObject);
    hierarchy1 = rowDetails.hierarchy_1_value ? rowDetails.hierarchy_1_value : null;
    hierarchy2 = rowDetails.hierarchy_2_value ? rowDetails.hierarchy_2_value : null;
    hierarchy3 = rowDetails.hierarchy_3_value ? rowDetails.hierarchy_3_value : null;
    hierarchy4 = rowDetails.hierarchy_4_value ? rowDetails.hierarchy_4_value : null;
    hierarchy5 = rowDetails.hierarchy_5_value ? rowDetails.hierarchy_5_value : null;
    prevCourseId = items[id - 1] ? items[id - 1].course_id : -1;
    nextCourseId = items[id + 1] ? items[id + 1].course_id : -1;

    if (rowDetails.course_id >= 100000) {
      rowData = {
        prev_course_id: prevCourseId,
        next_course_id: nextCourseId,
        hierarchy_1_value: hierarchy1,
        hierarchy_2_value: hierarchy2,
        hierarchy_3_value: hierarchy3,
        hierarchy_4_value: hierarchy4,
        hierarchy_5_value: hierarchy5,
        course_name: rowDetails.course_name,
      };
      console.log(rowData, 456);
      try {
        // PORT-BH-10: Create a Board Hierarchy Course Entry
        const createBoard = await createBoardHierarchyCourseEntry(school_id, board_id, rowData, Auth);
        // await Axios.post(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/hierarchy/courses`,
        //   rowData,
        //   Auth
        // );
        console.log(createBoard.data.data);
        toast.success('Successfuly created new course');
        fetchTableData();
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        fetchTableData();
      }
    } else {
      rowData = {
        hierarchy_1_value: hierarchy1,
        hierarchy_2_value: hierarchy2,
        hierarchy_3_value: hierarchy3,
        hierarchy_4_value: hierarchy4,
        hierarchy_5_value: hierarchy5,
        course_id: rowDetails.course_id,
        course_name: rowDetails.course_name,
      };
      console.log(rowData, 789);
      try {
        // PORT-BH-11: Update a Board Hierarchy Course Row
        const createBoard = await updateBoardHierarchyCourseRow(school_id, board_id, rowData, Auth);
        // await Axios.put(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/hierarchy/courses`,
        //   rowData,
        //   Auth
        // );
        console.log(createBoard.data.data);
        toast.success('Successfuly updated');
        fetchTableData();
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        fetchTableData();
      }
    }
    // }
  }

  async function handleDelete(id, rowDetails) {
    console.log(id);
    tempItems = items;
    tempItems.splice(id, 1);
    setItems([...tempItems]);
    deleteData = {
      course_id: rowDetails.course_id,
    };
    console.log(deleteData);
    try {
      // PORT-BH-12: Delete a Board Hierarchy Course Row
      const deleteRow = await deleteBoardHierarchyCourseRow(school_id, board_id, deleteData, Auth);
      // await Axios.delete(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/hierarchy/courses`,
      //   {
      //     data: deleteData,
      //     headers: Auth.headers,
      //   }
      // );
      console.log(deleteRow.data.data);
      // toast.success(deleteRow.data.data.o_result);
      toast.success('Successfully deleted course');
      fetchTableData();
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      fetchTableData();
    }
  }

  async function handleAddRow(id, rowDetails) {
    tempItems = items;
    tempId = +rowDetails.course_id + 100000;
    tempItems.splice(id + 1, 0, {
      // ...rowDetails,
      course_name: '',
      course_seq_no: null,
      hierarchy_1_value: '',
      hierarchy_2_value: null,
      hierarchy_3_value: null,
      hierarchy_4_value: null,
      hierarchy_5_value: null,
      notSaved: true,
      course_id: tempId,
    });
    console.log(tempItems);
    setItems([...tempItems]);
  }

  function handleCancel(id) {
    fetchTableData();
    // if (savedCount !== 0) {
    //   tempItems = items;
    //   tempItems.splice(id, 1);
    //   console.log(clickedRow);
    //   tempItems.splice(id, 0, { ...clickedRow, notSaved: false });
    //   setItems([...tempItems]);
    //   savedCount = 0;
    // } else if (savedCount === 0) {
    //   tempItems = items;
    //   tempItems.splice(id, 1);
    //   setItems([...tempItems]);
    // }
  }

  useEffect(() => {
    setLoading(true);
    const fetchBoard = async () => {
      console.log(456);
      try {
        // PORT-IB-5: Retrieve list of boards of an institution

        const getboard = await getBoardsByNameInInstitution(school_id);
        // await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/boards`,
        //   Auth
        // );
        setState({ ...state, boardOptions: getboard.data.data });
        console.log(getboard.data.data);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setLoading(false);
      }
    };

    fetchBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changedBoard = async (e, selectedvalue) => {
    console.log('123');
    setLoading(true);
    if (+e.target.value === -1) {
      board_id = e.target.value;
      setItems([]);
      setLoading(false);
    } else {
      if (selectedvalue === 'board') {
        board_id = e.target.value;
        fetchTableData();
      }
    }
  };

  return (
    <div>
      {loading ? <ReactLoading type="bars" color="#4fb64b" className="startUploadLoadSpin" /> : null}
      <div className={styles.mainTop_box}>
        <Box className={styles.top_box} component="div">
          <Box component="div" className={styles.top_box_l}>
            <div>
              <label>Board</label>
              <Box
                component="select"
                className={styles.options}
                onChange={(e) => {
                  changedBoard(e, 'board');
                }}
              >
                <option value="-1">{'Board'}</option>
                {state.boardOptions.map((el, key) => (
                  <option value={el.board_id} key={key}>
                    {el.display_name}
                  </option>
                ))}
              </Box>
            </div>
          </Box>
          <Box component="div" className={styles.buttons}></Box>
        </Box>
      </div>
      {items !== [] ? (
        <table className="boardTable">
          <thead>
            <tr className="theadDragTable">
              <th>Move</th>
              <th>Course</th>
              <th>hierarchy 1</th>
              <th>hierarchy 2</th>
              <th>hierarchy 3</th>
              <th>hierarchy 4</th>
              <th>hierarchy 5</th>
              <th>Action</th>
            </tr>
          </thead>
          <SortableCont
            onSortEnd={onSortEnd}
            axis="y"
            lockAxis="y"
            lockToContainerEdges={true}
            lockOffset={['30%', '50%']}
            helperClass="helperContainerClass"
            useDragHandle={true}
          >
            {items.map((value, index) => (
              <SortableItem
                key={`item-${index}`}
                handleChange={handlechange}
                index={index}
                id={index}
                rowDetails={value}
                handleSave={handleSave}
                handleDelete={handleDelete}
                handleAddRow={handleAddRow}
                handleCancel={handleCancel}
                notSaved={value.notSaved ? true : false}
                first={value.course_name}
                second={value.hierarchy_1_value}
                third={value.hierarchy_2_value}
                fourth={value.hierarchy_3_value}
                fifth={value.hierarchy_4_value}
                sixth={value.hierarchy_5_value}
              />
            ))}
          </SortableCont>
        </table>
      ) : null}
      {/* </MyTableWrapper> */}
      {/* <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </div>
  );
};

export default MyTable;
