import React, { useState } from 'react';
import styles from './resetpasswordStyle.module.css';
import { Divider, Box } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TextError from '../../variables/errors/Text_error';
import 'react-toastify/dist/ReactToastify.css';
import { user_email_website } from '../../config/Api';
import ReactLoading from 'react-loading';
import { sendResetPasswordMail } from '../../api/login';
const initialValues = {
  email: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email('invalid email').required('email is Required'),
});

const ResetPassword = () => {
  const [pass, setPass] = useState(true);
  const [loading, setLoading] = useState(false);
  const [notEligible, setNotEligible] = useState(false);
  const onSubmit = async (values) => {
    setLoading(true);
    setNotEligible(false);
    let email;
    email = values.email;
    if (email.includes(user_email_website)) {
      console.log('This email is not eligible for password change, Please contact administrator');
      setNotEligible(true);
    } else {
      // PORT-LOGIN-1: "Set password" mailer
      try {
        const response = await sendResetPasswordMail(email);

        console.log(response, 'resssssssssssssss');
        toast.success(response?.data?.message);
        setLoading(false);
        if (response?.data?.status === true) {
          setTimeout(() => {
            // return window.location = "/"
            setPass(false);
          }, 3000);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      {loading ? <ReactLoading type="spin" color="#4fb64b" className={styles.startUploadLoadSpin} /> : null}
      <Box className={styles.header} p={1}>
        <div className={styles.header_body}>
          <img src={require('../../assets/images/stucle.png')} alt="header-logo" className={styles.image} />
        </div>
      </Box>
      <Divider style={{ background: '#A6C8A6', height: '1px', width: '100%' }} />
      <main className={styles.body}>
        <div className={styles.left}>
          <Box component="div" className={styles.reset_box} mb={4}>
            <Box component="p" className={styles.reset} mb={0}>
              Reset Password
            </Box>
            <p className={styles.txt}>
              Please reset the password for security reasons or if you cannot remember it. We will send you a link to your
              registered email id. Click on the link, reset the password and you are ready to go again!
            </p>
            {pass ? (
              <div className={styles.form_text_div}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                  className={styles.formik}
                >
                  {(formik) => {
                    return (
                      <div className={styles.form_div}>
                        <Form className={styles.form}>
                          <Box className={styles.username} component="div">
                            <Field
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Please enter your email id here"
                              className={styles.email_field}
                            />
                          </Box>
                          <ErrorMessage name="email" component={TextError}>
                            {(error) => <div className="error">{error}</div>}
                          </ErrorMessage>
                          <button
                            type="submit"
                            className={!formik.isValid || formik.isSubmitting ? styles.disabled : styles.Button}
                            disabled={!formik.isValid || formik.isSubmitting}
                          >
                            <div>Submit</div>
                          </button>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
                {notEligible ? (
                  <p className={styles.textNotEligible}>
                    This email is not eligible for password change, Please contact administrator.
                  </p>
                ) : null}
              </div>
            ) : (
              <div className={styles.form_div}>
                <div className={styles.mailSent}>
                  <img src={require('../../assets/images/tick-mark.png')} alt="tick-mark" />
                  Mail Sent!
                </div>
              </div>
            )}
          </Box>
        </div>
        <div className={styles.right}></div>
      </main>
      <footer className={styles.footer}>
        &copy; {new Date().getFullYear()} Apps'n'Devices Technologies Pvt Ltd. All rights reserved.
      </footer>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </div>
  );
};

export default ResetPassword;
