import https from './init/https';

// PORT-EDU-1: Download educator list as excel
export const downloadEducatorsList = async (school_id) => {
  return await https.get(`/institutions/${school_id}/educators/excel-export`, {
    responseType: 'arraybuffer',
  });
};

// PORT-EDU-2: Add / update /delete educators
export const addUpdateDeleteEducator = async (school_id, data) => {
  return await https.post(`/institutions/${school_id}/educators/excel-import`, data);
};

// PORT-EDU-3: Retrieve list of educators for an institution
export const getEducatorsBySchoolId = async (school_id) => {
  return await https.get(`/institutions/${school_id}/educators`);
};

//PORT-EDU-4: Retrieve details of selected educator
export const getSelectedEducatorDetails = async (institution_id, educator_id) => {
  return await https.get(`/institutions/${institution_id}/educators/${educator_id}/detailed-info`);
};

// PORT-EDU-5: Retrieve the list of subjects taught by the educator
export const getSubjectsByEducatorID = async (institution_id, educator_id) => {
  return await https.get(`/institutions/${institution_id}/educators/${educator_id}/subjects`);
};

// PORT-EDU-6: Assign a new Subject to an Educator
export const assignNewSubjectToEducator = async (institution_id, educator_id, subject_id, data) => {
  return await https.post(`/institutions/${institution_id}/educators/${educator_id}/subjects/${subject_id}`, data);
};

// PORT-EDU-8: Disassoicate a Subject from an Educator
export const removeSubjectFromEducator = async (institution_id, educator_id, subject_id) => {
  return await https.delete(`/institutions/${institution_id}/educators/${educator_id}/subjects/${subject_id}`);
};

// PORT-EDU-10: Retrieve the study circle allocations of an educator
export const getStudyCircleAllocationsByEducatorID = async (institution_id, educator_id) => {
  return await https.get(`/institutions/${institution_id}/educators/${educator_id}/study_circles`);
};
