/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from 'react';
import { Box, InputBase } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { Pagination } from '@material-ui/lab';
import { toast, ToastContainer } from 'react-toastify';
import { saveAs } from 'file-saver';
import { fade, makeStyles } from '@material-ui/core/styles';
import styles from './StudentStyle.module.css';
import { Dialogbox } from '../../../../components/index';
import * as ReactBootStrap from 'react-bootstrap';
import ReactLoading from 'react-loading';
import './student.css';
import userIcon from '../../../../assets/images/user.png';
import configuration from '../../../../config/config';
import s3 from '../../../../config/DigitalOcean';
import {
  getInstitutionDefinedGroup,
  downloadStudentListExcel,
  uploadStudentsToSIS,
  getStudentCountBySearchConditions,
  getStudentRecords,
  addProfilePictureForStudent,
  bulkUploadImagesForStudents,
} from '../../../../api/student';

var default_page = 1;
var selectedGroup;
var searched;
let IDG_id = -1;
var searchString = '';
let pageNo = 10;
// let rows = 10;
var pageNum = 0;
var profilePicPath = '';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const Student = () => {
  const classes = useStyles();
  const [errorUpload, setErrorUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [errMsg, setErrMsg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    t_head: [
      'Student',
      'Institution Student ID',
      'Institution Defined Group',
      'Registered Mobile no.',
      'Alternate Mobile Number',
      'DOB',
      'City',
      // "Pincode",
      // "Internet Service Provider",
    ],
    t_body: [],
    optInst: [],
    pagecount: null,
    pageNo: 10,
  });
  // let pagecount = null;

  window.localStorage.setItem('tabIndex', 6);
  const school_id = window.localStorage.getItem('school_id');
  // const token = window.localStorage.getItem("token");
  const profilePicFolder = process.env.REACT_APP_PROFILE_PICTURE_PATH;
  // const Auth = {
  //   headers: {
  //     Authorization: "Bearer " + token,
  //   },
  // };

  const fetchResult = async () => {
    try {
      const getInstList = await getInstitutionDefinedGroup(school_id);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/students/inst_defined_groups`,
      //   Auth
      // );

      const getTableData = await getStudentRecords(school_id, IDG_id, '', pageNo, pageNum);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/students/${IDG_id}/search?search_text=&limit=${pageNo}&offset=${pageNum}`,
      //   Auth
      // );
      const getCount = await getStudentCountBySearchConditions(school_id, IDG_id, '');
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/students/${IDG_id}/search/record-count?search_text`,
      //   Auth
      // );
      let count = getCount.data.data[0].count;
      console.log(pageNo);
      let extraPage = count % pageNo === 0 ? 0 : 1;
      setIsLoading(false);
      console.log(getTableData.data.data);
      // pagecount= Math.floor(count / pageNo + extraPage);
      setState((prevState) => ({
        ...prevState,
        t_body: getTableData.data.data,
        optInst: getInstList.data.data,
        pagecount: Math.floor(count / pageNo + extraPage),
      }));
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    pageNo = window.localStorage.getItem('show') ? window.localStorage.getItem('show') : 10;
    // rows = pageNo;
    setState((prevState) => {
      return { ...prevState, pageNo: pageNo };
    });
    fetchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pagechange = (e, value) => {
    default_page = value;
    changedBoard(value, 'pagenumber');
  };

  const changedBoard = async (e, selectedvalue) => {
    if (selectedvalue === 'selectedgroup') {
      selectedGroup = e.target.value;
      pageNum = 0;
      default_page = 1;
      IDG_id = e.target.value;
      if (selectedGroup === 'Institution Defined Group') {
        // isd = "";
        IDG_id = -1;
      }
    } else if (selectedvalue === 'selectedsearch') {
      searched = e.target.value;
      searchString = searched;
      pageNum = 0;
      default_page = 1;
      if (searched === '') {
        searchString = '';
        pageNum = 0;
        default_page = 1;
      }
    } else if (selectedvalue === 'pagenumber') {
      if (e === 1) {
        pageNum = 0;
      } else {
        pageNum = e;
      }
    } else if (selectedvalue === 'showrow') {
      // rows = e.target.value;
      pageNo = e.target.value;
      window.localStorage.setItem('show', pageNo);
      // pageNo = rows;
      IDG_id = -1;
      setState((prevState) => {
        return { ...prevState, pageNo: pageNo };
      });
      pageNum = 0;
      default_page = 1;
    }

    try {
      const getTableData = await getStudentRecords(school_id, IDG_id, searchString, pageNo, pageNum);
      // const getTableData = await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/students/${IDG_id}/search?search_text=${searchString}&limit=${pageNo}&offset=${pageNum}`,
      //   Auth
      // );
      const getCount = await getStudentCountBySearchConditions(school_id, IDG_id, searchString);
      // const getCount = await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/students/${IDG_id}/search/record-count?search_text=${searchString}`,
      //   Auth
      // );
      let count = getCount.data.data[0].count;
      console.log(pageNo);
      let extraPage = count % pageNo === 0 ? 0 : 1;
      // pagecount= Math.floor(count / pageNo + extraPage);
      setState((prevState) => ({
        ...prevState,
        t_body: getTableData.data.data,
        pagecount: Math.floor(count / pageNo + extraPage),
      }));
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  const downloadFile = async () => {
    try {
      const response = await downloadStudentListExcel(school_id, IDG_id);
      let filename = response.headers['content-disposition'].split('=')[1];
      var blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      saveAs(blob, filename);
      toast.success('file downloaded');

      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/students/${IDG_id}/excel-export`,
      //   {
      //     ...Auth,
      //     responseType: "arraybuffer",
      //   }
      // ).then((response) => {
      //   // console.log(response)
      //   let filename = response.headers["content-disposition"].split("=")[1];
      //   var blob = new Blob([response.data], {
      //     type: "application/octet-stream",
      //   });
      //   saveAs(blob, filename);
      //   toast.success("file downloaded");
      // });
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  const uploadFile = async (e) => {
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);
    // const options = {
    //   onUploadProgress: (progressEvent) => {
    //     const { loaded, total } = progressEvent;
    //     let percent = Math.floor((loaded * 100) / total);
    //     console.log(`${loaded}kb of ${total}kb | ${percent}%`);
    //   }
    // }

    formdata.append('filetoupload', file);
    console.log('function');
    try {
      await uploadStudentsToSIS(school_id, formdata);
      toast.success('File Uploaded Successfully');
      setLoading(false);
      setTimeout(() => {
        window.location.reload(false);
      }, 3000);
    } catch (err) {
      if (err) {
        setErrorUpload(true);
        setLoading(false);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
        toast.error(`oops! error while upload file  check in the modal..`);
        setMsg(err.response.data.message);
        setErrMsg(err.response.data.data);
      }
    }
    // Axios({
    //   url: `${BASE_URL}/institutions/${school_id}/students/excel-import`,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //     "Content-Type": "multipart/form-data",
    //     Accept: "*",
    //     type: "formData",
    //     "Access-Control-Allow-Headers": "*",
    //   },
    //   data: formdata,
    // })
    //   .then((res) => {
    //     toast.success("File Uploaded Successfully");
    //     setLoading(false);
    //     setTimeout(() => {
    //       window.location.reload(false);
    //     }, 3000);
    //   })
    //   .catch((err) => {
    //     if (err) {
    //       setErrorUpload(true);
    //       setLoading(false);
    //       if (err.response && err.response.data) {
    //         console.log(err.response.data.message);
    //         toast.error(err.response.data.message);
    //       }
    //       toast.error(`oops! error while upload file  check in the modal..`);
    //       setMsg(err.response.data.message);
    //       setErrMsg(err.response.data.data);
    //     }
    //   });
  };

  const uploadImages = async (e) => {
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);
    formdata.append('filetoupload', file);
    try {
      await bulkUploadImagesForStudents(formdata);
      toast.success('File Uploaded Successfully');
      setLoading(false);
    } catch (err) {
      if (err) {
        setErrorUpload(true);
        setLoading(false);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
        toast.error(`oops! error while upload file  check in the modal..`);
        setMsg(err.response.data.message);
        setErrMsg(err.response.data.data);
      }
    }
    // Axios({
    //   url: `${BASE_URL}/students/bulkupload`,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //     "Content-Type": "multipart/form-data",
    //     Accept: "*",
    //     type: "formData",
    //     "Access-Control-Allow-Headers": "*",
    //   },
    //   data: formdata,
    // })
    //   .then((res) => {
    //     toast.success("File Uploaded Successfully");
    //     setLoading(false);
    //     // setTimeout(() => {
    //     //   window.location.reload(false);
    //     // }, 3000);
    //   })
    //   .catch((err) => {
    //     if (err) {
    //       setErrorUpload(true);
    //       setLoading(false);
    //       if (err.response && err.response.data) {
    //         console.log(err.response.data.message);
    //         toast.error(err.response.data.message);
    //       }
    //       toast.error(`oops! error while upload file  check in the modal..`);
    //       setMsg(err.response.data.message);
    //       setErrMsg(err.response.data.data);
    //     }
    //   });
  };

  const handleClosePopup = () => setErrorUpload(false);
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  const ErrModal = () => {
    let errHead = ['Student Id', 'Student Name', 'Error'];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.student_id}</td>
                    <td>{elm.student_name ? elm.student_name : 'NA'}</td>
                    <td>{elm.result ? elm.result : elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  async function handleProfilePic(e, rollNumber, studentId) {
    var uploadedFile = e.target.files[0];
    async function putImageUrl(path) {
      try {
        const putProfilePicUrl = await addProfilePictureForStudent(school_id, studentId, { profile_pic_url: path });
        // await Axios.put(
        //   `${BASE_URL}/institutions/${school_id}/students/${studentId}`,
        //   { profile_pic_url: path },
        //   Auth
        // );
        console.log(putProfilePicUrl);
        toast.success('Profile picture Uploaded');
        setTimeout(fetchResult, 2000);
      } catch (err) {
        setLoading(false);
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
        toast.error(`oops! error while uploading file`);
      }
    }
    const nameSplit = uploadedFile.type.slice(6);
    if (uploadedFile !== null) {
      setIsLoading(true);
      const FilePath = profilePicFolder + '/' + 'institution-' + school_id + '/' + 'student-' + rollNumber + '.' + nameSplit;
      const params = {
        Body: uploadedFile,
        Bucket: `${configuration.bucketName}`,
        Key: FilePath,
      };
      s3.putObject(params)
        .on('build', (request) => {
          request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
          request.httpRequest.headers['Content-Length'] = uploadedFile.size;
          request.httpRequest.headers['Content-Type'] = uploadedFile.type;
          request.httpRequest.headers['x-amz-acl'] = 'public-read';
          request.httpRequest.headers['Access-Control-Allow-Origin'] = '*';
          // console.log(request.response.httpResponse);
          profilePicPath = configuration.digitalOceanSpaces + FilePath;
          console.log(profilePicPath);
          putImageUrl(profilePicPath);
        })
        .send((err) => {
          if (err) {
            console.log(err, 'Img error');
            toast.error(err.response.data.message);
            setIsLoading(false);
          }
        });
    }
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.topBox}>
        <Box component="div" className={styles.left}>
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, 'selectedgroup');
            }}
          >
            <option>{'Institution Defined Group'}</option>
            {state.optInst.map((el, key) => (
              <option value={el.inst_defined_group} key={key}>
                {el.inst_defined_group}
              </option>
            ))}
          </Box>
        </Box>
        <Box component="div" className={styles.right}>
          <Box
            component="div"
            className={styles.search_box}
            onChange={(e) => {
              changedBoard(e, 'selectedsearch');
            }}
          >
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <img src={require('../../../../assets/icons/search.png')} alt="search" />
              </div>
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                style={{ padding: '0px' }}
              />
            </div>
          </Box>
          <Box component="div" className={styles.upload_images}>
            {/* <Formik> */}
            {/* {(formik) => { */}
            {/* return ( */}
            <div className={styles.upload}>
              {/* <Form> */}
              <input
                type="file"
                id="imagesupload"
                name="imagesupload"
                // multiple
                accept=".zip"
                onChange={(e) => {
                  uploadImages(e);
                  e.target.value = null;
                }}
                style={{ display: 'none', marginRight: '5px' }}
              />
              <label
                htmlFor="imagesupload"
                style={{
                  borderRadius: '10px',
                  width: '165px',
                  height: '32px',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '10px',
                }}
              >
                <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ marginRight: '10px' }} />
                Upload Student Images
              </label>
              {/* </Form> */}
            </div>
            {/* ); */}
            {/* }} */}
            {/* </Formik> */}
          </Box>
          <Box component="div" className={styles.download_list} onClick={downloadFile}>
            <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ marginRight: '10px' }} />
            Download Student List
          </Box>
          <Box component="div" className={styles.upload_list}>
            <Formik>
              {(formik) => {
                return (
                  <div className={styles.upload}>
                    <Form>
                      <Field
                        type="file"
                        id="filetoupload"
                        name="filetoupload"
                        // multiple
                        // accept=".*"
                        onChange={(e) => {
                          uploadFile(e);
                          e.target.value = null;
                        }}
                        style={{ display: 'none' }}
                      />
                      <label
                        htmlFor="filetoupload"
                        style={{
                          borderRadius: '10px',
                          width: '165px',
                          height: '32px',
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          padding: '10px',
                        }}
                      >
                        <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ marginRight: '10px' }} />
                        Upload New Student List
                      </label>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <div className={styles.loader}>
          <ReactBootStrap.Spinner animation="border" variant="success" /> &nbsp;
          <span className={styles.loaderTxt}>Loading...</span>
        </div>
      ) : null}
      <Box component="div" className={styles.tableBox}>
        <div className={styles.table_box}>
          {isLoading === true ? (
            <Example />
          ) : (
            <div className={styles.schoolList_Table}>
              <div className={styles.schoolList_Table_head}>
                <div className={styles.schoolList_Table_head_data_photo}>Sl. No.</div>
                <div className={styles.schoolList_Table_head_data_photo}>Photo</div>
                {state.t_head.map((elm, key) => (
                  <div key={key} className={styles.schoolList_Table_head_data}>
                    {elm}
                  </div>
                ))}
              </div>
              {
                <div className={styles.schoolList_Table_body}>
                  {state.t_body.length === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      No Data Found
                      <span role="img" aria-label="jsx-a11y/accessible-emoji">
                        😔
                      </span>
                    </div>
                  ) : (
                    state.t_body.map((elm, key) => (
                      <div key={key} className={styles.schoolList_Table_body_row}>
                        <div className={styles.schoolList_Table_body_row_td_photo}>
                          {+pageNo === 10 ? key + 1 + 10 * (default_page - 1) : key + 1 + 20 * (default_page - 1)}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td_photo}>
                          {elm.profile_pic_url !== null ? (
                            <img src={elm.profile_pic_url} className={styles.schoolList_td_photo} alt="avatar" />
                          ) : (
                            <img src={userIcon} className={styles.schoolList_td_photo} alt="userIcon" />
                          )}
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            className={styles.profilePicUpload}
                            onChange={(e) => handleProfilePic(e, elm.institution_student_id, elm.id)}
                          />
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          <div className={styles.student_avt}>
                            {/* <div>
                          <Avatar
                            src={elm.cmn_student_profile_pic_file_path}
                            style={{
                              height: "22px",
                              width: "22px",
                              marginRight: "10px",
                            }}
                            alt="avatar"
                          />
                        </div> */}
                            <div>{elm.student_name}</div>
                          </div>
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.institution_student_id}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.institution_defined_group}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.registered_mobile}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.alternate_mobile}</div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {console.log(elm.dob)}
                          {elm.dob}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>{elm.city}</div>
                        {/* <div className={styles.schoolList_Table_body_row_td3}>
                      {elm.pincode}
                    </div>
                    <div className={styles.schoolList_Table_body_row_td3}>
                      {elm.internet}
                    </div> */}
                      </div>
                    ))
                  )}
                </div>
              }
            </div>
          )}
        </div>
      </Box>
      {+state.pagecount === 0 || state.pagecount === null ? null : (
        <Box className={styles.bottomBox + ' pagination'} component="div">
          {default_page === 1 ? (
            <div className={styles.first + ' ' + styles.inActive}>First</div>
          ) : (
            <div className={styles.first} onClick={(e) => pagechange(e, 1)}>
              First
            </div>
          )}
          <div
          // className="col-md-10"
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   marginLeft: "50px",
          // }}
          >
            <Pagination
              count={state.pagecount}
              page={default_page}
              // defaultPage={default_page}
              onChange={pagechange}
            />
          </div>
          {default_page === state.pagecount ? (
            <div className={styles.last + ' ' + styles.inActive}>Last</div>
          ) : (
            <div className={styles.last} onClick={(e) => pagechange(e, state.pagecount)}>
              Last
            </div>
          )}
          <div className={styles.showRows}>
            <select
              component="select"
              className={styles.options}
              onChange={(e) => {
                changedBoard(e, 'showrow');
              }}
              value={state.pageNo}
            >
              <option value="10">{'Show 10'}</option>
              <option value="20">{'Show 20'}</option>
            </select>
          </div>
        </Box>
      )}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {errorUpload ? <ErrModal /> : null}
    </Box>
  );
};

export default Student;
