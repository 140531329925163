import https from './init/https';

// PORT-SH-1:  Check if subject hierarchy exists
export const checkSubjectHierarchy = async (board_id, course_id, subject_id) => {
  return await https.get(
    `/institutions/:institution_id/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy/check_if_exists`,
  );
};

// PORT-SH-2:  Download the Subject Hierarchy Blank Excel Template
export const downloadHierarchyTemplate = async (school_id, boardId, courseId, subjectId) => {
  return await https.get(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/hierarchy/excel_export`,
    {
      responseType: 'arraybuffer',
    },
  );
};

// PORT-SH-3:  Import the Subject Hierarchy Excel Template
export const uploadHierarchyFile = async (school_id, boardId, courseId, subjectId, data) => {
  // console.log("Form Data : ",formData);
  return await https.post(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/hierarchy/excel_import`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*',
        type: 'formData',
        'Access-Control-Allow-Headers': '*',
      },
    },
  );
};

// PORT-SH-4: Retrieve the Subject Hierarchy JSON for a Subject
export const getSubjectHierarchyJSON = async (school_id, board_id, course_id, subject_id) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy/`);
};

// PORT-SH-5: Create a Subject Hierarchy Node
export const createHierarchyNode = async (school_id, board_id, course_id, subject_id, nodeDetails) => {
  return await https.post(
    `/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy`,
    nodeDetails,
  );
};

// PORT-SH-6: Update a Subject Hierarchy Node
export const updateHierarchyNode = async (school_id, board_id, course_id, subject_id, nodeDetails) => {
  return await https.put(
    `/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy`,
    nodeDetails,
  );
};

// PORT-SH-7: Delete a Subject Hierarchy Node
export const deleteHierarchyNode = async (school_id, board_id, course_id, subject_id, nodeDetails) => {
  return await https.delete(`/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy`, {
    data: nodeDetails,
  });
};

// PORT-SH-8: Reorder a Subject Hierarchy Node
export const reOrderSubjectsInHierarchy = async (school_id, board_id, moveData) => {
  return await https.put(`/institutions/${school_id}/boards/${board_id}/hierarchy/reorder`, moveData);
};
