import React from 'react';

const Button = (props) => {
  const { children, onClick, className, type } = props;
  return (
    <button type={type} className={className} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
