import https from './init/https';

// PORT-IS-1: Download subjects for a course via excel export
export const downloadSubjectsForCourseExcel = async (school_id, boardId, courseId) => {
  return await https.get(`/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/excel-export`, {
    responseType: 'arraybuffer',
  });
};

// PORT-IS-2: Add, update, delete subjects to a course
export const updateSubjectCourse = async (school_id, boardId, courseId, formdata) => {
  return await https.post(`/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/excel-import`, formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*',
      type: 'formData',
      'Access-Control-Allow-Headers': '*',
    },
  });
};
// export const updateSubjectCourse = async (
//   school_id,
//   boardId,
//   courseId,
//   formdata
// ) => {
//   return await https({
//     url: `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/excel-import`,
//     mode: "no-cors",
//     method: "POST",
//     headers: {
//       "Content-Type": "multipart/form-data",
//       Accept: "*",
//       type: "formData",
//       "Access-Control-Allow-Headers": "*",
//     },
//     data: formdata,
//   });
// };

// PORT-IS-3: Retrieve list of subjects with details for an institution
export const getAllSubjectDetailsByInstitute = async (school_id, board_id) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/subjects/detailed-info`);
};

// PORT-IS-4: Retrieve list of subjects for an institutions
export const getAllSubjectsByInstitute = async (school_id, board_id, subject_tag) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/subjects?subject_tag=${subject_tag}`);
};

// PORT-IS-5: Add new Subject
export const addNewSubjectToCourse = async (school_id, board_id, subject_details) => {
  return await https.post(`/institutions/${school_id}/boards/${board_id}/subjects`, subject_details);
};

// PORT-IS-6: Update a Subject

export const updateSubjectInCourse = async (school_id, board_id, subject_id, updated_data) => {
  return await https.put(`/institutions/${school_id}/boards/${board_id}/subjects/${subject_id}`, updated_data);
};

// PORT-IS-7: Delete a Subject
export const deleteSubjectFromCourse = async (school_id, board_id, subject_id) => {
  return await https.delete(`/institutions/${school_id}/boards/${board_id}/subjects/${subject_id}`);
};
// PORT-IS-8: Add Subject Tag

export const addTagToSubject = async (school_id, board_id, subject_tag) => {
  return await https.post(`/institutions/${school_id}/boards/${board_id}/subject-tags?subject_tag=${subject_tag}`, {});
};

// PORT-IS-9: Retrieve list of subjects for an institutions for given board / course
export const getAllSubjectsByBoardId = async (school_id, board_id, course_id, medium_id) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects?${medium_id}`);
};
