import https from './init/https';

// PORT-ISS-1: Download Sub Subjects for a Subject via Excel Download
export const downloadSubSubjectExcel = async (school_id, boardId, courseId, subjectId, medium_id) => {
  return await https.get(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/excel-export?medium_id=${medium_id}`,
    {
      responseType: 'arraybuffer',
    },
  );
};
// PORT-ISS-2: Add, update, delete sub subjects via excel
export const addUpdateDeleteSubSubjectFromExcel = async (school_id, boardId, courseId, subjectId, formdata) => {
  return await https.post(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/excel-import`,
    formdata,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*',
        type: 'formData',
        'Access-Control-Allow-Headers': '*',
      },
    },
  );
};
// export const addUpdateDeleteSubSubjectFromExcel = async (
//   school_id,
//   boardId,
//   courseId,
//   subjectId,
//   formdata,

// ) => {
//   return await https({
//     url: `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/excel-import`,
//     mode: "no-cors",
//     method: "POST",
//     headers: {

//       "Content-Type": "multipart/form-data",
//       Accept: "*",
//       type: "formData",
//       "Access-Control-Allow-Headers": "*",
//     },
//     data: formdata,
//   });
// };

// PORT-ISS-3: Retrieve list of sub subjects
export const getListOfSubSubjects = async (school_id, boardId, courseId, subjectId, medium_id) => {
  return await https.get(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/detailed-info?medium_id=${medium_id}`,
  );
};

// PORT-ISS-4: Retrieve list of sub-subjects for an institution
export const getInstitutionWiseListOfSubSubjects = async (school_id, board_id, course_id, subject_id) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects`);
};
