// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MasterdataStyle_dashboard_txt__z25kl {
  color: #2e2b2b;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
}
.MasterdataStyle_txt__SatX\\+ {
  text-align: center;
  font-weight: 600;
  padding-left: 7px;
  margin-bottom: 8px;
}

.MasterdataStyle_react-tabs__tab__oCFu8 {
  width: 94px !important;
}

.MasterdataStyle_studyCircle__cMl-k {
  display: inline-block;
  border: 1px solid #aaa;
  border-bottom: none;
  bottom: 11px;
  position: relative;
  list-style: none;
  cursor: pointer;
  margin-right: -1px;
  border-radius: 10px 20px 0px 0px;
  width: 102px;
  height: 50px;
  font-size: 12px;
  padding: 10px 6px;
}

.MasterdataStyle_txtstudyCircle__Ac3yi {
  margin-left: 18px;
  text-align: center;
  font-weight: 600;
  padding-left: 7px;
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/MasterData/MasterdataStyle.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gCAAgC;EAChC,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".dashboard_txt {\n  color: #2e2b2b;\n  font-size: 16px;\n  line-height: 23px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\n.txt {\n  text-align: center;\n  font-weight: 600;\n  padding-left: 7px;\n  margin-bottom: 8px;\n}\n\n.react-tabs__tab {\n  width: 94px !important;\n}\n\n.studyCircle {\n  display: inline-block;\n  border: 1px solid #aaa;\n  border-bottom: none;\n  bottom: 11px;\n  position: relative;\n  list-style: none;\n  cursor: pointer;\n  margin-right: -1px;\n  border-radius: 10px 20px 0px 0px;\n  width: 102px;\n  height: 50px;\n  font-size: 12px;\n  padding: 10px 6px;\n}\n\n.txtstudyCircle {\n  margin-left: 18px;\n  text-align: center;\n  font-weight: 600;\n  padding-left: 7px;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_txt": `MasterdataStyle_dashboard_txt__z25kl`,
	"txt": `MasterdataStyle_txt__SatX+`,
	"react-tabs__tab": `MasterdataStyle_react-tabs__tab__oCFu8`,
	"studyCircle": `MasterdataStyle_studyCircle__cMl-k`,
	"txtstudyCircle": `MasterdataStyle_txtstudyCircle__Ac3yi`
};
export default ___CSS_LOADER_EXPORT___;
