import React, { useState } from 'react';
import { TabPanel, TabList, Tabs, Tab } from 'react-tabs';
import { Box } from '@material-ui/core';
import InstitutionAct from '../../assets/icons/Institution.png';
import InstitutionInact from '../../assets/icons/Institution_inact.png';
import SubjectAct from '../../assets/icons/Subject_act.png';
import SubjectInact from '../../assets/icons/Subject.png';
import TopicAct from '../../assets/icons/Topic_act.png';
import TopicInact from '../../assets/icons/Topic.png';
import TeacherAct from '../../assets/icons/Teacher_grey.png';
import TeacherInact from '../../assets/icons/Teacher.png';
import StudentAct from '../../assets/icons/Study Circle_Grey Copy.png';
import StudentInact from '../../assets/icons/Study.png';
import StudyCircleAct from '../../assets/icons/Study Circle_Grey.png';
import StudyCircleInact from '../../assets/icons/StudyCircle.png';
import CourseAct from '../../assets/icons/course_act.png';
import CourseInact from '../../assets/icons/course.png';

// import "react-tabs/style/react-tabs.css";
import './Tabs_style.css';
import {
  Institution,
  // Course,
  // SubjectOld,
  // SubSubject,
  // Topic,
  // SubTopic,
  Educator,
  Student,
  Studycircle,
  SubjectHierarchy,
  Subject,
  Courses,
  CourseSubject,
} from './Tabs/TabScreens';
import styles from './MasterdataStyle.module.css';

var tabID;
var Institution_Icon;
// var Course_Icon;
// var SubjectOld_Icon;
// var SubSubject_Icon;
// var Topic_Icon;
// var SubTopic_Icon;
var Teacher_Icon;
var student_Icon;
var StudyCircle_Icon;
var SubjectHierarchy_Icon;
var Subject_Icon;
var Courses_Icon;
var CourseSubject_Icon;
var Institution_load;
// var Course_load;
// var SubjectOld_load;
// var SubSubject_load;
// var Topic_load;
// var SubTopic_load;
var Teacher_load;
var Student_load;
var StudyCircle_load;
var SubjectHierarchy_load;
var Subject_load;
var Courses_load;
var CourseSubject_load;

// window.localStorage.getItem("tabIndex") == null
//   ? (tabID = 0)
//   : (tabID = Number(window.localStorage.getItem("tabIndex")));

const MasterData = () => {
  window.localStorage.getItem('tabIndex') == null ? (tabID = 0) : (tabID = Number(window.localStorage.getItem('tabIndex')));

  console.log(tabID);

  tabID === 0 ? (Institution_load = InstitutionAct) : (Institution_load = InstitutionInact);
  // tabID === 1 ? (Course_load = CourseAct) : (Course_load = CourseInact);
  // tabID === 2 ? (SubjectOld_load = SubjectAct) : (SubjectOld_load = SubjectInact);
  // tabID === 3
  // ? (SubSubject_load = SubjectAct)
  // : (SubSubject_load = SubjectInact);
  // tabID === 4 ? (Topic_load = TopicAct) : (Topic_load = TopicInact);
  // tabID === 5 ? (SubTopic_load = TopicAct) : (SubTopic_load = TopicInact);
  tabID === 3 ? (CourseSubject_load = SubjectAct) : (CourseSubject_load = SubjectInact);
  tabID === 4 ? (SubjectHierarchy_load = TopicAct) : (SubjectHierarchy_load = TopicInact);
  tabID === 5 ? (Teacher_load = TeacherAct) : (Teacher_load = TeacherInact);
  tabID === 6 ? (Student_load = StudentAct) : (Student_load = StudentInact);
  tabID === 7 ? (StudyCircle_load = StudyCircleAct) : (StudyCircle_load = StudyCircleInact);
  tabID === 2 ? (Subject_load = TeacherAct) : (Subject_load = TeacherInact);
  tabID === 1 ? (Courses_load = CourseAct) : (Courses_load = CourseInact);

  const [state, setState] = useState({
    Institution_link: Institution_load,
    // Course_link: Course_load,
    // SubjectOld_link: Subject_load,
    // SubSubject_link: SubSubject_load,
    // Topic_link: Topic_load,
    // SubTopic_link: SubTopic_load,
    Teacher_link: Teacher_load,
    student_link: Student_load,
    StudyCircle_link: StudyCircle_load,
    SubjectHierarchy_link: SubjectHierarchy_load,
    Subject_link: Subject_load,
    Courses_link: Courses_load,
    CourseSubject_link: CourseSubject_load,
  });

  const tabSelected = (ID) => {
    ID === 0 ? (Institution_Icon = InstitutionAct) : (Institution_Icon = InstitutionInact);
    // ID === 1 ? (Course_Icon = CourseAct) : (Course_Icon = CourseInact);
    // ID === 2 ? (SubjectOld_Icon = SubjectAct) : (SubjectOld_Icon = SubjectInact);
    // ID === 3
    // ? (SubSubject_Icon = SubjectAct)
    // : (SubSubject_Icon = SubjectInact);
    // ID === 4 ? (Topic_Icon = TopicAct) : (Topic_Icon = TopicInact);
    // ID === 5 ? (SubTopic_Icon = TopicAct) : (SubTopic_Icon = TopicInact);
    ID === 3 ? (CourseSubject_Icon = SubjectAct) : (CourseSubject_Icon = SubjectInact);
    ID === 4 ? (SubjectHierarchy_Icon = TopicAct) : (SubjectHierarchy_Icon = TopicInact);
    ID === 5 ? (Teacher_Icon = TeacherAct) : (Teacher_Icon = TeacherInact);
    ID === 6 ? (student_Icon = StudentAct) : (student_Icon = StudentInact);
    ID === 7 ? (StudyCircle_Icon = StudyCircleAct) : (StudyCircle_Icon = StudyCircleInact);
    ID === 2 ? (Subject_Icon = TeacherAct) : (Subject_Icon = TeacherInact);
    ID === 1 ? (Courses_Icon = CourseAct) : (Courses_Icon = CourseInact);

    setState({
      ...state,
      Institution_link: Institution_Icon,
      // Course_link: Course_Icon,
      // SubjectOld_link: Subject_Icon,
      // SubSubject_link: SubSubject_Icon,
      // Topic_link: Topic_Icon,
      // SubTopic_link: SubTopic_Icon,
      Teacher_link: Teacher_Icon,
      student_link: student_Icon,
      StudyCircle_link: StudyCircle_Icon,
      SubjectHierarchy_link: SubjectHierarchy_Icon,
      Subject_link: Subject_Icon,
      Courses_link: Courses_Icon,
      CourseSubject_link: CourseSubject_Icon,
    });
  };

  return (
    <div>
      <div className={styles.dashboard_txt}>MASTER DATA</div>
      <Tabs defaultIndex={tabID}>
        <TabList>
          <Tab style={{ fontWeight: 600 }} onClick={() => tabSelected(0)}>
            <img src={state.Institution_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Institution</span>
          </Tab>
          {/* <Tab onClick={() => tabSelected(1)}>
            <img src={state.Course_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Course</span>
          </Tab> */}
          {/* <Tab onClick={() => tabSelected(2)}>
            <img src={state.SubjectOld_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Subject</span>
          </Tab> */}
          {/* <Tab onClick={() => tabSelected(3)} className={styles.studyCircle}> */}
          {/* <Tab onClick={() => tabSelected(3)}>
            <img
              src={state.SubSubject_link}
              alt="inst"
              height={18}
              width={18}
            /> */}
          {/* <span className={styles.txt}>SS</span> */}
          {/* <span className={styles.txt}>Sub</span> */}
          {/* <br /> */}
          {/* <span className={styles.txtstudyCircle}>Subject</span> */}
          {/* </Tab>
          <Tab onClick={() => tabSelected(4)}>
            <img src={state.Topic_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Topic</span>
          </Tab>
          <Tab onClick={() => tabSelected(5)}>
            <img src={state.SubTopic_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>ST</span> */}
          {/* <span className={styles.txt}>SubTopic</span> */}
          {/* </Tab> */}
          <Tab onClick={() => tabSelected(1)}>
            <img src={state.Courses_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Courses</span>
          </Tab>
          <Tab onClick={() => tabSelected(2)}>
            <img src={state.Subject_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Subject</span>
          </Tab>
          <Tab onClick={() => tabSelected(3)} className={styles.studyCircle}>
            <img src={state.CourseSubject_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Course</span>
            <br />
            <span className={styles.txtstudyCircle}>Subject</span>
          </Tab>
          <Tab onClick={() => tabSelected(4)} className={styles.studyCircle}>
            <img src={state.SubjectHierarchy_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Subject</span>
            <br />
            <span className={styles.txtstudyCircle}>Hierarchy</span>
          </Tab>
          <Tab onClick={() => tabSelected(5)}>
            <img src={state.Teacher_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Educator</span>
          </Tab>
          <Tab onClick={() => tabSelected(6)}>
            <img src={state.student_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Student</span>
          </Tab>
          <Tab onClick={() => tabSelected(7)} className={styles.studyCircle}>
            {/* <Tab onClick={() => tabSelected(6)}> */}
            <img src={state.StudyCircle_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Study</span>
            <br />
            <span className={styles.txtstudyCircle}>Circle</span>
          </Tab>
        </TabList>

        <TabPanel>
          <Box>
            <Institution />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Courses />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Subject />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <CourseSubject />
          </Box>
        </TabPanel>
        {/* <TabPanel>
          <Box>
            <Course />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <SubjectOld />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <SubSubject />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Topic />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <SubTopic />
          </Box>
        </TabPanel> */}
        <TabPanel>
          <Box>
            <SubjectHierarchy />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Educator />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Student />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Studycircle />
          </Box>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MasterData;
