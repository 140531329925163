import React from 'react';
import { TabPanel, TabList, Tabs, Tab } from 'react-tabs';
import { Box } from '@material-ui/core';

// import "react-tabs/style/react-tabs.css";
import './Tabs_style.css';
import { Institution, GroupInstitution, GroupEducator, Studycircle } from './Tabs/TabScreens';
import styles from './GroupdataStyle.module.css';

var tabID;

window.localStorage.getItem('groupTabIndex') == null ? (tabID = 0) : (tabID = Number(window.localStorage.getItem('groupTabIndex')));

console.log(tabID);
const MasterData = () => {
  return (
    <div className="groupData">
      <div className={styles.dashboard_txt}>INSTITUTION LIST</div>
      <Tabs defaultIndex={tabID}>
        <TabList>
          <Tab style={{ fontWeight: 600 }}>
            <span className={styles.txt}>Institution</span>
          </Tab>
          <Tab>
            <span className={styles.txt}>Group Institution</span>
          </Tab>
          <Tab>
            <span className={styles.txt}>Group Educator</span>
          </Tab>
          <Tab>
            <span className={styles.txt}>Group study circle</span>
          </Tab>
        </TabList>

        <TabPanel>
          <Box>
            <Institution />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <GroupInstitution />
          </Box>
        </TabPanel>
        <TabPanel>
          <GroupEducator />
        </TabPanel>
        <TabPanel>
          <Studycircle />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MasterData;
