import React, { useState, useEffect } from 'react';
// import Axios from 'axios';
import SortableTree, {
  toggleExpandedForAll,
  changeNodeAtPath,
  // insertNode,
  removeNodeAtPath,
  addNodeUnderParent,
  // getNodeAtPath,
} from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import './SubjectHierarchy.css';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
// import { BASE_URL } from '../../../../config/Api';
import { Box } from '@material-ui/core';
import styles from './TopicStyle.module.css';
import { Formik, Form, Field } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import { saveAs } from 'file-saver';
import ReactLoading from 'react-loading';
import { Dialogbox } from '../../../../components/index';
import { getCoursesListOfAnInstitution } from '../../../../api/institutionCourses';
import {
  downloadHierarchyTemplate,
  uploadHierarchyFile,
  getSubjectHierarchyJSON,
  createHierarchyNode,
  updateHierarchyNode,
  deleteHierarchyNode,
} from '../../../../api/subjectHierarchy';
import { getAllSubjectsByBoardId } from '../../../../api/InstitutionSubjects';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';

var searchFocusIndex = 0;
var maxDepthNode = 4;
// var newPath;
var newNode;
var board_id = -1;
var course_id = -1;
var subject_id = -1;
// var default_page = 1;
// var pageNum = 1;
var sendNode;
// var medium_id = -1;
var newPath = [];
// var recentTopicId = null;
// var recentSubSubjectId = null;
// var recentSubjectId = null;
// var treeArray;
var tempChildrenArray = [];
var childrenArray;
var oldPath;
var oldNode;
var oldNumber = 0;

function SubjectHierarchy() {
  const [tree, setTree] = useState({
    treeData: [
      {
        id: 76,
        parent_id: null,
        type: 'Subject',
        title: '1st_Maths',
        title_eng: '1st_Maths',
        children: [
          {
            id: 87,
            parent_id: 76,
            type: 'Sub Subject',
            title: 'BLANK',
            title_eng: 'BLANK',
            children: [
              {
                id: 213,
                parent_id: 87,
                type: 'Topic',
                title: 'Topic 1',
                title_eng: 'Topic 1',
                children: [
                  {
                    id: 5,
                    parent_id: 213,
                    type: 'Sub Topic',
                    title: 'Sub Topic 1',
                    title_eng: 'Sub Topic 1',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  });
  const [searchString, setSearchString] = useState('');
  const [popUp, setPopUp] = useState(false);
  const [deleteNode, setDeleteNode] = useState(false);
  const [addPressed, setAddPressed] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [msg, setMsg] = useState('');
  // const [editVal, setEditVal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [treeDisplay, setTreeDisplay] = useState(false);
  const [noData, setNoData] = useState(false);
  // const [currentNode, setCurrentNode] = useState({});
  // const [open, setOpen] = useState(false);
  const getNodeKey = ({ treeIndex }) => treeIndex;
  const [state, setState] = useState({
    optBD: [],
    boardId: '-1',
    courseId: '-1',
    subjectId: '-1',
    subSubjectId: '-1',
    optCourse: [],
    optSubject: [],
  });

  const school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 4);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  // useEffect(() => {
  //   async function getData() {
  //     const getJSONData = await await Axios.get(
  //       `https://devsip2-api.stucle.in/institutions/0/boards/1/courses/1/subjects/1/hierarchy/`
  //     );
  //     console.log(getJSONData.data.data.subjects_json);
  //     setTree({ treeData: getJSONData.data.data.subjects_json });
  //   }
  //   getData();
  // }, []);

  function expandAndCollapse(expanded) {
    setTree({
      treeData: toggleExpandedForAll({
        treeData: tree.treeData,
        expanded,
      }),
    });
  }

  //function to handle cancel button in node
  async function cancelChange(path, node) {
    console.log(oldPath, oldNode);
    console.log(path, node);
    setAddPressed(false);
    if (node.id > 100000) {
      setTree((prevState) => ({
        treeData: removeNodeAtPath({
          treeData: prevState.treeData,
          path,
          getNodeKey: ({ treeIndex }) => treeIndex,
        }),
      }));
    } else {
      path = oldPath;
      node = oldNode;
      console.log(node);
      setTree((PrevState) => ({
        treeData: changeNodeAtPath({
          treeData: PrevState.treeData,
          path,
          getNodeKey,
          newNode: { ...node, notSaved: false },
        }),
      }));
    }
    oldNumber = 0;
  }

  async function removeNode(path, node) {
    setPopUp(true);
    console.log(path, node);
    var idNumChild = node.id;
    console.log(idNumChild, deleteNode);
    setDeleteNode(true);
    if (deleteNode === true) {
      console.log(123);
      if (node.children !== null && node.children && node.children.length !== 0) {
        // if (node.children !== null || (node.children && node.children !== [])) {
        console.log(789);
        if (node.children.length > 0) {
          tempChildrenArray = node.children;
          childrenArray =
            tempChildrenArray.length > 0 &&
            tempChildrenArray.map((tempchild) => {
              return { ...tempchild, children: [] };
            });
          tempChildrenArray.length > 0 &&
            tempChildrenArray.map((child) => {
              return child.children ? (childrenArray = [...child.children, ...childrenArray]) : null;
            });
          childrenArray = [
            ...childrenArray,
            {
              id: node.id,
              type: node.type,
              title: node.title,
              seq_no: node.seq_no,
              parent_id: node.parent_id,
            },
          ];
          console.log(childrenArray);
          for (var i = 0; i < childrenArray.length; i++) {
            console.log(childrenArray[i]);
            sendNode = {
              node_json: {
                id: childrenArray[i].id,
                type: childrenArray[i].type,
                title: childrenArray[i].title,
                seq_no: childrenArray[i].seq_no,
                parent_id: childrenArray[i].parent_id,
              },
            };
            try {
              // PORT-SH-7: Delete a Subject Hierarchy Node
              const delNode = await deleteHierarchyNode(school_id, board_id, course_id, subject_id, sendNode, Auth);
              // await Axios.delete(
              //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy/`,
              //   {
              //     headers: {
              //       Authorization: "Bearer " + token,
              //     },
              //     data: sendNode,
              //   }
              //   // Auth
              // );
              toast.success('Deleted successfully');
              console.log(delNode);
              if (i === 0) {
                setTree((prevState) => ({
                  treeData: removeNodeAtPath({
                    treeData: prevState.treeData,
                    path,
                    getNodeKey: ({ treeIndex }) => treeIndex,
                  }),
                }));
              }
            } catch (err) {
              if (err.response && err.response.data) {
                console.log(err.response.data.message);
                toast.error(err.response.data.message);
              }
            }
          }
        }
      } else {
        console.log(456);
        sendNode = {
          node_json: {
            id: node.id,
            type: node.type,
            title: node.title,
            seq_no: node.seq_no,
            parent_id: node.parent_id,
          },
        };
        try {
          // PORT-SH-7: Delete a Subject Hierarchy Node
          const delNode = await deleteHierarchyNode(school_id, board_id, course_id, subject_id, sendNode, Auth);
          // await Axios.delete(
          //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy/`,
          //   {
          //     headers: {
          //       Authorization: "Bearer " + token,
          //     },
          //     data: sendNode,
          //   }
          //   // Auth
          // );
          console.log(delNode);
          toast.success('Deleted successfully');
          setTree((prevState) => ({
            treeData: removeNodeAtPath({
              treeData: prevState.treeData,
              path,
              getNodeKey: ({ treeIndex }) => treeIndex,
            }),
          }));
        } catch (err) {
          if (err.response && err.response.data) {
            console.log(err.response.data.message);
            toast.error(err.response.data.message);
          }
        }
      }

      setPopUp(false);
      setDeleteNode(false);
      setAddPressed(false);
    }
    if (deleteNode === false) {
      newPath = path;
      newNode = node;
    }
  }

  // function insertNewNode() {
  //   setAddPressed(true);
  //   setTree((prevState) => ({
  //     treeData: insertNode({
  //       treeData: prevState.treeData,
  //       depth: 0,
  //       minimumTreeIndex: prevState.treeData.length,
  //       // minimumTreeIndex: path,
  //       newNode: {
  //         title: "New tag",
  //         id: 100000 + 0,
  //         parent_id: 0,
  //         type: "Sub Subject",
  //         notSaved: true,
  //         children: [],
  //       },
  //       getNodeKey: ({ treeIndex }) => treeIndex,
  //     }).treeData,
  //   }));
  // }

  function nodeUnderParent(path, node) {
    console.log(path, node);
    setAddPressed(true);
    var idNumParent = node.id;
    var parentType = node.type;
    // recentTopicId = null;
    // recentSubSubjectId = null;
    // recentSubjectId = null;
    // treeArray = tree.treeData;
    // for (var j = 0; j < (path.length -1); j++) {
    //   newPath = path;
    //   newPath = newPath.splice(-1, 1);
    //   const parentNode = getNodeAtPath({
    //     treeArray,
    //     path: newPath,
    // getNodeKey,
    //   }).node;
    //   if(newPath.length === 3){
    //     recentTopicId = parentNode.id
    //   }
    //   if(newPath.length === 2){
    //     recentSubSubjectId = parentNode.id
    //   }
    //   if(newPath.length === 1){
    //     recentSubjectId = parentNode.id
    //   }
    //   console.log(recentTopicId, recentSubSubjectId, recentSubjectId);
    // }
    console.log(idNumParent, parentType);
    // if(node.title_eng){
    if (parentType === 'Sub Subject') {
      setTree((prevState) => ({
        treeData: addNodeUnderParent({
          treeData: prevState.treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          newNode: {
            title: '',
            title_eng: '',
            id: 100000 + idNumParent,
            parent_id: idNumParent,
            // subSubjectId: idNumParent,
            // topicId: null,
            type: 'Topic',
            notSaved: true,
            seq_no: null,
            children: [],
          },
          getNodeKey: ({ treeIndex }) => treeIndex,
        }).treeData,
      }));
    }
    if (parentType === 'Subject') {
      setTree((prevState) => ({
        treeData: addNodeUnderParent({
          treeData: prevState.treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          newNode: {
            title: '',
            title_eng: '',
            id: 100000 + idNumParent,
            parent_id: idNumParent,
            // subjectId: idNumParent,
            // topicId: null,
            type: 'Sub Subject',
            seq_no: null,
            notSaved: true,
            children: [],
          },
          getNodeKey: ({ treeIndex }) => treeIndex,
        }).treeData,
      }));
    } else if (parentType === 'Topic') {
      setTree((prevState) => ({
        treeData: addNodeUnderParent({
          treeData: prevState.treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          newNode: {
            title: '',
            title_eng: '',
            id: 100000 + idNumParent,
            parent_id: idNumParent,
            // subSubjectId: null,
            // topicId: idNumParent,
            type: 'Sub Topic',
            seq_no: null,
            notSaved: true,
            children: [],
          },
          getNodeKey: ({ treeIndex }) => treeIndex,
        }).treeData,
      }));
    }
    // document.getElementById("New tag").scrollIntoView()
    oldNumber = 0;
  }

  //function of okDelete in popup
  function okDelete() {
    // setDeleteNode(true);
    removeNode(newPath, newNode);
    oldNumber = 0;
    // setPopUp(false);
  }

  //function of cancel in popup
  function cancel() {
    setDeleteNode(false);
    setPopUp(false);
  }

  //function to save the node which were edited
  async function save(path, node) {
    console.log(path, node);
    // reacentSiblingSaved = lowerSiblingCounts;
    var idNumSave = node.id;
    var titleSave = node.title;
    sendNode = {
      node_json: {
        id: node.id,
        type: node.type,
        title: node.title,
        title_eng: node.title_eng,
        seq_no: node.seq_no,
        parent_id: node.parent_id,
      },
    };
    if (idNumSave >= 100000) {
      // idNumSave = idNumSave - 100000;
      try {
        const saveNewNode = await createHierarchyNode(school_id, board_id, course_id, subject_id, sendNode, Auth);
        //  await Axios.post(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy`,
        //   sendNode,
        //   Auth
        // );
        console.log(saveNewNode.data.data.id);
        setTree((PrevState) => ({
          treeData: changeNodeAtPath({
            treeData: PrevState.treeData,
            path,
            getNodeKey,
            newNode: { ...node, id: saveNewNode.data.data.id, notSaved: false },
          }),
        }));
        toast.success('Saved successfully');
        setAddPressed(false);
        oldNumber = 0;
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
      }
    } else {
      // idNumSave = idNumSave - 100000;
      try {
        // PORT-SH-6: Update a Subject Hierarchy Node
        const saveNewNode = await updateHierarchyNode(school_id, board_id, course_id, subject_id, sendNode, Auth);
        // await Axios.put(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy/`,
        //   sendNode,
        //   Auth
        // );
        console.log(saveNewNode.data);
        console.log(saveNewNode.status);
        setTree((PrevState) => ({
          treeData: changeNodeAtPath({
            treeData: PrevState.treeData,
            path,
            getNodeKey,
            newNode: { ...node, notSaved: false },
          }),
        }));
        toast.success('Saved successfully');
        setAddPressed(false);
        oldNumber = 0;
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
      }
    }
    console.log(idNumSave, titleSave);
  }

  const changedBoard = async (e, selectedvalue) => {
    setNoData(false);
    if (selectedvalue === 'board') {
      setTreeDisplay(false);
      // pageNum = 1;
      // default_page = 1;
      board_id = e.target.value;
      course_id = -1;
      subject_id = -1;
      try {
        // PORT-IC-4: Retrieve list of courses of an institution
        const getCourse = await getCoursesListOfAnInstitution(school_id, board_id, Auth);
        // const getCourse = await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses`,
        //   Auth
        // );
        setState((prevState) => ({
          ...prevState,
          boardId: board_id,
          courseId: course_id,
          subjectId: subject_id,
          optCourse: getCourse.data.data,
        }));
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message, 'error');
          toast.error(err.response.data.message);
        }
      }
    } else if (selectedvalue === 'course') {
      setTreeDisplay(false);
      course_id = e.target.value;
      subject_id = -1;
      // pageNum = 1;
      // default_page = 1;
      try {
        const getSubject = await getAllSubjectsByBoardId(school_id, board_id, course_id, '');
        // await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects`,
        //   Auth
        // );
        setState((prevState) => ({
          ...prevState,
          boardId: board_id,
          courseId: course_id,
          subjectId: subject_id,
          optSubject: getSubject.data.data,
        }));
        console.log(getSubject.data.data);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message, 'error');
          toast.error(err.response.data.message);
        }
      }
    } else if (selectedvalue === 'subject') {
      setIsLoading(true);
      setTreeDisplay(false);
      subject_id = e.target.value;
      // pageNum = 1;
      // default_page = 1;
      // setTreeDisplay(true);
      try {
        // PORT-SH-4: Retrieve the Subject Hierarchy JSON for a Subject
        const getJSONData = await getSubjectHierarchyJSON(school_id, board_id, course_id, subject_id, Auth);
        // await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy/`,
        //   Auth
        // );
        console.log(getJSONData.data.data.subjects_json);
        setTree({ treeData: getJSONData.data.data.subjects_json });
        setState((prevState) => ({
          ...prevState,
          boardId: board_id,
          courseId: course_id,
          subjectId: subject_id,
        }));
        setTreeDisplay(true);
        setIsLoading(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message, 'error');
          toast.error(err.response.data.message);
        }
        setNoData(true);
        setIsLoading(false);
        // setTreeDisplay(false);
      }
    }
  };

  const downloadFile = async () => {
    // try {
    // PORT-SH-2:  Download the Subject Hierarchy Blank Excel Template
    try {
      const response = await downloadHierarchyTemplate(school_id, state.boardId, state.courseId, state.subjectId, Auth);
      let filename = response.headers['content-disposition'].split('=')[1];
      var blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      saveAs(blob, filename);
      toast.success('file downloaded');
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message, 'error');
        toast.error(err.response.data.message);
      }
    }
    //   await Axios.get(
    //     `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/${state.courseId}/subjects/${state.subjectId}/hierarchy/excel_export`,
    //     {
    //       ...Auth,
    //       responseType: "arraybuffer",
    //     }
    //   ).then((response) => {
    //     // console.log(response)
    //     let filename = response.headers["content-disposition"].split("=")[1];
    //     var blob = new Blob([response.data], {
    //       type: "application/octet-stream",
    //     });
    //     saveAs(blob, filename);
    //     toast.success("file downloaded");
    //   });
    //   // }
    // } catch (err) {
    //   if (err.response && err.response.data) {
    //     console.log(err.response.data.message, "error");
    //     toast.error(err.response.data.message);
    //   }
    // }
  };

  const uploadFile = async (e) => {
    if (board_id !== -1 && course_id !== -1 && subject_id !== -1) {
      let formdata = new FormData();
      let file = e.target.files[0];
      setLoading(true);

      formdata.append('filetoupload', file);
      // PORT-SH-3:  Import the Subject Hierarchy Excel Template
      try {
        await uploadHierarchyFile(school_id, state.boardId, state.courseId, state.subjectId, formdata, Auth);
        setLoading(false);
        toast.success('File Uploaded Successfully');
        try {
          // PORT-SH-4: Retrieve the Subject Hierarchy JSON for a Subject
          const getJSONData = await getSubjectHierarchyJSON(school_id, board_id, course_id, subject_id, Auth);
          // const getJSONData = await Axios.get(
          //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy/`,
          //   Auth
          // );
          console.log(getJSONData.data.data.subjects_json);
          setTree({ treeData: getJSONData.data.data.subjects_json });
        } catch (err) {
          if (err.response && err.response.data) {
            console.log(err.response.data.message, 'error');
            toast.error(err.response.data.message);
          }
        }
      } catch (err) {
        console.log(err.response.data.message);
        setLoading(false);
        if (err) {
          setErrorUpload(true);
          toast.error(err.response.data.message);
          setMsg(err.response.data.message);
          setErrMsg(err.response.data.data);
        }
      }
      // Axios({
      //   url: `${BASE_URL}/institutions/${school_id}/boards/${state.boardId}/courses/${state.courseId}/subjects/${state.subjectId}/hierarchy/excel_import`,
      //   mode: "no-cors",
      //   method: "POST",
      //   headers: {
      //     ...Auth.headers,
      //     "Content-Type": "multipart/form-data",
      //     Accept: "*",
      //     type: "formData",
      //     "Access-Control-Allow-Headers": "*",
      //   },
      //   data: formdata,
      // })
      // .then(async (res) => {
      //   // console.log(res)
      //   setLoading(false);
      //   toast.success("File Uploaded Successfully");
      //   try {
      //     // PORT-SH-4: Retrieve the Subject Hierarchy JSON for a Subject
      //     const getJSONData = await Axios.get(
      //       `${BASE_URL}/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/hierarchy/`,
      //       Auth
      //     );
      //     console.log(getJSONData.data.data.subjects_json);
      //     setTree({ treeData: getJSONData.data.data.subjects_json });
      //   } catch (err) {
      //     if (err.response && err.response.data) {
      //       console.log(err.response.data.message, "error");
      //       toast.error(err.response.data.message);
      //     }
      //   }
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 3000);
      // })
      // .catch((err) => {
      //   console.log(err.response.data.message);
      //   setLoading(false);
      //   if (err) {
      //     setErrorUpload(true);
      //     toast.error(err.response.data.message);
      //     setMsg(err.response.data.message);
      //     setErrMsg(err.response.data.data);
      //   }
      // });
    } else {
      toast.error('Select all the dropdowns');
    }
  };

  const fetchResults = async () => {
    setIsLoading(true);
    try {
      // PORT-IB-5: Retrieve list of boards of an institution

      const getBoard = await getBoardsByNameInInstitution(school_id);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards`,
      //   Auth
      // );

      setIsLoading(false);
      setState((prevState) => ({
        ...prevState,
        optBD: getBoard.data.data,
      }));
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message, 'error while fetching data');
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClosePopup = () => {
    // setOpen(false);
    setErrorUpload(false);
  };

  const ErrModal = () => {
    let errHead = ['Error'];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className="Msg">
            <div className="errMsgErrModal">{`${msg}`}</div>
            <div className="closeBtnErrModal" onClick={handleClosePopup}>
              <img src={require('../../../../assets/icons/close.png')} alt="closeBtn" className={styles.subHrErrorModalImg} />
            </div>
          </div>
          <table className="content_table">
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  return (
    <div>
      <div className={styles.mainTop_box}>
        <Box className={styles.top_box} component="div">
          <Box component="div" className={styles.top_box_l}>
            <div>
              <label>Board</label>
              <Box
                component="select"
                className={styles.options}
                onChange={(e) => {
                  changedBoard(e, 'board');
                }}
                value={state.boardId}
              >
                <option value="-1">{'Board'}</option>
                {state.optBD.map((el, key) => (
                  <option value={el.board_id} key={key}>
                    {el.display_name}
                  </option>
                ))}
              </Box>
            </div>
            <div>
              <label>Course</label>
              <Box
                component="select"
                className={styles.options}
                onChange={(e) => {
                  changedBoard(e, 'course');
                }}
                value={state.courseId}
              >
                <option value="-1">{'Course'}</option>
                {state.optCourse.map((el, key) => (
                  <option value={el.course_id} key={key}>
                    {el.display_name}
                  </option>
                ))}
              </Box>
            </div>
            <div>
              <label>Subject</label>
              <Box
                component="select"
                className={styles.options}
                onChange={(e) => {
                  changedBoard(e, 'subject');
                }}
                value={state.subjectId}
              >
                <option value="-1">{'Subject'}</option>
                {state.optSubject.map((el, key) => (
                  <option value={el.subject_id} key={key}>
                    {el.display_name}
                  </option>
                ))}
              </Box>
            </div>
          </Box>
          <Box component="div" className={styles.buttons}>
            <Box component="div" className={styles.buttons_format} onClick={downloadFile}>
              <img src={require('../../../../assets/icons/down.png')} alt="down" className={styles.subHrDownloadImg} />
              Download
            </Box>
            <Box component="div" className={styles.buttons_data}>
              <Formik>
                {(formik) => {
                  return (
                    <div>
                      <Form>
                        <Field
                          type="file"
                          id="filetoupload"
                          name="filetoupload"
                          onChange={(e) => {
                            uploadFile(e);
                            e.target.value = null;
                          }}
                          style={{ display: 'none' }}
                        />
                        <label htmlFor="filetoupload" className={styles.subHrUploadLabel}>
                          <img src={require('../../../../assets/icons/up.png')} alt="up" className={styles.subHrDownloadImg} />
                          Upload
                        </label>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </div>
      {loading ? <ReactLoading type="bars" className="loadingAni" color="#DCEEDC" /> : null}
      {isLoading ? (
        <div className="loading_animation">
          <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
        </div>
      ) : null}
      {noData && (
        <div className="treeDiv">
          <h2 className="noDataH2">No Data Available</h2>
        </div>
      )}
      {treeDisplay ? (
        <div className="treeDiv">
          <div>
            <button onClick={() => expandAndCollapse(true)} className="btnTree">
              Expand All
            </button>
            <button onClick={() => expandAndCollapse(false)} className="btnTree">
              Collapse All
            </button>
            {/* <button
              onClick={() => insertNewNode()}
              className={addPressed ? "btnTreeDisabled" : "btnTree"}
              disabled={addPressed}
            >
              Add More
            </button> */}
            <input placeholder="Search" onChange={(event) => setSearchString(event.target.value)} className="searchTree" />
            <div style={{ height: '400px' }}>
              <SortableTree
                treeData={tree.treeData}
                rowHeight={42}
                maxDepth={maxDepthNode}
                // rowHeight= {maxDepthNode}
                // canDrop={({ nextParent,nextPath }) =>((!nextParent || !nextParent.noChildren) && (nextPath.length>1))}
                canDrop={({ nextParent, nextPath }) => {
                  console.log(nextParent, nextPath);
                  return true;
                }}
                onChange={(treeData) => {
                  setTree({ treeData });
                  // updateTreeData(treeData);
                }}
                onMoveNode={(moveDetails) => {
                  console.log(moveDetails);
                  console.log(moveDetails.node.id, moveDetails.nextParentNode.id);
                }}
                searchQuery={searchString}
                searchFocusOffset={searchFocusIndex}
                generateNodeProps={({ node, path }) => ({
                  title: (
                    <div
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   e.stopPropagation();
                    //   // selectThis(node, path);
                    // }}
                    >
                      <input
                        style={{ fontSize: '1rem', width: 200 }}
                        value={node.title}
                        className={addPressed === true && node.notSaved === true ? 'inputTreeRed' : 'inputTree'}
                        placeholder="Enter here"
                        disabled={addPressed === true && !node.notSaved ? true : false}
                        onChange={(event) => {
                          const title = event.target.value;
                          console.log(title);
                          setAddPressed(true);
                          if (oldNumber < 1) {
                            oldPath = path;
                            oldNode = node;
                          }
                          oldNumber = 2;
                          // updatingNode(title, node);
                          setTree((PrevState) => ({
                            treeData: changeNodeAtPath({
                              treeData: PrevState.treeData,
                              path,
                              getNodeKey,
                              newNode: { ...node, notSaved: true, title },
                            }),
                          }));
                        }}
                      />
                      {/* {node.title_eng && node.title_eng === "" ?  */}
                      <input
                        style={{ fontSize: '1rem', width: 200 }}
                        value={node.title_eng === null ? '' : node.title_eng}
                        className={
                          node.title_eng
                            ? addPressed === true && node.notSaved === true
                              ? 'inputTreeRed'
                              : 'inputTree'
                            : 'inputTreeSmall'
                        }
                        placeholder="Enter here"
                        disabled={addPressed === true && !node.notSaved ? true : false}
                        onChange={(event) => {
                          const title_eng = event.target.value;
                          console.log(title_eng);
                          setAddPressed(true);
                          if (oldNumber < 1) {
                            oldPath = path;
                            oldNode = node;
                          }
                          oldNumber = 2;
                          // updatingNode(title, node);
                          setTree((PrevState) => ({
                            treeData: changeNodeAtPath({
                              treeData: PrevState.treeData,
                              path,
                              getNodeKey,
                              newNode: { ...node, notSaved: true, title_eng },
                            }),
                          }));
                        }}
                      />
                      {/* : null} */}
                      &nbsp;
                      {node.notSaved ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            cancelChange(path, node);
                          }}
                          className={path.length > 1 ? 'plusBtnTree' : 'plusBtnTreeSubject'}
                        >
                          <CancelIcon className="deleteIconRed" />
                        </button>
                      ) : path.length < maxDepthNode ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            nodeUnderParent(path, node);
                          }}
                          className={path.length > 1 ? 'plusBtnTree' : 'plusBtnTreeSubject'}
                          // disabled={addPressed}
                          disabled={addPressed === true && !node.notSaved ? true : false}
                        >
                          <AddCircleIcon className={addPressed ? 'deleteIconDisabled' : 'deleteIcon'} />
                        </button>
                      ) : null}
                      {path.length > 1 ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeNode(path, node);
                          }}
                          className={
                            path.length < maxDepthNode ? 'deleteBtn' : !node.notSaved ? 'deleteBtnOnly' : 'deleteBtnOnlyCancel'
                          }
                          // id={node.title}
                          disabled={addPressed === true && !node.notSaved ? true : false}
                        >
                          <DeleteIcon
                            className={
                              addPressed === true && node.notSaved === true
                                ? // ? addPressed === true && !node.notSaved
                                  'deleteIconRed'
                                : // : "deleteIconRed"
                                  addPressed === true && !node.notSaved
                                  ? 'deleteIconDisabled'
                                  : 'deleteIcon'
                            }
                          />
                        </button>
                      ) : null}
                      {path.length > 1 ? (
                        <button
                          className="saveBtn"
                          onClick={(event) => save(path, node)}
                          disabled={addPressed === true && !node.notSaved ? true : false}
                        >
                          <SaveIcon
                            className={
                              addPressed === true && node.notSaved === true
                                ? // ? addPressed === true && !node.notSaved
                                  'deleteIconRed'
                                : // : "deleteIconRed"
                                  addPressed === true && !node.notSaved
                                  ? 'deleteIconDisabled'
                                  : 'deleteIcon'
                            }
                          />
                        </button>
                      ) : null}
                    </div>
                  ),
                })}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div onClick={cancel} className={popUp ? 'popUpBack' : 'noPopUp'}>
        <div className={popUp ? 'yesPopUp' : 'noPopUp'}>
          <button onClick={cancel} className="popUpCloseBtn">
            <CloseOutlinedIcon className="closeIcon" />
          </button>
          <div className="warningDiv">
            <ReportOutlinedIcon className="warningIcon" />
          </div>
          <h2 className="popUph2">Are You Sure?</h2>
          <div className="popUpButtonDiv">
            <button onClick={cancel} className="popUpCancelButton">
              Cancel
            </button>
            <button onClick={okDelete} className="popUpOkButton">
              OK
            </button>
          </div>
        </div>
      </div>
      {errorUpload ? <ErrModal /> : null}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default SubjectHierarchy;
