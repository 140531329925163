import https from './init/https';

// PORT-IT-1: Download Topics for a Subject via Excel Download
export const downloadTopics = async (school_id, boardId, courseId, subjectId, subSubjectId, medium_id) => {
  return await https.get(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/${subSubjectId}/topics/excel-export?medium_id=${medium_id}`,
    { responseType: 'arraybuffer' },
  );
};

// PORT-IT-2: Add, update, delete topics via excel
export const uploadTopics = async (school_id, boardId, courseId, subjectId, data) => {
  return await https.post(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/topics/excel-import`,
    data,
  );
};

// PORT-IT-3: Retrieve list of Topics
export const getTopicsList = async (school_id, boardId, courseId, subjectId, subSubjectId, medium_id) => {
  return await https.get(
    `/institutions/${school_id}/boards/${boardId}/courses/${courseId}/subjects/${subjectId}/sub_subjects/${subSubjectId}/topics/detailed-info?medium_id=${medium_id}`,
  );
};

// PORT-IT-4: Retrieve list of topics for an institution
export const getTopicsListForInstitution = async (school_id, board_id, course_id, subject_id, subSubject_id) => {
  return await https.get(
    `/institutions/${school_id}/boards/${board_id}/courses/${course_id}/subjects/${subject_id}/sub_subjects/${subSubject_id}/topics`,
  );
};
