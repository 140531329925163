import https from './init/https';

// PORT-AP-01: Get current academic period for a board
export const getAcademicPeriod = async (boardId) => {
  const response = await https.get(`/academic_periods/current?board_id=${boardId}`);
  return response;
};

// PORT-AP-02: Get academic period given board and date
export const getAcademicPeriodByDate = async (boardId, date) => {
  return await https.get(`/academic_periods/fordate?board_id=${boardId}&date=${date}`);
};

// PORT-AP-03: Get all academic periods for a board
export const getAllAcademicPeriods = async (boardId) => {
  return await https.get(`/academic_periods/all?board_id=${boardId}`);
};

// PORT-AP-04: Get academic_periods for an institution
export const getAcademicPeriodsForInstitution = async (institutionId) => {
  return await https.get(`/academic_periods/forinst?institution_id=${institutionId}`);
};
