import https from './init/https';

// PORT-IBI-1: Retrieve institution basic info
export const getInstitutionInfo = async (school_id) => {
  const data = await https.get(`/institutions/${school_id}/basic-info`);
  return data;
};
// ======================
// PORT-IBI-2: Update institution basic info
export const updateInstitutionBasicInfo = async (data, school_id) => {
  return await https.put(`/institutions/${school_id}/basic-info`, data);
};
