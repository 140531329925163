import Axios from 'axios';
import { BASE_URL } from '../../config/Api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const axios = Axios.create({ baseURL: BASE_URL });

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = BASE_URL;
    return config;
  },
  function (error) {
    console.log('error', error.message);
    toast.error(error.message);
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.data) {
      console.log('error', error.response.data.message);
      console.log(error);
      toast.error(error.response.data.message);
    }
    return Promise.reject(error);
  },
);

const https = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

export default https;
