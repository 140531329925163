import React, { useState } from 'react';
import { Divider, Box, Icon } from '@material-ui/core';
import { Redirect, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useStyles } from '../../assets/styles/components/searchBoxStyle';
import TextError from '../../variables/errors/Text_error';
import styles from './Login.module.css';
import DialogBox from '../../components/dialog/DialogBox';
import SearchComponent from '../../components/searchBox/searchBox';
import ButtonComponent from '../../components/button/Button';
import './login.css';
import ReactLoading from 'react-loading';
import { userLogin, getUserInstitutions, userAuthorizeIntoInstitution, createNewInstitution } from '../../api/login';

const addSchoolInit = {
  school_name: '',
  school_code: '',
};

const schoolSchema = Yup.object({
  school_name: Yup.string().email().required('School name is required!'),
  school_code: Yup.string().required('School code is required!'),
});

const Login = () => {
  // const history = useHistory();
  const classes = useStyles();
  const [schoolId, setSchoolId] = useState(null);
  const [state, setState] = useState({
    email: '',
    password: '',
    school_name: '',
    school_code: '',
    formErrors: { email: '', password: '' },
    emailValid: false,
    passwordValid: false,
    formValid: false,
  });
  let loggedIn = false;
  const [LogedIn, setLogedIn] = useState(loggedIn);
  const [token, setToken] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [userSchools, setUserSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  let schoolhead = ['school Code', 'school Name'];
  const stateName = window.location.pathname.slice(1);
  window.localStorage.setItem('stateName', stateName);
  console.log(stateName);

  let user_state_name;
  const clearState = () => {
    setState({
      ...state,
      email: '',
      password: '',
      school_name: '',
      school_code: '',
      formErrors: { email: '', password: '' },
      emailValid: false,
      passwordValid: false,
      formValid: false,
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    let Logindata;
    if (state?.email === '' || state?.password === '') {
      toast.error('Please Enter Username and Password');
    } else {
      setLoading(true);
      try {
        Logindata = await userLogin(state);
        if (Logindata.data.status === true) {
          setLoading(false);
          console.log(Logindata.data.data.result[0]);
          setSchoolId(Logindata.data.data.result[0].institution_id);
          if (Logindata?.data?.data?.result?.[0]?.board_id) {
            window?.localStorage?.setItem('board_id', Logindata?.data?.data?.result?.[0]?.board_id);
          }

          window.localStorage.setItem('token', Logindata.data.data.token);
          window.localStorage.setItem('sessionExp', Logindata.data.data.expiry);
          if (Logindata?.data.data?.result?.[0]?.academic_period) {
            window.localStorage.setItem('institutionData', JSON.stringify(Logindata?.data.data?.result?.[0]?.academic_period));
          }
          // window.localStorage.setItem("sessionExp", Date.now() + 20000);

          setToken(Logindata.data.data.token);
          window.localStorage.setItem('school_id', Logindata.data.data.result[0].institution_id);
          window.localStorage.setItem('cmn_user_email', Logindata.data.data.result[0].email);
          window.localStorage.setItem('cmn_user_full_name', Logindata.data.data.result[0].full_name);
          // window.localStorage.setItem(
          //   "cmn_school_name",
          //   response.data.data.result[0].cmn_school_name
          // );
          window.localStorage.setItem('cmn_user_id', Logindata.data.data.result[0].user_id);
          window.localStorage.setItem('role', Logindata.data.data.result[0].name);
          if (Logindata.data.data.result[0].name === 'BoardAdmin') {
            window.localStorage.setItem('grpData', true);
            window.localStorage.setItem('role', 'BoardAdmin');
          }

          user_state_name = Logindata.data.data.result[0].board_name;

          console.log('stateName', stateName, user_state_name);
          if (stateName && user_state_name !== stateName) {
            toast.error('You are not a board admin for ' + stateName.toUpperCase());
            setLogedIn(false);
            setLoading(false);
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
            return;
          }

          if (Logindata.data.data.result) {
            const fetchData = async () => {
              let result;
              try {
                result = await getUserInstitutions(Logindata.data.data.result[0].user_id);
                console.log(result.data, 'getUserInstitutions');
                result.data.data.forEach((el, key) => {
                  if (el.institution_id === 0) {
                    window.localStorage.setItem('grpData', true);
                    // window.localStorage.setItem("role", "admin");
                  }
                });
                if (result.data.data.length === 0) {
                  setOpenModal(false);
                  setLogedIn(true);
                  toast.success('login successful !');
                } else if (result.data.data.length === 1) {
                  setUserSchools(result.data.data);
                  // setOpenModal(true);
                  setLogedIn(true);
                  toast.success('login successful !');
                } else if (result.data.data.length > 0) {
                  setUserSchools(result.data.data);
                  setOpenModal(true);
                }
              } catch (err) {
                if (err.response && err.response.data) {
                  console.log(err.response.data.message);
                  toast.error(err.response.data.message);
                }
              }
            };
            fetchData();
          } else {
            setLogedIn(true);
            setOpenModal(false);
          }
        } else {
          toast.error(Logindata.message);
          setLogedIn(false);
          setLoading(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      } catch (err) {
        console.log(err, 'error');
        toast.error(err?.response?.data?.message);
        setLogedIn(false);
        setLoading(false);
        clearState();

        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 3000);
      }
    }
    console.log(Logindata, 'login data');
  };

  const onSubmitSchool = async (values, submitProps) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    // console.log(values)
    // PORT-LOGIN-6: Create a new institution
    try {
      await createNewInstitution({
        name: values.school_name,
        code: values.school_code,
      });
      toast.success('School Successfully added!');
      try {
        document.getElementById('searchBox').value = '';
        // PORT-LOGIN-5: Retrieve the available institutions to the logged in user
        const getTableData = await getUserInstitutions(window.localStorage.getItem('cmn_user_id'));

        setUserSchools(getTableData.data.data);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    window.localStorage.clear();
    clearState();
  };

  const getUserInfo = async (e, f) => {
    toast.success(`selected School : ${f}`);
    toast.success('login successful !');
    // PORT-LOGIN-8: Login to an institution
    let res;
    try {
      res = await userAuthorizeIntoInstitution(window?.localStorage?.getItem('cmn_user_id'), e);

      console.log(res, 'instresuser');
      if (res?.data?.data?.result?.academic_period) {
        window?.localStorage?.setItem('institutionData', JSON.stringify(res?.data?.data?.result?.academic_period));
      }
      window?.localStorage?.setItem('token', res?.data?.data?.result?.user_id);

      window?.localStorage?.setItem('role_id', res?.data?.data?.result?.role_id);

      if (res?.data?.data?.result?.board_id) {
        window?.localStorage?.setItem('board_id', res?.data?.data?.result?.board_id);
      }
      if (res?.data?.data?.result?.board_name) {
        window?.localStorage?.setItem('board_name', res?.data?.data?.result?.board_name);
      }
      if (res?.data?.data?.result?.board_code) {
        window?.localStorage?.setItem('board_code', res?.data?.data?.result?.board_code);
      }
      if (res?.data?.data?.result?.institution_id) {
        window?.localStorage?.setItem('institutionId', res?.data?.data?.result?.institution_id);
      }
      window?.localStorage?.setItem('token', res?.data?.data?.token);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }

    setTimeout(() => {
      window.localStorage.setItem('school_id', e);
      window.localStorage.setItem('school_name', f);
      setLogedIn(true);
      setOpenModal(false);
    }, 3000);
  };

  function showPassword() {
    var temp = document.getElementById('password');
    if (temp.type === 'password') {
      temp.type = 'text';
    } else {
      temp.type = 'password';
    }
  }

  if (LogedIn) {
    return (
      <Redirect
        from="/"
        to={window.localStorage.getItem('stateName') ? 'admin/master-data' : '/admin/dashboard'}
        SchoolId={schoolId}
        token={token}
      />
    );
  }

  const forget = () => {
    return <Redirect to="/reset-pasword" />;
  };

  const changedBoard = async (e) => {
    setLoading(true);
    try {
      // PORT-LOGIN-5: Retrieve the available institutions to the logged in user
      const getTableData = await getUserInstitutions(window.localStorage.getItem('cmn_user_id'), e.target.value);

      setUserSchools(getTableData.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {loading ? <ReactLoading type="spin" color="#4fb64b" className={styles.startUploadLoadSpin} /> : null}
      <Box className={styles.header} p={1}>
        <div className={styles.header_body}>
          {stateName ? (
            stateName === 'uttarakhand' ? (
              <h2 className={styles.interactiveH2}>
                E-LEARNING Project for Welfare of Tribal Communities - {stateName.toUpperCase()}{' '}
              </h2>
            ) : stateName === 'rbse' ? (
              <h2 className={styles.interactiveH2}>Classroom Based Digital Assessment System - {stateName.toUpperCase()} </h2>
            ) : stateName === 'seba' ? (
              <h2 className={styles.interactiveH2}>Classroom Based Digital Assessment System - {stateName.toUpperCase()} </h2>
            ) : stateName === 'kseeb' ? (
              <h2 className={styles.interactiveH2}>Classroom Based Digital Assessment System - {stateName.toUpperCase()} </h2>
            ) : stateName === 'bseo' ? (
              <h2 className={styles.interactiveH2}>Classroom Based Digital Assessment System - {stateName.toUpperCase()} </h2>
            ) : stateName === 'mbose' ? (
              <h2 className={styles.interactiveH2}>E-Classroom Meghalaya - {stateName.toUpperCase()} </h2>
            ) : stateName === 'tndet' ? (
              <h2 className={styles.interactiveH2}>Industrial Training Institute Industry 4.0 - {stateName.toUpperCase()}</h2>
            ) : stateName === 'ncffs' ? (
              <h2 className={styles.interactiveH2}>National Curriculum Framework for Foundational Stage - {stateName.toUpperCase()}</h2>
            ): (
              <h2 className={styles.interactiveH2}>Interactive Virtual Classrooms - {stateName.toUpperCase()} </h2>
            )
          ) : (
            <img src={require('../../assets/images/stucle.png')} alt="header-logo" className={styles.image} />
          )}
        </div>
      </Box>
      <Divider style={{ background: '#A6C8A6', height: '1px', width: '100%' }} />
      <main className={styles.body}>
        <div className={styles.left}>
          <form method="post" onSubmit={onSubmit} className={styles.formik}>
            <div className={styles.form_div}>
              <div className={styles.form}>
                <Box className={styles.username} component="div">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Username"
                    value={state.email}
                    onChange={(e) => setState({ ...state, email: e.target.value })}
                    className={styles.username_field}
                  />
                </Box>
                {/* {state?.email === "" ? (
                  <p style={{ color: "red" }}>Please Enter a Valid Username</p>
                ) : null} */}
                <Box className={styles.password} component="div">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={state.password}
                    onChange={(e) => setState({ ...state, password: e.target.value })}
                    className={styles.password_field}
                  />
                  <Box component="span" className={styles.show_password} onClick={() => showPassword()}>
                    show password
                  </Box>
                </Box>
                <ButtonComponent
                  type="submit"
                  className={styles.Button}
                // disabled={!state.email || !state.password ? true : false}
                >
                  <div>Sign in</div>
                  <div style={{ display: 'flex' }}>
                    <Icon style={{ display: 'flex' }}>
                      <ExitToAppIcon />
                    </Icon>
                  </div>
                </ButtonComponent>
              </div>
              <Box component="div" className={styles.forget}>
                <Box className={styles.sign}>
                  <input type="checkbox" name="check" id="check" />
                  <span className={styles.keep}>Keep me signed in</span>
                </Box>
                <Link to="/reset-password">
                  <Box className={styles.forget_password} onClick={() => forget()}>
                    Forgot password ?
                  </Box>
                </Link>
              </Box>
              {/* <Box className={styles.knowMore} component="div">
                <Box component="div" className={styles.not_register}>
                  Not Registred Yet?
                </Box>
                <Box component="div" className={styles.know_more}>
                  Know More
                  <Icon fontSize="small" className={styles.know_more}>
                    <ArrowForwardIosIcon style={{ height: "15px" }} />
                  </Icon>
                </Box>
              </Box> */}
            </div>
          </form>
        </div>
        <div className={styles.right}></div>
      </main>
      {stateName ? (
        <footer className={styles.footer}>&copy; {new Date().getFullYear()} Gumbi Software Pvt Ltd. All rights reserved.</footer>
      ) : (
        <footer className={styles.footer}>
          &copy; {new Date().getFullYear()} Apps'n'Devices Technologies Pvt Ltd. All rights reserved.
        </footer>
      )}
      {openModal ? (
        <DialogBox open={openModal} close={handleClose} style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
          <Box className={styles.school_add}>
            <Box component="div" className={styles.closeLine} p={5}>
              <div className={styles.userInfo}>User School List</div>
              <Box component="div" className={styles.search_box}>
                <div className={classes.search}>
                  <div className="searchIcon">
                    <img src={require('../../assets/icons/search.png')} alt="search" />
                  </div>
                  <SearchComponent
                    id="searchBox"
                    name="searchBox"
                    onChange={(e) => {
                      changedBoard(e);
                    }}
                    classess={classes}
                  />
                </div>
              </Box>
              <div className={styles.close} onClick={handleClose}>
                <img
                  src={require('../../assets/icons/close.png')}
                  alt="close"
                  style={{
                    height: '16px',
                    width: '16px',
                    cursor: 'pointer',
                    border: 'none',
                    outline: 'none',
                  }}
                />
              </div>
            </Box>
            <Box component="div" p={5} className={styles.tbl}>
              <div className={styles.schoolList_Table}>
                <div className={styles.schoolList_Table_head}>
                  {schoolhead.map((elm, key) => (
                    <div key={key} className={styles.schoolList_Table_head_data}>
                      {elm}
                    </div>
                  ))}
                </div>
                <div className={styles.schoolList_Table_body}>
                  {userSchools.map((elm, key) => (
                    <div
                      key={key}
                      onClick={() => getUserInfo(elm.institution_id, elm.institution_name)}
                      className={styles.schoolList_Table_body_row}
                    >
                      {/* <div className={styles.schoolList_Table_body_row_td1}>
                    {elm.cmn_school_id}
                  </div> */}
                      <div className={styles.schoolList_Table_body_row_td2}>{elm.institution_code}</div>
                      <div className={styles.schoolList_Table_body_row_td3}>{elm.institution_name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Box>
            <Box component="div" className={styles.btm} p={5}>
              <Formik
                initialValues={addSchoolInit}
                validationSchema={schoolSchema}
                onSubmit={onSubmitSchool}
                enableReinitialize
                className={styles.addSchool}
              >
                {(formik) => {
                  return (
                    <Form style={{ width: '100%' }}>
                      <div className={styles.bdy}>
                        <div className={styles.lft}>
                          <div className={styles.bx2}>
                            <Field
                              type="text"
                              id="school_code"
                              name="school_code"
                              required={true}
                              placeholder="Enter School Code"
                              className={styles.bx22}
                            />
                          </div>
                          <ErrorMessage name="school_code" component={TextError}>
                            {(error) => <div className="error">{error}</div>}
                          </ErrorMessage>
                          <div className={styles.bx1}>
                            <Field
                              type="text"
                              id="school_name"
                              name="school_name"
                              required={true}
                              placeholder="Enter School Name"
                              className={styles.bx11}
                            />
                          </div>
                          <ErrorMessage name="school_name" component={TextError}>
                            {(error) => <div className="error">{error}</div>}
                          </ErrorMessage>
                        </div>

                        <div className={styles.rgt}>
                          <ButtonComponent
                            type="submit"
                            className={styles.btn}
                          // disabled={!formik.isValid || formik.isSubmitting}
                          >
                            <div>Add School</div>
                          </ButtonComponent>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </DialogBox>
      ) : null}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </div>
  );
};

export default Login;
