import https from './init/https';

// PORT-BH-1:  Check if Board hierarchy exists

export const checkBoardHierarchy = (institution_id, board_id) => {
  return https.get(`/institutions/${institution_id}/boards/${board_id}/hierarchy/check_if_exists`);
};

// PORT-BH-2:  Download the Board Hierarchy Blank Excel Template
export const downloadBoardHierarchyTemplate = async (school_id, board_id) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/hierarchy/excel_export`, {
    responseType: 'arraybuffer',
  });
};

// PORT-BH-3:  Import the Board Hierarchy Excel Template
export const uploadBoardHierarchyExcelFile = async (school_id, board_id, data) => {
  return await https.post(`/institutions/${school_id}/boards/${board_id}/hierarchy/excel_import`, data);
};

// PORT-BH-4: Retrieve the Board Hierarchy for a Board
export const getBoardHierarchyForBoard = async (school_id, board_id) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/hierarchy/`);
};

// PORT-BH-4: Retrieve the Board Hierarchy for a Board Copy
export const getBoardHierarchyForBoardCopy = async (school_id, board_id, course_id) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/hierarchy/?course_id=${course_id}`);
};

// PORT-BH-5: Create a Board Hierarchy Entry
export const createBoardHierarchyEntry = async (school_id, hierarchy_data, board_id) => {
  return await https.post(`/institutions/${school_id}/boards/${board_id}/hierarchy/`, hierarchy_data);
};

// PORT-BH-6: Update a Board Hierarchy Row
export const updateBoardHierarchyRow = async (school_id, board_id, changes) => {
  return await https.put(`/institutions/${school_id}/boards/${board_id}/hierarchy/`, changes);
};

// PORT-BH-7: Delete a Board Hierarchy Row
export const deleteBoardHierarchyRow = async (school_id, board_id, course_id) => {
  return await https.delete(`/institutions/${school_id}/boards/${board_id}/hierarchy/`, course_id);
};

// PORT-BH-8: Reorder a Board Hierarchy Row
export const reorderBoardHierarchyRows = async (school_id, board_id, data) => {
  return await https.put(`/institutions/${school_id}/boards/${board_id}/hierarchy/reorder`, data);
};

// PORT-BH-9: Retrieve the Board Hierarchy Course List
export const getCourseListForBoardHierarchy = async (school_id, board_id) => {
  return await https.get(`/institutions/${school_id}/boards/${board_id}/hierarchy/courses`);
};

// PORT-BH-10: Create a Board Hierarchy Course Entry
export const createBoardHierarchyCourseEntry = async (school_id, board_id, data) => {
  return await https.post(`/institutions/${school_id}/boards/${board_id}/hierarchy/courses`, data);
};

// PORT-BH-11: Update a Board Hierarchy Course Row

export const updateBoardHierarchyCourseRow = async (school_id, board_id, data) => {
  return await https.put(`/institutions/${school_id}/boards/${board_id}/hierarchy/courses`, data);
};

// PORT-BH-12: Delete a Board Hierarchy Course Row
export const deleteBoardHierarchyCourseRow = async (school_id, board_id, deleteData) => {
  return await https.delete(`/institutions/${school_id}/boards/${board_id}/hierarchy/courses`, {
    data: deleteData,
  });
};

// PORT-BH-13: Reorder a Board Hierarchy Course
export const reorderBoardHierarchyCourse = async (school_id, board_id, data) => {
  return await https.put(`/institutions/${school_id}/boards/${board_id}/hierarchy/courses/reorder`, data);
};
