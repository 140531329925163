import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styles from '../../assets/styles/Layout/AdminStyle';
import { Sidebar } from '../index';
import { toast } from 'react-toastify';
// import Axios from "axios";
// import { BASE_URL } from "../../config/Api";

import { routes } from '../../routes';
import {
  Dashboard,
  Status,
  LiveClass,
  Student,
  MasterData,
  GroupData,
  Manage,
  Educator,
  Reports,
  Settings,
} from '../../screens/index';

const switchRoutes = (
  <Switch>
    <Route exact path="/admin/dashboard" component={Dashboard} />
    <Route exact path="/admin/status" component={Status} />
    <Route exact path="/admin/manage" component={Manage} />
    <Route exact path="/admin/master-data" component={MasterData} />
    <Route exact path="/admin/group-data" component={GroupData} />
    <Route exact path="/admin/settings" component={Settings} />
    <Route exact path="/admin/reports" component={Reports} />
    <Route exact path="/admin/status/live-class" component={LiveClass} />
    <Route exact path="/admin/status/educator" component={Educator} />
    <Route exact path="/admin/status/student" component={Student} />
    <Redirect
      from="/admin"
      to={
        window.localStorage.getItem('stateName') === 'nagaland' ||
        window.localStorage.getItem('stateName') === 'uttarakhand' ||
        window.localStorage.getItem('stateName') === 'rbse' ||
        window.localStorage.getItem('stateName') === 'seba'
          ? 'admin/master-data'
          : '/admin/dashboard'
      }
      // to={
      //   window.localStorage.getItem("stateName")
      //     ? "admin/master-data"
      //     : "/admin/dashboard"
      // }
    />
    {/* <Redirect from="/admin" to="admin/master-data" /> */}
  </Switch>
);

const useStyles = makeStyles(styles);
var i = 0;
var timeStampExpire;
var expiryTime;

const Layout = ({ ...rest }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const mainPanel = React.createRef();
  const classes = useStyles();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== null;
  };

  timeStampExpire = window.localStorage.getItem('sessionExp');
  if (window.location.pathname !== '/' && i === 0) {
    expiryTime = timeStampExpire - Date.now();
    console.log(expiryTime, i);
    i = 1;
    if (expiryTime < 0) {
      toast.error('Session has expired. Login again to continue...');
      setTimeout(() => {
        window.localStorage.clear();
        window.location = '/';
      }, 3000);
    } else {
      setTimeout(() => {
        toast.error('Session has expired. Login again to continue...');
        setTimeout(() => {
          window.localStorage.clear();
          window.location = '/';
        }, 3000);
      }, expiryTime);
    }
  }

  if (window.localStorage.getItem('token') === null) {
    if (window.location.pathname !== '/') {
      return (window.location.href = '/');
    }
  }
  return (
    <>
      <div className={classes.wrapper}>
        <Sidebar routes={routes} handleDrawerToggle={handleDrawerToggle} open={mobileOpen} {...rest} />
        <div className={classes.mainPanel} ref={mainPanel}>
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : null}
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Layout;
