import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
// import styled from "styled-components";
import './courseSubject.css';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
// import { object } from "prop-types";

const RowHandler = SortableHandle(() => <div className="handle">|||</div>);

const TableRow = ({
  id,
  rowDetails,
  first,
  second,
  third,
  fourth,
  fifth,
  className,
  handleChange,
  handleSave,
  handleDelete,
  course,
  subject,
  medium,
  taxonomy,
  subjectTag,
  notSaved,
  handleAddRow,
  handleCancel,
}) => {
  return (
    // <TrWrapper className={className}>
    <tr>
      <td>
        <div className="firstElement">
          <RowHandler />
        </div>
      </td>

      <td>
        <select
          value={first}
          onChange={(event) => handleChange(event, 'course_id', id)}
          className={notSaved ? 'tdInputRed' : 'tdInput'}
        >
          <option value="-1">Course</option>
          {course.map((object, optionIndex) => {
            return (
              <option key={optionIndex} value={object.course_id}>
                {object.display_name}
              </option>
            );
          })}
        </select>
      </td>
      <td>
        <select
          value={second}
          onChange={(event) => handleChange(event, 'subject_tag', id)}
          className={notSaved ? 'tdInputRed' : 'tdInput'}
        >
          <option value="-1">Subject Tag</option>
          {subjectTag.map((object, optionIndex) => {
            return (
              <option key={optionIndex} value={object.subject_tag}>
                {object.subject_tag}
              </option>
            );
          })}
        </select>
      </td>
      <td>
        {/* <input
          type="text"
          value={third}
          placeholder="Enter here"
          onChange={(event) => handleChange(event, "subject_name", id)}
          className={notSaved ? "tdInputRed" : "tdInput"}
        />{" "} */}
        <select
          value={third}
          onChange={(event) => handleChange(event, 'subject_id', id)}
          className={notSaved ? 'tdInputRed' : 'tdInput'}
        >
          <option value="-1">Subject</option>
          {subject.map((object, optionIndex) => {
            return (
              <option key={optionIndex} value={object.subject_id}>
                {object.display_name}
              </option>
            );
          })}
        </select>
      </td>
      <td>
        <select
          value={fourth}
          onChange={(event) => handleChange(event, 'medium', id)}
          className={notSaved ? 'tdInputRed' : 'tdInput'}
        >
          <option value="-1">Medium</option>
          {medium.map((object, optionIndex) => {
            return (
              <option key={optionIndex} value={object.medium_name}>
                {object.medium_name}
              </option>
            );
          })}
        </select>
      </td>
      <td>
        <select
          value={fifth}
          onChange={(event) => handleChange(event, 'taxonomy', id)}
          className={notSaved ? 'tdInputRed' : 'tdInput'}
        >
          <option value="-1">Taxonomy</option>
          {taxonomy.map((object, optionIndex) => {
            return (
              <option key={optionIndex} value={object.display_name}>
                {object.display_name}
              </option>
            );
          })}
        </select>
      </td>

      <td>
        {notSaved ? (
          <button onClick={() => handleCancel(id)} className={id % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}>
            <CancelIcon className={notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
          </button>
        ) : (
          <button onClick={() => handleAddRow(id, rowDetails)} className={id % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}>
            <AddCircleIcon className={notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
          </button>
        )}
        <button onClick={() => handleDelete(id, rowDetails)} className={id % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}>
          <DeleteIcon className={notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
        </button>
        <button onClick={() => handleSave(id, rowDetails)} className={id % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}>
          <SaveIcon className={notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
        </button>
      </td>
      {/* // </TrWrapper> */}
    </tr>
  );
};

export default TableRow;
