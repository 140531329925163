import React, { useState, useEffect } from 'react';
// import TableRow from "./TableRow";
// import styled from "styled-components";
// import { SortableContainer, SortableElement } from "react-sortable-hoc";
// import { ITEMS } from "./data";
// import arrayMove from "./ArrayMove";
import './subject.css';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { Box } from '@material-ui/core';
import styles from '../SubjectHierarchy/TopicStyle.module.css';
import { Formik, Form, Field } from 'formik';
import { Dialogbox } from '../../../../components/index';
import ReactLoading from 'react-loading';
// import { MultiSelect } from "react-multi-select-component";
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Select from 'react-dropdown-select';
import { getAllMediums } from '../../../../api/mediums';
import {
  getAllSubjectDetailsByInstitute,
  addNewSubjectToCourse,
  updateSubjectInCourse,
  deleteSubjectFromCourse,
  addTagToSubject,
} from '../../../../api/InstitutionSubjects';
import { getSubjectTagsForBoardAPI } from '../../../../api/insitutionCourseSubjectLinking';
import { downloadBoardHierarchyTemplate, uploadBoardHierarchyExcelFile } from '../../../../api/boardHierarchy';
import { getTaxonomiesList } from '../../../../api/taxonomy';
import { getBoardsByNameInInstitution } from '../../../../api/institutionBoards';

var tempObject;
var tempItems;
// var clickedRow;
var savedCount = 0;
var school_id = -1;
var board_id = -1;
var tempId;
var rowData;
var deleteData;
let addId = 0;
var cancelCount = 0;
// var prevSubjectId;
// var nextSubjectId;
// var moveData;
// var newId;
// var tempSubjectTagArray = [];
// var tempSelectedSubjectTag = [];
var tempTableData = [];
// var subjectTagSelected = [];

// const SortableCont = SortableContainer(({ children }) => {
//   return <tbody>{children}</tbody>;
// });

// const SortableItem = SortableElement((props) => <TableRow {...props} />);

const MyTable = () => {
  const [items, setItems] = useState([]);
  const [state, setState] = useState({
    boardOptions: [],
    mediumOptions: [],
    taxonomyOptions: [],
    subjectTagOptions: [],
  });
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [msg, setMsg] = useState('');
  // const [open, setOpen] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  // const [move, setMove] = useState(false);
  // const [selected, setSelected] = useState([]);

  console.log(items, deleteData);
  school_id = window.localStorage.getItem('school_id');
  window.localStorage.setItem('tabIndex', 2);
  const token = window.localStorage.getItem('token');
  const Auth = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  async function fetchTableData() {
    addId = 1000;
    cancelCount = 1000;
    console.log(addId);
    setLoading(true);
    try {
      // PORT-IS-3: Retrieve list of subjects with details for an institution

      const getTableData = await getAllSubjectDetailsByInstitute(school_id, board_id);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subjects/detailed-info`,
      //   Auth
      // );
      // PORT-ICS-01: Retrieve list of Subject Tags for a Board
      const getSubjectTag = await getSubjectTagsForBoardAPI(school_id, board_id, Auth);
      //   await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subject_tags`,
      //   Auth
      // );
      console.log(getTableData.data.data);
      // tempSubjectTagArray = getSubjectTag.data.data;
      // tempSubjectTagArray = tempSubjectTagArray.map((subject) => {
      //   return { value: subject.subject_tag, label: subject.subject_tag };
      // });
      if (getTableData.data.data.length === 0) {
        tempTableData = [
          {
            display_name: '',
            medium_name: '',
            no_of_courses_linked: 0,
            subject_id: 100000,
            subject_tag: [{ subject_tag: '' }],
            taxonomy_name: '',
            translated_display_name: '',
          },
        ];
        console.log(tempTableData);
        setItems(tempTableData);
      } else {
        tempTableData = getTableData.data.data;
        tempTableData = tempTableData.map((row) => {
          // row.subject_tag(())
          return { ...row, subject_tag: [{ subject_tag: row.subject_tag }] };
        });
        // tempSelectedSubjectTag = getTableData.data.data.subject_tag;
        // tempSelectedSubjectTag = Array.isArray(tempSelectedSubjectTag)
        //   ? tempSelectedSubjectTag.map((subject) => {
        //       return { value: subject.subject_tag, label: subject.subject_tag };
        //     })
        //   : [];
        console.log(tempTableData);
        setItems(tempTableData);
      }
      // setSelected(tempSelectedSubjectTag);
      setState({
        ...state,
        // subjectTagOptions: tempSubjectTagArray,
        subjectTagOptions: getSubjectTag.data.data,
      });
      console.log(getSubjectTag.data.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  }

  // const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
  //   console.log(oldIndex, newIndex);
  //   setLoading(true);
  //   setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  //   newId = newIndex;
  //   setMove(true);
  // }, []);

  // async function handleMove() {
  //   console.log(items);
  //   // if (items[newId - 1].course_id === items[newId + 1].course_id) {
  //   prevSubjectId = items[newId - 1]
  //     ? items[newId - 1].course_id === items[newId + 1].course_id
  //       ? items[newId - 1].subject_id
  //       : -1
  //     : -1;
  //   nextSubjectId = items[newId + 1] ? items[newId + 1].subject_id : -1;
  //   // }
  //   console.log(prevSubjectId, nextSubjectId);
  //   moveData = {
  //     subject_id: items[newId].subject_id,
  //     prev_subject_id: prevSubjectId,
  //     next_subject_id: nextSubjectId,
  //   };
  //   try {
  //     const moveRow = await Axios.put(
  //       `${BASE_URL}/institutions/${school_id}/boards/${board_id}/hierarchy/reorder`,
  //       moveData,
  //       Auth
  //     );
  //     console.log(moveRow);
  //     setMove(false);
  //     toast.success("Moved Successfully");
  //     fetchTableData();
  //   } catch (error) {
  //     if (error.response && error.response.data) {
  //       console.log(error.response.data.message);
  //       toast.error(error.response.data.message);
  //     }
  //     setMove(false);
  //     fetchTableData();
  //   }
  // }

  // useEffect(() => {
  //   if (move === true) {
  //     handleMove();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [move]);

  function handlechange(event, first, id) {
    var text = event.target.value;
    console.log(text, first, id);
    if (savedCount === 0) {
      // clickedRow = items[id];
      savedCount = savedCount + 1;
    }
    tempObject = items;
    tempObject = tempObject.map((item, i) => {
      if (i === id) {
        console.log(123);
        return { ...item, [first]: text, notSaved: true };
      } else {
        return item;
      }
    });
    console.log(tempObject);
    setItems(tempObject);
  }

  function handleSubjectTag(value, name, id) {
    console.log(value, name, id);
    console.log(savedCount, cancelCount, addId);
    if (+savedCount === 0 && +cancelCount === 0) {
      // clickedRow = items[id];
      savedCount = savedCount + 1;
      // cancelCount = 1000;
      console.log(648);
    }
    // if(+id === +addId  && +cancelCount === 0){
    if (+id === +addId) {
      console.log(649);
      addId = 1000;
      cancelCount = 1000;
      tempObject = items;
      tempObject = tempObject.map((item, i) => {
        if (i === id) {
          console.log(123);
          return { ...item, [name]: value, notSaved: true };
        } else {
          return item;
        }
      });
      console.log(tempObject);
      setItems(tempObject);
    } else {
      console.log('wrong');
    }
  }

  async function handleCreateSubjectTag(item) {
    setLoading(true);
    console.log(item, 'items');
    console.log(item.subject_tag);
    // try {
    console.log(568);
    // const createSubjectTag = await Axios.post(
    //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subject-tags?subject_tag=${item.subject_tag}`,
    //   Auth
    // );
    // PORT-IS-8: Add Subject Tag
    try {
      const response = await addTagToSubject(school_id, board_id, item?.subject_tag);
      console.log(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
    // await Axios({
    //   url: `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subject-tags?subject_tag=${item?.subject_tag}`,
    //   mode: "no-cors",
    //   method: "POST",
    //   headers: {
    //     ...Auth.headers,
    //   },
    // })
    //   .then((response) => {
    //     console.log(response.data.data);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });

    try {
      // PORT-ICS-01: Retrieve list of Subject Tags for a Board
      const getSubjectTag = await getSubjectTagsForBoardAPI(school_id, board_id, Auth);
      // await Axios.get(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subject_tags`,
      //   Auth
      // );
      setState({
        ...state,
        // subjectTagOptions: tempSubjectTagArray,
        subjectTagOptions: getSubjectTag.data.data,
      });
      console.log(getSubjectTag);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
    // } catch (error) {
    //   console.log(error);
    //   if (error.response && error.response.data) {
    //     console.log(error.response.data.message);
    //     toast.error(error.response.data.message);
    //   }
    setLoading(false);
  }

  // }

  //   function addRow() {
  //     console.log(678);
  //     tempItems = items;
  //     tempItems.push({
  //       first: "New",
  //       second: "New",
  //       third: "New",
  //       fourth: "New",
  //       notSaved: true,
  //     });
  //     setItems([...tempItems]);
  //   }

  async function handleSave(id, rowDetails) {
    setLoading(true);

    // tempSelectedSubjectTag = tagValues.map((subject) => {
    //   return { subject_tag_id: subject.value, subject_tag: subject.label };
    // });
    tempObject = items;
    addId = 1000;
    cancelCount = 0;
    tempObject = tempObject.map((item, i) => {
      if (i === id) {
        console.log(123);
        return { ...item, notSaved: false };
      } else {
        return item;
      }
    });

    setItems(tempObject);

    if (rowDetails?.subject_id >= 100000) {
      rowData = {
        subject_tag: rowDetails?.subject_tag[0]?.subject_tag,
        subject_display_name: rowDetails.display_name,
        translated_subject_name: rowDetails.translated_display_name,
        medium_name: rowDetails.medium_name,
        taxonomy_name: rowDetails.taxonomy_name,
      };
      console.log(rowData, 4567);
      try {
        // PORT-IS-5: Add new Subject
        const createBoard = await addNewSubjectToCourse(school_id, board_id, rowData);
        // await Axios.post(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subjects`,
        //   rowData,
        //   Auth
        // );
        console.log(createBoard.data.data);
        toast.success('Successfuly created new subject');
        fetchTableData();
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        fetchTableData();
      }
    } else {
      rowData = {
        subject_tag: rowDetails?.subject_tag[0]?.subject_tag,
        subject_display_name: rowDetails.display_name,
        translated_subject_name: rowDetails.translated_display_name,
        medium_name: rowDetails.medium_name,
        taxonomy_name: rowDetails.taxonomy_name,
      };
      console.log(rowData, 789);
      try {
        // PORT-IS-6: Update a Subject
        const createBoard = await updateSubjectInCourse(school_id, board_id, rowDetails.subject_id, rowData);
        // await Axios.put(
        //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subjects/${rowDetails.subject_id}`,
        //   rowData,
        //   Auth
        // );
        console.log(createBoard);
        toast.success('Successfuly updated');
        fetchTableData();
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        fetchTableData();
      }
    }
  }

  async function handleDelete(id, rowDetails) {
    tempItems = items;
    tempItems.splice(id, 1);
    setItems([...tempItems]);
    deleteData = {
      course_id: rowDetails.course_id,
      subject_id: rowDetails.subject_id,
    };
    try {
      // PORT-IS-7: Delete a Subject
      const deleteRow = await deleteSubjectFromCourse(school_id, board_id, rowDetails.subject_id);
      // await Axios.delete(
      //   `${BASE_URL}/institutions/${school_id}/boards/${board_id}/subjects/${rowDetails.subject_id}`,
      //   {
      //     // data: deleteData,
      //     headers: Auth.headers,
      //   }
      // );
      console.log(deleteRow.data.data);
      toast.success('Successfuly deleted subject');
      fetchTableData();
    } catch (error) {
      if (error.response && error.response.data) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      fetchTableData();
    }
  }

  async function handleAddRow(id, rowDetails) {
    tempItems = items;
    cancelCount = 1000;
    addId = id;
    tempId = +rowDetails.subject_id + 100000;
    tempItems.splice(id + 1, 0, {
      // ...rowDetails,
      no_of_courses_linked: '',
      taxonomy_name: '',
      notSaved: true,
      subject_id: tempId,
      display_name: '',
      medium_name: '',
      translated_display_name: '',
      subject_tag: [],
    });
    console.log(tempItems);
    setItems([...tempItems]);
  }

  function handleCancel(id) {
    addId = 1000;
    console.log(savedCount, addId);
    cancelCount = 1000;
    fetchTableData();
    // if (savedCount !== 0) {
    //   console.log(324);
    //   tempItems = items;
    //   tempItems.splice(id, 1);
    //   console.log(clickedRow);
    //   tempItems.splice(id, 0, { ...clickedRow, notSaved: false });
    //   setItems([...tempItems]);
    //   savedCount = 0;
    // } else if (savedCount === 0) {
    //   console.log(657)
    //   tempItems = items;
    //   tempItems.splice(id, 1);
    //   setItems([...tempItems]);
    // }
  }

  useEffect(() => {
    setLoading(true);
    const fetchBoard = async () => {
      try {
        // PORT-IB-5: Retrieve list of boards of an institution

        const getboard = await getBoardsByNameInInstitution(school_id);
        // await Axios.get(
        //   `${BASE_URL}/institutions/${school_id}/boards`,
        //   Auth
        // );
        console.log(getboard.data.data, 'board');
        // PORT-MD-1: Retrieve list of all mediums
        const getMedium = await getAllMediums(Auth);
        // const getMedium = await Axios.get(`${BASE_URL}/mediums/`, Auth);
        const getTaxonomy = await getTaxonomiesList();
        // await Axios.get(`${BASE_URL}/taxonomies`, Auth);

        setState({
          ...state,
          boardOptions: getboard?.data?.data,
          mediumOptions: getMedium.data.data,
          taxonomyOptions: getTaxonomy.data.data,
        });

        console.log(getTaxonomy.data.data);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.data) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
        setLoading(false);
      }
    };
    console.log('board');
    fetchBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFile = async () => {
    // try {
    // PORT-BH-2:  Download the Board Hierarchy Blank Excel Template

    try {
      if (board_id === -1 && board_id) {
        toast.error('Please Select a Board');
      } else {
        // PORT-BH-2:  Download the Board Hierarchy Blank Excel Template
        const response = await downloadBoardHierarchyTemplate(school_id, board_id);
        let filename = response.headers['content-disposition'].split('=')[1];
        var blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });
        saveAs(blob, filename);
        toast.success('file downloaded');
      }
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      }
    }

    //   await Axios.get(
    //     `${BASE_URL}/institutions/${school_id}/boards/${board_id}/hierarchy/excel_export`,
    //     {
    //       // ...Auth,
    //       responseType: "arraybuffer",
    //     }
    //   ).then((response) => {
    //     // console.log(response)
    //     let filename = response.headers["content-disposition"].split("=")[1];
    //     var blob = new Blob([response.data], {
    //       type: "application/octet-stream",
    //     });
    //     saveAs(blob, filename);
    //     toast.success("file downloaded");
    //   });
    //   // }
    // } catch (err) {
    //   if (err.response && err.response.data) {
    //     console.log(err.response.data.message);
    //     toast.error(err.response.data.message);
    //   }
    // }
  };

  const uploadFile = async (e) => {
    if (board_id !== -1) {
      let formdata = new FormData();
      let file = e.target.files[0];
      setLoading(true);

      formdata.append('filetoupload', file);
      // PORT-BH-3:  Import the Board Hierarchy Excel Template
      const auth = {
        headers: {
          // ...Auth.headers,
          'Content-Type': 'multipart/form-data',
          Accept: '*',
          type: 'formData',
          'Access-Control-Allow-Headers': '*',
        },
      };
      try {
        // PORT-BH-3:  Import the Board Hierarchy Excel Template
        await uploadBoardHierarchyExcelFile(school_id, board_id, formdata, auth);
        setLoading(false);
      } catch (err) {
        if (err.response && err.response.data) {
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
        }
        setLoading(false);
        if (err) {
          setErrorUpload(true);
          console.log(err.response.data.message);
          toast.error(err.response.data.message);
          console.log(err.response.data.data);
          setErrMsg(err.response.data.data);
          setMsg(err.response.data.message);
        }
      }

      // Axios({
      //   url: `${BASE_URL}/institutions/${school_id}/boards/${board_id}/hierarchy/excel_import`,
      //   mode: "no-cors",
      //   method: "POST",
      //   headers: {
      //     // ...Auth.headers,
      //     "Content-Type": "multipart/form-data",
      //     Accept: "*",
      //     type: "formData",
      //     "Access-Control-Allow-Headers": "*",
      //   },
      //   data: formdata,
      // })
      //   .then(async (res) => {
      //     // console.log(res)
      //     toast.success("File Uploaded Successfully");
      //     fetchTableData();
      //   })
      //   .catch((err) => {
      //     if (err.response && err.response.data) {
      //       console.log(err.response.data.message);
      //       toast.error(err.response.data.message);
      //     }
      //     setLoading(false);
      //     if (err) {
      //       setErrorUpload(true);
      //       console.log(err.response.data.message);
      //       toast.error(err.response.data.message);
      //       console.log(err.response.data.data);
      //       setErrMsg(err.response.data.data);
      //       setMsg(err.response.data.message);
      //     }
      //   });
    } else {
      toast.error('Select all the dropdowns');
    }
  };

  const changedBoard = async (e, selectedvalue) => {
    console.log('123');
    setLoading(true);
    if (+e.target.value === -1) {
      board_id = e.target.value;
      setItems([]);
      setLoading(false);
    } else {
      if (selectedvalue === 'board') {
        board_id = e.target.value;
        fetchTableData();
      }
    }
  };

  const handleClosePopup = () => {
    // setOpen(false);
    setErrorUpload(false);
  };

  const ErrModal = () => {
    let errHead = ['Error'];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className="Msg">
            <div className="errMsgErrModal">{`${msg}`}</div>
            <div className="closeBtnErrModal" onClick={handleClosePopup}>
              <img
                src={require('../../../../assets/icons/close.png')}
                alt="closeBtn"
                style={{ height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <table className="content_table">
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  return (
    <div>
      {loading ? <ReactLoading type="bars" color="#4fb64b" className="startUploadLoadSpin" /> : null}
      <div className={styles.mainTop_box}>
        <Box className={styles.top_box} component="div">
          <Box component="div" className={styles.top_box_l}>
            <div>
              <label>Board</label>
              <Box
                component="select"
                className={styles.options}
                onChange={(e) => {
                  changedBoard(e, 'board');
                }}
              >
                <option value="-1">{'Board'}</option>
                {state.boardOptions.map((el, key) => (
                  <option value={el.board_id} key={key}>
                    {el.display_name}
                  </option>
                ))}
              </Box>
            </div>
          </Box>
          <Box component="div" className={styles.buttons}>
            <Box component="div" className={styles.buttons_format} onClick={downloadFile}>
              <img src={require('../../../../assets/icons/down.png')} alt="down" style={{ paddingRight: '10px' }} />
              Download
            </Box>
            <Box component="div" className={styles.buttons_data}>
              <Formik>
                {(formik) => {
                  return (
                    <div>
                      <Form>
                        <Field
                          type="file"
                          id="filetoupload"
                          name="filetoupload"
                          onChange={(e) => {
                            uploadFile(e);
                            e.target.value = null;
                          }}
                          style={{ display: 'none' }}
                        />
                        <label
                          htmlFor="filetoupload"
                          style={{
                            width: '120px',
                            height: '32px',
                            marginBottom: '0px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '10px',
                            color: 'rgb(155, 139, 139)',
                            cursor: 'pointer',
                            textAlign: 'center',
                            padding: '11px',
                          }}
                        >
                          <img src={require('../../../../assets/icons/up.png')} alt="up" style={{ paddingRight: '10px' }} />
                          Upload
                        </label>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </div>
      {/* <div>
        <button onClick={addRow}>Add Row</button>
      </div> */}
      {/* <MyTableWrapper> */}
      {/* <table className="table table-dark fixed_header"> */}
      {items !== [] ? (
        <table className="boardTable">
          <thead>
            <tr className="theadDragTable">
              {/* <th>Move</th> */}
              <th>Subject</th>
              <th>Subject Tag</th>
              <th>Medium</th>
              <th>Taxonomy</th>
              <th>Translated Name</th>
              <th>Action</th>
            </tr>
          </thead>
          {/* <SortableCont
            onSortEnd={onSortEnd}
            axis="y"
            lockAxis="y"
            lockToContainerEdges={true}
            lockOffset={["30%", "50%"]}
            helperClass="helperContainerClass"
            useDragHandle={true}
          >
            {items.map((value, index) => (
              <SortableItem
                key={`item-${index}`}
                handleChange={handlechange}
                index={index}
                id={index}
                rowDetails={value}
                handleSave={handleSave}
                handleDelete={handleDelete}
                handleAddRow={handleAddRow}
                handleCancel={handleCancel}
                medium={state.mediumOptions}
                taxonomy={state.taxonomyOptions}
                subjectTag={state.subjectTagOptions}
                notSaved={value.notSaved ? true : false}
                first={selected}
                second={value.subject_name}
                // setSelectedParent={setSelected}
                third={value.medium_id}
                fourth={value.translated_subject_name}
                fifth={value.taxonomy_id}
              />
            ))}
          </SortableCont> */}
          <tbody>
            {items.map((row, rowIndex) => {
              return (
                <tr
                  key={rowIndex}
                  // onClick={(e) => {
                  //   addId = rowIndex;
                  //   console.log(addId);
                  // cancelCount = 0;
                  // }}
                >
                  <td>
                    <input
                      type="text"
                      value={row.display_name}
                      placeholder="Enter here"
                      onChange={(event) => handlechange(event, 'display_name', rowIndex)}
                      className={row.notSaved ? 'tdInputRed' : 'tdInput'}
                    />
                  </td>
                  <td
                    onClick={(e) => {
                      addId = rowIndex;
                      console.log(addId);
                      // cancelCount = 0;
                    }}
                  >
                    {/* <MultiSelect
                      options={state.subjectTagOptions}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                      hasSelectAll={false}
                      className={row.notSaved ? "tdInputRed" : "tdInput"}
                      isCreatable={true}
                    /> */}
                    <Select
                      options={state.subjectTagOptions}
                      // values={[]}
                      values={row.subject_tag}
                      valueField="subject_tag"
                      labelField="subject_tag"
                      searchBy="subject_tag"
                      searchable={true}
                      create={true}
                      // autoFocus={true}
                      // placeholder="subjectTag"
                      // keepSelectedInList={false}
                      // sortBy="company.catchPhrase"
                      onChange={(value) => handleSubjectTag(value, 'subject_tag', rowIndex)}
                      // onDropdownClose={(value) =>
                      //   handleSubjectTag(value, "subject_tag", rowIndex)
                      // }
                      className={row.notSaved ? 'tdInputRed' : 'tdInput'}
                      onCreateNew={(item) => handleCreateSubjectTag(item)}
                    />
                  </td>
                  <td>
                    <select
                      value={row.medium_name}
                      onChange={(event) => handlechange(event, 'medium_name', rowIndex)}
                      className={row.notSaved ? 'tdInputRed' : 'tdInput'}
                    >
                      <option value="-1">Medium</option>
                      {state.mediumOptions.map((object, optionIndex) => {
                        return (
                          <option key={optionIndex} value={object.medium_name}>
                            {object.medium_name}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td>
                    <select
                      value={row.taxonomy_name}
                      onChange={(event) => handlechange(event, 'taxonomy_name', rowIndex)}
                      className={row.notSaved ? 'tdInputRed' : 'tdInput'}
                    >
                      <option value="-1">Taxonomy</option>
                      {state.taxonomyOptions.map((object, optionIndex) => {
                        return (
                          <option key={optionIndex} value={object.display_name}>
                            {object.display_name}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={row.translated_display_name}
                      placeholder="Enter here"
                      onChange={(event) => handlechange(event, 'translated_display_name', rowIndex)}
                      className={row.notSaved ? 'tdInputRed' : 'tdInput'}
                    />
                  </td>
                  <td>
                    {row.notSaved ? (
                      <button
                        onClick={() => handleCancel(rowIndex)}
                        className={rowIndex % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}
                      >
                        <CancelIcon className={row.notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
                      </button>
                    ) : (
                      <button
                        onClick={() => handleAddRow(rowIndex, row)}
                        className={rowIndex % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}
                      >
                        <AddCircleIcon className={row.notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
                      </button>
                    )}
                    <button
                      onClick={() => handleDelete(rowIndex, row)}
                      className={rowIndex % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}
                    >
                      <DeleteIcon className={row.notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
                    </button>
                    <button
                      onClick={() => handleSave(rowIndex, row)}
                      className={rowIndex % 2 !== 0 ? 'tableDragBtnEven' : 'tableDragBtnOdd'}
                    >
                      <SaveIcon className={row.notSaved ? 'tableDragDeleteIconRed' : 'tableDragDeleteIcon'} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
      {/* </MyTableWrapper> */}
      {/* <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default MyTable;
