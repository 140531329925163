import https from './init/https';

// PORT-IC-1: download courses of an institution via excel export
export const getCoursesByInstitutionIdExcel = async (school_id, boardId) => {
  return await https.get(`/institutions/${school_id}/boards/${boardId}/courses/excel-export`, {
    responseType: 'arraybuffer',
  });
};

// PORT-IC-2: Add, update, delete courses to an institution via excel import

export const uploadCourseToSchool = async (school_id, data) => {
  return await https.post(`/institutions/${school_id}/courses/excel-import`, data, {
    'Content-Type': 'multipart/form-data',
    Accept: '*',
    type: 'formData',
    'Access-Control-Allow-Headers': '*',
  });
};

// PORT-IC-3: Retrieve detailed info of the courses of an institution
export const getAllCoursesOfAInstitution = async (school_id, boardId) => {
  return await https.get(`/institutions/${school_id}/boards/${boardId}/courses/detailed-info`);
};

// PORT-IC-4: Retrieve list of courses of an institution
export const getCoursesListOfAnInstitution = async (school_id, board_id) => {
  const response = await https.get(`/institutions/${school_id}/boards/${board_id}/courses`);
  console.log(response, 'resp');
  return response;
};
