import AWS from 'aws-sdk';
// console.log(process.env.REACT_APP_ACCESS_KEY_ID);
// console.log(process.env.REACT_APP_END_POINT);
// console.log(process.env.REACT_APP_SECRET_KEY);
const s3 = new AWS.S3({
  endpoint: process.env.REACT_APP_END_POINT,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
});

export default s3;
