// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageStyle_txt__7uYmc{
    text-align: center;
    font-weight: 600;
    padding-left: 7px;
    margin-bottom: 8px;
}
.ManageStyle_dashboard_txt__J60i0{
    color: #2E2B2B;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .ManageStyle_manage__rCyjo{
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      flex-direction: row;
  }
  .ManageStyle_search_box__OLuIM,
.ManageStyle_download_list__I2Zf6 {
  margin-right: 15px;
}
.ManageStyle_search_box__OLuIM {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  width: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/screens/Manage/ManageStyle.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;EACrB;EACA;MACI,aAAa;MACb,8BAA8B;MAC9B,mBAAmB;MACnB,qBAAqB;MACrB,mBAAmB;EACvB;EACA;;EAEA,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".txt{\n    text-align: center;\n    font-weight: 600;\n    padding-left: 7px;\n    margin-bottom: 8px;\n}\n.dashboard_txt{\n    color: #2E2B2B;\n    font-size: 16px;\n    line-height: 23px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  .manage{\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      align-content: center;\n      flex-direction: row;\n  }\n  .search_box,\n.download_list {\n  margin-right: 15px;\n}\n.search_box {\n  border: 1px solid #e3e3e3;\n  border-radius: 10px;\n  width: auto;\n  height: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt": `ManageStyle_txt__7uYmc`,
	"dashboard_txt": `ManageStyle_dashboard_txt__J60i0`,
	"manage": `ManageStyle_manage__rCyjo`,
	"search_box": `ManageStyle_search_box__OLuIM`,
	"download_list": `ManageStyle_download_list__I2Zf6`
};
export default ___CSS_LOADER_EXPORT___;
