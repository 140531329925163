import https from './init/https';

// PORT-LOGIN-1: "Set password" mailer
export const sendResetPasswordMail = async (email) => {
  const res = await https.post(`/mail/sendmail`, { email });
  return res;
};
//=================================

// PORT-LOGIN-2: Check validity of the emailed token
export const checkTokenValidity = async (user_id) => {
  const res = await https.post(`/user/check-renew`, { user_id });
  return res.data.isValid;
};
// ===============================

// PORT-LOGIN-3: Set password
export const setPassword = async (user_id, password) => {
  const res = await https.post(`/user/save`, {
    user_id,
    password,
  });
  return res;
};
// ========================

// PORT-LOGIN-4: Signin
export const userLogin = async (state) => {
  const loginData = await https.post(`/user/signin`, {
    email: state.email,
    password: state.password,
  });

  return loginData;
};
// =================================================

// PORT-LOGIN-5: Retrieve the available institutions to the logged in user
export const getUserInstitutions = async (userId, search_text) => {
  // Send a request with this token and receive data about the authorized user's institutions
  const response = await https.get(`/user/${userId}/institutions?search_text=${search_text ? search_text : ''}`);

  return response;
};
// ====================================================
// PORT-LOGIN-6: Create a new institution
export const createNewInstitution = async (data) => {
  const response = await https.put(`/institution/new`, data);
  return response;
};
// PORT-LOGIN-8: Login to an institution
export const userAuthorizeIntoInstitution = async (instId, id) => {
  const response = await https.post(`/user/${instId}/login-to-inst/${id}`);

  return response;
};
// ========================================
