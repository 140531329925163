// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupdataStyle_dashboard_txt__gXgLv{
    color: #2E2B2B;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
.GroupdataStyle_txt__VbtJA{
    text-align: center;
    font-weight: 600;
    padding-left: 7px;
    margin-bottom: 8px;
}

.GroupdataStyle_groupDataTab__ma1kw{
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/screens/GroupData/GroupdataStyle.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;EACrB;AACF;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dashboard_txt{\n    color: #2E2B2B;\n    font-size: 16px;\n    line-height: 23px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n.txt{\n    text-align: center;\n    font-weight: 600;\n    padding-left: 7px;\n    margin-bottom: 8px;\n}\n\n.groupDataTab{\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_txt": `GroupdataStyle_dashboard_txt__gXgLv`,
	"txt": `GroupdataStyle_txt__VbtJA`,
	"groupDataTab": `GroupdataStyle_groupDataTab__ma1kw`
};
export default ___CSS_LOADER_EXPORT___;
