import https from './init/https';

// PORT-GI-1: Excel Export of Group Institutions
export const getGroupExcelExport = async () => {
  return await https.get(`/group_institutions/excel-export?search_text=`, { responseType: 'arraybuffer' });
};

// PORT-GI-2: Excel Import of Group Insititutions
export const postGroupExcelImport = async (file) => {
  return await https.post(`/group_institutions/excel-import`, file);
};

// PORT-GI-3: Display Group Institution List as a Table
export const getGroupInstListTableData = async (searchKey) => {
  return await https.get(`/group_institutions/search?search_text=${searchKey}`);
};

// PORT-GI-4: Retrieve Info about a Group Institution
export const getOneGroupInfo = async (grpInst_id) => {
  return await https.get(`/group_institutions/${grpInst_id}/basic_info`);
};

// PORT-GI-5: Retrieve Child Group Institutions
export const getChildGroups = async (group_institution_id) => {
  return await https.get(`/group_institutions/${group_institution_id}/child_group_institutions`);
};

// PORT-GI-6: Save Group Insitution Basic Info
export const putBasicInfo = async (group_institution_id, data) => {
  return await https.put(`/group_institutions/${group_institution_id}`, data);
};

// PORT-GI-7: Excel Export of Insitutions within a Group
export const downloadInsWithinGroup = async (group_institution_id) => {
  return await https.get(`/group_institutions/${group_institution_id}/institutions/excel-export`, { responseType: 'arraybuffer' });
};

// PORT-GI-8: Excel Import of Insitutions for a Group Insitution
export const uploadInsToGroup = async (group_institution_id, data) => {
  return await https.post(`/group_institutions/${group_institution_id}/institutions/excel-import`, data);
};

// PORT-GI-9: Display list of insitutions for a Group Insitution
export const getAllInstitutionsOfGrp = async (group_institution_id) => {
  return await https.get(`/group_institutions/${group_institution_id}/institutions`);
};

// PORT-GI-10: Excel Export of Group Educators for a Group Institution
export const downloadEducatorsForGroup = async (group_institution_id) => {
  return await https.get(`/group_institutions/${group_institution_id}/group_educators/excel-export`, {
    responseType: 'arraybuffer',
  });
};

// PORT-GI-11: Excel Import of Group Educators for a Group Institution
export const addNewEducatorstoGroup = async (group_institution_id, data) => {
  return await https.post(`/group_institutions/${group_institution_id}/group_educators/excel-import`, data);
};

//PORT-GI-12: Display list of Group Educators for a Group Institution
export const getListofEducatorsforGroup = async (group_institution_id) => {
  return await https.get(`/group_institutions/${group_institution_id}/group_educators`);
};
/* ------------------------------- GROUP CLASS METHODS ---------------------------- */
