import https from './init/https';

// PORT-IB-1: Add a board to an institution
export const addBoardToInstitution = async (school_id, data) => {
  return await https.post(`/institutions/${school_id}/boards`, data);
};
// ======================

// PORT-IB-2: Retrieve detailed info of boards of an institution
export const getBoardsOfInstitution = async (school_id) => {
  return await https.get(`/institutions/${school_id}/boards/detailed-info`);
};

// PORT-IB-3: Update board info of an institution
export const updateBoardInfoInInstitution = async (school_id, board_id, data) => {
  return await https.put(`/institutions/${school_id}/boards/${board_id}`, data);
};

// PORT-IB-4: Delete a board of an institution
export const deleteBoardFromInstitution = async (school_id, board_id) => {
  return await https.delete(`/institutions/${school_id}/boards/${board_id}`);
};

// PORT-IB-5: Retrieve list of boards of an institution
export const getBoardsByNameInInstitution = async (school_id) => {
  return await https.get(`/institutions/${school_id}/boards/`);
};
